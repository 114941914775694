import { types, flow, isAlive } from 'mobx-state-tree';

import api from 'services/API';
import { commonUtilities } from 'utils';
import { getRootStore } from 'models/root';
import { ItemLine } from 'models/types';

export const itemLinesInitialState = {
  all: [],
  isLoaded: false,
};

const ItemLineWithViews = ItemLine.views(self => ({}));

export const itemLinesModel = types
  .model({
    all: types.array(ItemLineWithViews),
    isLoaded: types.boolean,
  })
  .views(self => ({
    get itemLines() {
      return self.all;
    },
  }))
  .actions(self => {
    return {
      handleNewItemConnection({ connected = [] } = {}) {
        connected.forEach(({ _item_line }) => self.updateItemLine(_item_line));
      },

      handleItemDisconnection({ disconnected = [] } = {}) {
        disconnected.forEach(({ _item_line }) => self.updateItemLine(_item_line));
      },

      handleNewItemLine(result) {
        const added = result?.added || [];

        if (Array.isArray(added) && added.length > 0) {
          added.forEach(addedEl => {
            self.addItemLine(addedEl._item_line);
            const root = getRootStore();
            root.itemsStore.queueInventoryReplacement(addedEl._item_line.item_id);
          });
        }
      },

      handleItemLineRemove(result) {
        const removed = result?.removed || [];

        if (Array.isArray(removed) && removed.length > 0) {
          removed.forEach(removedEl => {
            self.removeItemLine(removedEl._item_line);
            const root = getRootStore();
            root.itemsStore.queueInventoryReplacement(removedEl._item_line.item_id);
          });
        }
      },

      handleItemLineReorder(reorderedItemLines = []) {
        reorderedItemLines = Array.isArray(reorderedItemLines)
          ? reorderedItemLines
          : [reorderedItemLines];

        reorderedItemLines.forEach(self.updateItemLine);
      },

      handleNextItem({ disconnected = {}, connected = {}, deleted_item_line_id = null } = {}) {
        const { _item_lines: connectedItemLines } = connected;
        const { _item_lines: disconnectedItemLines } = disconnected;

        if (deleted_item_line_id) self.removeItemLineById(deleted_item_line_id);

        disconnectedItemLines &&
          Array.isArray(disconnectedItemLines) &&
          disconnectedItemLines.forEach(self.updateItemLine);
        connectedItemLines &&
          Array.isArray(connectedItemLines) &&
          connectedItemLines.forEach(self.updateItemLine);
      },

      handleSwapItems({ _item_lines_deleted = [], _item_lines_updated = [] }) {
        _item_lines_updated &&
          Array.isArray(_item_lines_updated) &&
          _item_lines_updated.forEach(_item_line => {
            self.updateOrInsertItemLine(_item_line);
          });
        _item_lines_deleted &&
          Array.isArray(_item_lines_deleted) &&
          _item_lines_deleted.forEach(_item_line => {
            self.removeItemLine(_item_line);
          });
      },

      fetchItemLines: flow(function* () {
        const root = getRootStore();
        const linesStore = root.linesStore;
        try {
          const ids = linesStore.lines.map(line => line.id);
          if (ids && ids.length) {
            try {
              const itemLinesData = yield api.getItemLines({ line_id: ids });
              self.isLoaded = true;
              if (commonUtilities.validateResponse(itemLinesData)) {
                self.all.replace(itemLinesData.data.result);
              } else {
                return Promise.reject(new Error('There are no item lines for current lines.'));
              }
            } catch (err) {
              self.isLoaded = false;
              return Promise.reject(new Error('Get item lines error.'));
            }
          } else {
            self.isLoaded = false;
            return Promise.reject(new Error('There are no lines for current bar.'));
          }
        } catch (err) {
          self.isLoaded = false;
          return Promise.reject(err);
        }
      }),

      fetchItemLine: flow(function* (line_id) {
        try {
          const response = yield api.getItemLine({ line_id });
          if (response?.data?.result) {
            self.updateOrInsertItemLine(response.data.result);
          }
        } catch (err) {
          return Promise.reject(err);
        }
      }),

      updateItemLine(_item_line) {
        if (_item_line) {
          const current = self.all.find(el => el.id === _item_line.id);
          if (current) {
            const updatedItemLines = self.all.map(itemLine => {
              if (itemLine.id === _item_line.id) {
                return { ...itemLine, ..._item_line };
              }
              return itemLine;
            });
            self.all.replace(updatedItemLines);
          } else {
            self.all.replace([...self.all, _item_line]);
          }
        }
      },

      removeDuplicateItemLineRecord(record) {
        if (record && record.id) {
          const oldItemLineRecord = self.all.find(
            el => el.id !== record.id && el.item_id === record.item_id,
          );
          if (oldItemLineRecord) {
            self.all.remove(oldItemLineRecord);
          }
        }
      },

      getItemLineByLineId(id) {
        return self.all.find(itemLine => itemLine.line_id === id && itemLine.queue_index === 0);
      },

      getItemLineByItemId(id) {
        return self.all.find(itemLine => itemLine.item_id === id && itemLine.queue_index === 0);
      },

      updateItemLines(updatedItemLines) {
        self.all.replace(updatedItemLines);
      },

      addItemLine(itemLine) {
        self.all.push(itemLine);
      },

      removeItemLine(itemLineToRemove) {
        self.all.replace(self.all.filter(itemLine => itemLine.id !== itemLineToRemove.id));
      },

      removeItemLineById(id) {
        self.all.replace(self.all.filter(itemLine => itemLine.id !== id));
      },

      updateOrInsertItemLine(_item_line) {
        if (_item_line && _item_line.id) {
          const itemLineIndex = self.all.findIndex(
            itemLine => itemLine && itemLine.id === _item_line.id,
          );
          const itemLineExist = itemLineIndex >= 0;
          if (itemLineExist) {
            self.all[itemLineIndex] = _item_line;
          } else {
            const updatedItemLines = [...self.all, _item_line];
            self.all.replace(updatedItemLines);
          }
        }
      },

      setItemLines(itemLines, initial = false) {
        if (isAlive(self)) {
          self.all.replace(
            initial
              ? itemLines.map(line => ({
                  ...line,
                  queue_index: 0,
                }))
              : itemLines,
          );
          self.isLoaded = true;
        }
      },
    };
  });
