import { useCallback, useMemo, useState } from 'react';
import { useStore, useToast } from 'hooks';

const useDigitalMenuData = () => {
  const { menusStore, menuDesignsStore, menuListsStore } = useStore();
  const { errorToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const loadData = useCallback(async () => {
    try {
      setIsLoading(true);
      await Promise.all([menusStore.fetch(), menuDesignsStore.fetch(), menuListsStore.fetch()]);
    } catch (error) {
      errorToast(error);
    } finally {
      setIsLoading(false);
    }
  }, [errorToast, menuDesignsStore, menuListsStore, menusStore]);

  const isLoaded = useMemo(() => {
    return menusStore.isLoaded && menuDesignsStore.isLoaded && menuListsStore.isLoaded;
  }, [menusStore.isLoaded, menuDesignsStore.isLoaded, menuListsStore.isLoaded]);

  return { isLoading, isLoaded, loadData };
};

export default useDigitalMenuData;
