import { types, flow } from 'mobx-state-tree';
import api from 'services/API';
import { MenuDesign } from './types';

export const menuDesignsInitialState = {
  all: [],
  isLoaded: false,
  currentMenu: null,
  page: 0,
};

const MenuDesignWithViews = MenuDesign.views(self => ({
  get config() {
    return self.layout;
  },
  get orientation() {
    return self.portrait ? 'vertical' : 'horizontal';
  },
}));

export const menuDesignsModel = types
  .model({
    all: types.array(MenuDesignWithViews),
    isLoaded: types.boolean,
    currentMenu: types.maybeNull(types.reference(MenuDesignWithViews)),
    page: types.number,
  })
  .views(self => ({
    get designs() {
      return self.all
        .filter(({ archived }) => !Boolean(archived))
        .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
    },
    getDesignById: id => {
      return self.all.find(design => design.id === Number(id));
    },
  }))
  .actions(self => {
    return {
      fetch: flow(function* () {
        try {
          const menuDesignsData = yield api.getMenuDesigns();
          self.all.replace(menuDesignsData);
          self.isLoaded = true;
          return self.all;
        } catch (err) {
          self.all.replace([]);
          self.isLoaded = false;
          return Promise.reject(err);
        }
      }),

      updateOrInsert(menuDesign) {
        if (menuDesign && menuDesign.id) {
          const menuDesignIndex = self.all.findIndex(
            existingUser => existingUser && existingUser.id === menuDesign.id,
          );
          const menuDesignExists = menuDesignIndex >= 0;

          if (menuDesignExists) {
            self.all[menuDesignIndex] = menuDesign;
          }
        }
      },

      update: flow(function* (id, patch) {
        try {
          const response = yield api.updateMenuDesign(id, patch);
          if (response && response.data && response.data.row) {
            self.updateOrInsert(response.data.row);
          }

          return response;
        } catch (err) {
          return Promise.reject(err);
        }
      }),

      create: flow(function* (data) {
        try {
          const response = yield api.createMenuDesign(data);
          self.all.push(response.data.row);
          return response;
        } catch (err) {
          return Promise.reject(err);
        }
      }),

      delete: flow(function* (id) {
        try {
          const response = yield api.deleteMenuDesign(id);

          const item = self.all.find(c => c.id === id);
          self.all.remove(item);

          return response;
        } catch (err) {
          return Promise.reject(err);
        }
      }),

      setMenuDesigns(menus) {
        self.all.replace(menus);
        self.isLoaded = true;
      },
    };
  });
