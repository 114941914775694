import { types } from 'mobx-state-tree';

const BruModel = types.model({
  n: types.maybeNull(types.number),
  max: types.maybeNull(types.number),
  min: types.maybeNull(types.number),
  mean: types.maybeNull(types.number),
  median: types.maybeNull(types.number),
  stdevp: types.maybeNull(types.number),
  avg: types.maybeNull(types.number),
  from: types.maybeNull(types.string),
  to: types.maybeNull(types.string),
});

const HeartbeatCurrentStatisticsModel = types.model({
  bru_hum: types.maybeNull(BruModel),
  n_lines: types.maybeNull(types.number),
  bru_baro: types.maybeNull(BruModel),
  bru_temp_c: types.maybeNull(BruModel),
  heartbeats_to: types.maybeNull(types.string),
  heartbeats_from: types.maybeNull(types.string),
  n_line_sensors_current: types.maybeNull(types.number),
});

const HistoricalPeriodModel = types.model({
  bru_baro: types.maybeNull(BruModel),
  bru_hum: types.maybeNull(BruModel),
  bru_temp_c: types.maybeNull(BruModel),
  from: types.maybeNull(types.string),
  to: types.maybeNull(types.string),
});

const HeartbeatHistoricalStatisticsModel = types.model({
  '1 day': types.maybeNull(HistoricalPeriodModel),
  '7 days': types.maybeNull(HistoricalPeriodModel),
  '30 days': types.maybeNull(HistoricalPeriodModel),
});

export const CoolerStatistic = types.model({
  id: types.integer,
  establishment_id: types.number,
  name: types.maybeNull(types.string),
  cooler_type: types.maybeNull(types.number),
  draft_system_type: types.maybeNull(types.number),
  // gas_system_blend_ratio: {
  //   co2_ratio: 1,
  //   nitrogen_ratio: 0,
  // },
  // metadata: null,
  archived: types.maybeNull(types.boolean),
  created_at: types.maybeNull(types.string),
  // created_by: 0,
  updated_at: types.maybeNull(types.string),
  // updated_by: 0,
  _heartbeats_current_statistics: types.maybeNull(HeartbeatCurrentStatisticsModel),
  _heartbeats_historical_statistics: types.maybeNull(HeartbeatHistoricalStatisticsModel),
});
