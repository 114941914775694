import palette from '../palette';

const MuiTextField = {
  styleOverrides: {
    root: {
      margin: '0',
      '& .MuiFormHelperText-root.Mui-error': {
        color: palette.error.light,
      },
      '& .MuiFormLabel-root.Mui-error': {
        color: palette.error.light,
      },
    },
  },
};

export default MuiTextField;
