import { alpha } from '@mui/material/styles';
import palette from 'theme/palette';

const MuiTableCell = {
  styleOverrides: {
    root: {
      borderBottom: '1px solid #1C1C1C',
      padding: '5px 10px',
      height: 46,
      fontSize: 14,
    },
    head: {
      color: alpha(palette.white, 0.6),
      fontSize: 16,
    },
    body: {
      fontSize: 16,
      color: palette.white,
    },
  },
};

export default MuiTableCell;
