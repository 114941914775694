import React, { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Link, Typography } from '@mui/material';

const FallbackComponent = ({ resetError, fallbackPage = { path: '/on-tap', title: 'On-Tap' } }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const resetErrorState = useCallback(() => {
    if (typeof resetError === 'function') {
      resetError();
    }
  }, [resetError]);

  const redirectUser = useCallback(
    e => {
      e.preventDefault();
      navigate(fallbackPage.path);
      resetErrorState();
    },
    [navigate, fallbackPage.path, resetErrorState],
  );

  useEffect(() => {
    resetErrorState();
  }, [location, resetErrorState]);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      height="1"
    >
      <Typography variant="h4" style={{ fontSize: 28, fontWeight: 500, marginBottom: 10 }}>
        Oops! Something went wrong...
      </Typography>
      <Link
        onClick={redirectUser}
        component="button"
        color="primary"
        style={{ cursor: 'pointer', fontSize: 22 }}
      >
        {`Go to ${fallbackPage.title} page`}
      </Link>
    </Box>
  );
};

export default FallbackComponent;
