import palette from '../../../../../../../theme/palette';
import { TimePicker } from '@mui/x-date-pickers';
import React from 'react';

export const DynamicTimePicker = ({
  title,
  valueState,
  name,
  handleChange,
  textColor,
  disabled,
}) => {
  return (
    <TimePicker
      disabled={disabled}
      label={title}
      value={valueState[name]}
      onChange={handleChange}
      sx={{
        '.MuiInputBase-root': {
          backgroundColor: palette.secondary.dark,
          color: textColor,
        },
        '&.MuiTextField-root': {
          marginBottom: 0,
        },
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: palette.outline.main,
        },
      }}
    />
  );
};
