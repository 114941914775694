import { types } from 'mobx-state-tree';

const Metadata = types.model({
  display_status: types.boolean,
});

export const Container = types.model({
  id: types.integer,
  name: types.string,
  nickname: types.maybeNull(types.string),
  establishment_id: types.maybeNull(types.integer),
  type_code: types.integer,
  volume_unit_id: types.number,
  volume_total: types.number,
  archived: types.boolean,
  // updated_at: types.date,
  // updated_by: types.integer,
  metadata: types.maybeNull(Metadata),
  // status_code: null,
  // _metadata_show: null,
  // _prototype_name: types.maybeNull(types.string),
  // _users_username_updated_by: types.string,
  _volume_total_ml: types.maybeNull(types.number),
});
