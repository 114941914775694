import React from 'react';
import styled from '@emotion/styled';
import { ReactComponent as Logo } from 'assets/icons/logoBarTrack.svg';

const message = [
  '--- Scheduled Maintenance ---',
  'The BarTrack Dashboard is offline for scheduled maintenance from October 9th 6:30AM (ET) to October 9th 8:30AM (ET). During this time, our team will be performing various system upgrades and improvements that will enhance your experience on our platform. While the maintenance is underway, you will not be able to access the BarTrack Dashboard or other BarTrack services.',
  'We apologize for any inconvenience this may cause and would like to assure you that all of your data is still being captured by our hardware. Every effort will be made to complete the maintenance as quickly as possible and minimize the downtime - we do not anticipate it to last the entire period.',
  'Thank you for your understanding and cooperation during this period. If you have any questions or concerns, please do not hesitate to reach out to your Customer Success Manager.',
  'Cheers!',
];

const MaintenancePage = () => {
  const componentName = 'MaintenanceContainer';
  return (
    <StyledMaintenanceContainer className={componentName}>
      {message.map((message, index) => {
        return (
          <StyledMaintenanceMessage key={index} className={`${componentName}-message`}>
            {message}
          </StyledMaintenanceMessage>
        );
      })}

      <StyledSvgContainer className={`${componentName}-message`}>
        <Logo />
      </StyledSvgContainer>
    </StyledMaintenanceContainer>
  );
};

export default MaintenancePage;

const StyledMaintenanceContainer = styled.div(() => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100vh',
  padding: '80px 25px 20px',
  margin: 'auto',
  maxWidth: '728px',

  '@media (min-width: 720px)': {
    padding: '120px 40px',
  },
}));

const StyledMaintenanceMessage = styled.p(() => ({
  fontSize: '12px',
  lineHeight: '2',
  margin: '8px 0',
  textAlign: 'justify',

  '@media (min-width: 720px)': {
    fontSize: '18px',
  },
}));

const StyledSvgContainer = styled.div(() => ({
  margin: '40px 0',
}));
