import { types } from 'mobx-state-tree';

const Metadata = types.model({});

export const Menu = types.model({
  id: types.identifierNumber,
  name: types.maybeNull(types.string),
  establishment_id: types.integer,
  metadata: types.maybeNull(Metadata),
  menu_design_id: types.maybeNull(types.integer),
  menu_list_ids: types.maybeNull(types.array(types.frozen(types.integer))),
  active: types.boolean,
  idle: types.integer,
  updated_by: types.integer,
  updated_at: types.string,
  archived: types.boolean,
});
