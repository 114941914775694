import { types } from 'mobx-state-tree';

const BruItemModel = types.model({
  // d: types.maybeNull(types.union(types.string, types.number)),
  // n: types.maybeNull(types.union(types.string, types.number)),
  mean: types.maybeNull(types.union(types.string, types.number)),
  // median: types.maybeNull(types.union(types.string, types.number)),
  // stdev_p: types.maybeNull(types.union(types.string, types.number)),
});

const BruSummaryModel = types.model({
  bru_hum: types.maybeNull(BruItemModel),
  // bru_baro: types.maybeNull(BruItemModel),
  bru_temp_c: types.maybeNull(BruItemModel),
});

export const DeviceHistory = types.model({
  bin_from: types.string,
  connected_sensors: types.map(types.frozen()),
  // bru_summary: types.map(types.frozen()),
  bru_summary: types.maybeNull(BruSummaryModel),
});
