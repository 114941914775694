import { useCallback, useState } from 'react';
import { useStore, useToast } from 'hooks';
import { useEffect } from 'react';

const useTapDetailsData = () => {
  const { linesStore, itemsStore, itemLinesStore } = useStore();
  const { errorToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const loadItemData = useCallback(async () => {
    try {
      await Promise.all([
        itemsStore.fetchItem(linesStore.currentLine.item.id),
        itemLinesStore.fetchItemLine(linesStore.currentLine.id),
      ]);
    } catch (err) {
      errorToast(err);
    }
  }, [
    errorToast,
    itemLinesStore,
    itemsStore,
    linesStore.currentLine.id,
    linesStore.currentLine?.item?.id,
  ]);

  const loadData = useCallback(async () => {
    try {
      setIsLoading(true);
      await linesStore.fetchLine(linesStore.currentLine.id);
      if (!linesStore.currentLine?.item?.id) {
        setIsLoaded(true);
        setIsLoading(false);
        return;
      }
      if (linesStore.currentLine?._item_lines_latest_item_id === linesStore.currentLine?.item?.id) {
        setIsLoading(false);
        await loadItemData();
      } else {
        await loadItemData();
        setIsLoading(false);
      }
      setIsLoaded(true);
    } catch (err) {
      setIsLoading(false);
      errorToast(err);
    }
  }, [linesStore, loadItemData, errorToast]);

  useEffect(() => {
    (async () => {
      await loadData();
    })();
  }, [linesStore.currentLine?.id, linesStore.currentLine?.item?.id, loadData]);

  return { isLoading, isLoaded, loadData };
};

export default useTapDetailsData;
