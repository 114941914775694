import { types } from 'mobx-state-tree';

export const Schedule = types.model({
  id: types.identifierNumber,
  establishment_id: types.number,
  type: types.maybeNull(types.number),
  from_token: types.maybeNull(types.string),
  to_token: types.maybeNull(types.string),
  identifier: types.maybeNull(types.string),
  metadata: types.maybeNull(
    types.model({
      lines: types.maybeNull(types.string),
      lines_all: types.maybeNull(types.union(types.boolean, types.string)), // string as the boolean value was
      // coming from the server as a string
    }),
  ),
  priority: types.maybeNull(types.number),
  recurring: types.maybeNull(types.boolean),
  effective_from: types.maybeNull(types.string),
  effective_to: types.maybeNull(types.string),
  effective: types.maybeNull(types.string),
  archived: types.maybeNull(types.boolean),
  updated_at: types.maybeNull(types.string),
  updated_by: types.maybeNull(types.number),
  _users_username_updated_by: types.maybeNull(types.string),
});
