import { types, flow } from 'mobx-state-tree';

import api from 'services/API';
import { getRootStore } from 'models/root';
import { messages } from 'config/messages';

export const itemLineControllerInitialState = {};

export const itemLineControllerModel = types.model({}).actions(() => {
  return {
    connectNextItem: flow(function* (line_id, item_status_code) {
      const root = getRootStore();
      try {
        const response = yield api.connectNextItem({ line_id, item_status_code });

        if (response?.data?.result) {
          const {
            disconnected = {},
            connected = {},
            deleted_item_line_id = null,
          } = response.data.result;

          if (Array.isArray(disconnected) && disconnected?.length) {
            disconnected.forEach(d => {
              const { _item_line, _item, _item_events_summary } = d;

              root.itemLinesStore.updateItemLine(_item_line);
              root.itemsStore.updateItem({ ..._item, _item_events_summary });
            });
          }

          if (deleted_item_line_id) root.itemLinesStore.removeItemLineById(deleted_item_line_id);

          if (Array.isArray(connected) && connected?.length) {
            connected.forEach(c => {
              const { _item_line, _item, _item_events_summary } = c;

              root.itemLinesStore.updateItemLine(_item_line);
              root.itemsStore.updateItem({ ..._item, _item_events_summary });
            });
          }

          return response;
        } else {
          return Promise.reject(new Error(messages.common.error));
        }
      } catch (err) {
        return Promise.reject(err);
      }
    }),

    connectNewItem: flow(function* (line_id, item_id, item_status_code) {
      const root = getRootStore();
      try {
        const response = yield api.connectNewItem({ line_id, item_id, item_status_code });

        const { _lines = [], connected = [], disconnected = [] } = response?.data?.result || {};

        connected.forEach(({ _item_line, _item, _item_events_summary }) => {
          root.itemLinesStore.updateItemLine(_item_line);

          const itemEventsSummaries = Array.isArray(_item_events_summary)
            ? _item_events_summary
            : [];

          itemEventsSummaries.forEach(summary => {
            root.itemsStore.updateItem({
              id: _item.id,
              summary,
            });
          });

          root.itemsStore.updateItem(_item);
        });

        disconnected.forEach(({ _item_line, _item, _item_events_summary }) => {
          root.itemLinesStore.updateItemLine(_item_line);
          root.itemsStore.updateItem({ ..._item, _item_events_summary });
        });

        _lines && _lines.length && _lines.forEach(root.linesStore.updateLine);

        return response;
      } catch (err) {
        return Promise.reject(err);
      }
    }),

    disconnectCurrentItem: flow(function* (lineId, statusCode) {
      const root = getRootStore();
      try {
        const response = yield api.disconnectCurrentItem({
          line_id: lineId,
          item_status_code: statusCode,
        });

        const { disconnected = [], _lines = [] } = response?.data?.result || {};

        if (disconnected.length === 0) {
          return Promise.reject(new Error(messages.common.error));
        }

        if (Array.isArray(_lines) && _lines.length) {
          _lines.forEach(root.linesStore.updateLine);
        }

        disconnected.forEach(({ _item_line, _item, _item_events_summary }) => {
          root.itemLinesStore.updateItemLine(_item_line);
          root.itemsStore.updateItem({
            ..._item,
            _item_events_summary,
          });
        });

        return response;
      } catch (err) {
        return Promise.reject(err);
      }
    }),
  };
});
