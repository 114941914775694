import { alpha } from '@mui/material/styles';
import palette from 'theme/palette';

const MuiDialog = {
  styleOverrides: {
    root: {
      '@media(max-width: 599px)': {
        paddingTop: 56,
      },
      '& .MuiCard-root': {
        background: alpha(palette.secondary.dark, 0.4),
      },
      '.MuiTimePickerToolbar-hourMinuteLabel': {
        alignItems: 'baseline',
      },
    },
    paper: {
      '@media(min-width: 600px)': {
        margin: '32px 32px 88px',
      },
      '&.rounded': {
        borderRadius: 10,
      },
    },
    paperWidthSm: {
      maxWidth: 560,
    },
    paperWidthXs: {
      '&.alert': {
        maxWidth: 330,
      },
    },
    paperScrollPaper: {
      '@media(max-width: 1023px)': {
        maxHeight: 'calc(100% - 80px)',
      },
    },
    container: {
      '@media(max-width: 599px)': {},
    },
  },
};

export default MuiDialog;
