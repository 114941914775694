import { types, flow } from 'mobx-state-tree';

import api from 'services/API';
import { CleaningStatistic } from 'models/types';

export const cleaningStatisticsInitialState = {
  isLoaded: false,
  all: [],
  state: 'done',
  updated: null,
};

export const cleaningStatisticsModel = types
  .model({
    isLoaded: types.boolean,
    all: types.array(CleaningStatistic),
    state: types.enumeration('state', ['done', 'pending', 'error']),
    updated: types.maybeNull(types.Date),
  })
  .views(self => ({
    get getUpdated() {
      return self.updated;
    },

    getStatisticByLineId(id) {
      return self.all.find(line => line.line_id === id);
    },
  }))
  .actions(self => ({
    fetch: flow(function* () {
      try {
        self.state = 'pending';
        const response = yield api.getCleaningStatistics();
        if (response && response.data && response.data.result) {
          self.all.replace(response.data.result);
          self.state = 'done';
          self.isLoaded = true;
          self.updated = new Date();
        } else {
          self.state = 'done';
          self.isLoaded = true;
          self.updated = new Date();
        }
      } catch (err) {
        self.isLoaded = true;
        self.state = 'error';
        self.updated = new Date();
        console.error(err);
        return Promise.reject(err);
      }
    }),

    setCleaningStatistics(cleaningStatistics) {
      self.all.replace(cleaningStatistics);
      self.state = 'done';
      self.isLoaded = true;
      self.updated = new Date();
    },
  }));
