/* eslint-disable import/no-anonymous-default-export */
const io = require('socket.io-client');

const { REACT_APP_API_BASE_URL } = process.env;

class SocketClient {
  constructor() {
    this.connection = io.connect(REACT_APP_API_BASE_URL, {
      autoConnect: false,
      honorCipherOrder: true,
      rejectUnauthorized: false,
      secureProtocol: 'TLSv1_2_method',
    });
  }

  connect(authToken) {
    this.connection.io.opts.query = {
      auth_token: authToken,
    };
    this.connection.open();
    return this.connection;
  }

  reinitialize(authToken) {
    if (this.connection.connected) {
      this.connection.disconnect();
    }
    this.connection = io.connect(REACT_APP_API_BASE_URL, {
      autoConnect: true,
      honorCipherOrder: true,
      rejectUnauthorized: false,
      secureProtocol: 'TLSv1_2_method',
      query: {
        auth_token: authToken,
      },
    });
    return this.connection;
  }
}

export default new SocketClient();
