import React, { useState, useEffect } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { observer } from 'mobx-react';
import { Box, styled } from '@mui/material';
import clsx from 'clsx';

import { BarTrackLogoIcon } from 'assets/icons';

const MenuPagination = ({ pagination = true, state }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (!state.isPaginationEnabled) return;
    const interval = setInterval(() => {
      setCount(prevValue => prevValue + 50);
    }, 50);
    return () => {
      setCount(9);
      clearInterval(interval);
    };
  }, [state.isPaginationEnabled]);

  useEffect(() => {
    if (!state.isPaginationEnabled) return;

    if (count > state.pageDurationInMS) {
      const maxPageIndex = state.pageCount - 1;
      const nextPage = state.page + 1;

      if (nextPage <= maxPageIndex) {
        state.setPage(nextPage);
      } else {
        state.setPage(0);
      }

      setCount(0);
    }
  }, [count, state]);

  return (
    <StyledRootDiv
      state={state}
      className={clsx({
        logoContainerWithoutProgressbar: state.isPaginationEnabled,
      })}
    >
      <div>
        <div className="progressbarContainer">
          <div className="logoHolder">
            <BarTrackLogoIcon />
          </div>
          {pagination && state.isPaginationEnabled && (
            <CircularProgressbar
              value={(count / state.pageDurationInSeconds) * 1000}
              maxValue={1}
              strokeWidth={3}
            />
          )}
        </div>
        {pagination && state.isPaginationEnabled && (
          <Box display="flex" justifyContent={'center'}>
            {Array.from(Array(state.pageCount).keys(), pageInArray => (
              <div
                key={pageInArray}
                className={clsx('pageIndicator', {
                  active: state.page === pageInArray,
                })}
              />
            ))}
          </Box>
        )}
      </div>
    </StyledRootDiv>
  );
};

export default observer(MenuPagination);

const StyledRootDiv = styled('div', {
  shouldForwardProp: props => props !== 'state',
})(({ state }) => ({
  width: '10vh',
  height: '6vh',
  margin: '0 2vh 0 1.6vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& .CircularProgressbar': {
    width: '100%',
    verticalAlign: 'middle',
  },

  '& .CircularProgressbar .CircularProgressbar-path': {
    stroke: state.logoColor,
    opacity: 1,
    strokeLinecap: 'round',
  },

  '& .CircularProgressbar .CircularProgressbar-trail': {
    stroke: state.logoColor,
    opacity: 0.3,
    strokeLinecap: 'round',
  },

  '& .CircularProgressbar .CircularProgressbar-text': {
    fill: state.logoColor,
    fontSize: '20px',
    textAnchor: 'middle',
  },

  '& .CircularProgressbar .CircularProgressbar-background': {
    fill: 'transparent',
  },

  '.logoContainerWithoutProgressbar': {
    margin: '0 !important',
  },
  '.progressbarContainer': {
    width: '8vh',
    height: '8vh',
    marginBottom: '.4vh',
    position: 'relative',
  },
  '.logoHolder': {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      width: '70%',
      height: '70%',
      '& path': {
        fill: state.logoColor,
      },
    },
  },
  '.pageIndicator': {
    position: 'relative',
    width: '.9vh',
    height: '.9vh',
    borderRadius: '50%',
    border: '2px solid',
    borderColor: state.logoColor,
    opacity: 0.3,
    '&:not(:last-of-type)': {
      marginRight: '.4vh',
    },
  },
  '.active': {
    background: state.logoColor,
    opacity: 1,
  },
}));
