import { types } from 'mobx-state-tree';

const Config = types.model({
  logoColor: types.maybeNull(types.string),
  backgroundColor: types.maybeNull(types.string),
  beverageNameRowColor: types.maybeNull(types.string),
  producerRowColor: types.maybeNull(types.string),
  beverageDetailsRowColor: types.maybeNull(types.string),
  priceListColor: types.maybeNull(types.string),
  titleColor: types.maybeNull(types.string),
  bubbleColor: types.maybeNull(types.string),
  bubbleTextColor: types.maybeNull(types.string),
  kegColor: types.maybeNull(types.string),
  footerColor: types.maybeNull(types.string),
  categoryColor: types.maybeNull(types.string),
  font: types.maybeNull(types.string),
  establishmentInfoPosition: types.maybeNull(types.string),
  orientation: types.maybeNull(types.string),
  isGroupedByCategory: types.maybeNull(types.boolean),
  isKegLevelVisible: types.maybeNull(types.boolean),
  isProducerVisible: types.maybeNull(types.boolean),
  isProducerLocationVisible: types.maybeNull(types.boolean),
  isABVVisible: types.maybeNull(types.boolean),
  isIBUVisible: types.maybeNull(types.boolean),
  isStyleVisible: types.maybeNull(types.boolean),
  isTypeVisible: types.maybeNull(types.boolean),
  isPriceVisible: types.maybeNull(types.boolean),
  isCurrencyVisible: types.maybeNull(types.boolean),
  roundNumbers: types.maybeNull(types.boolean),
  areContainersVisible: types.maybeNull(types.boolean),
  areKeywordsVisible: types.maybeNull(types.boolean),
  tilesPerPage: types.maybeNull(types.number),
  tilesColumns: types.maybeNull(types.number),
  title: types.maybeNull(types.string),
  pageDurationInSeconds: types.maybeNull(types.number),
  pollingDuration: types.maybeNull(types.number),
  sortBy: types.maybeNull(types.string),
  sortOrder: types.maybeNull(types.string),
  groupBy: types.maybeNull(types.string),
  flipVertical: types.maybeNull(types.boolean),
});

export const MenuDesign = types.model({
  id: types.identifierNumber,
  name: types.maybeNull(types.string),
  establishment_id: types.integer,
  portrait: types.boolean,
  category: types.integer,
  template: types.integer,
  layout: types.maybeNull(Config),
  updated_by: types.integer,
  updated_at: types.string,
  archived: types.boolean,
});
