import { useCallback, useEffect, useState } from 'react';
import { useDrawerContext } from 'hooks';

const useDrawer = props => {
  const context = useDrawerContext();
  const [options] = useState(props);

  useEffect(() => {
    context.setOptions(defaultOptions => ({ ...defaultOptions, ...options }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const openDrawer = useCallback(() => {
    context.setOpen(true);
  }, [context]);

  const closeDrawer = useCallback(() => {
    context.setOpen(false);
  }, [context]);

  return {
    openDrawer,
    closeDrawer,
  };
};

export default useDrawer;
