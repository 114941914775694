import palette from 'theme/palette';

const MuiMenuItem = {
  styleOverrides: {
    root: {
      height: 32,
      paddingTop: 0,
      paddingBottom: 0,
      transition: '.3s',
      textAlign: 'center',
      minHeight: 'auto',
      '&:last-of-type': {
        '& .option': {
          borderBottom: 'none',
        },
      },
      '& .option': {
        width: '100%',
        height: 32,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderBottom: `1px solid #323232`,
        '&.left': {
          justifyContent: 'left',
        },
      },
      '&.Mui-selected': {
        color: palette.primary.main,
        backgroundColor: 'inherit',
      },
    },
    gutters: {
      '&.item--icons': {
        paddingRight: 0,
        textAlign: 'left',
        '& > svg': {
          marginRight: 10,
          minWidth: 24,
        },
        '& .option': {
          paddingRight: 20,
          textTransform: 'capitalize',
        },
        '&.Mui-selected': {
          color: palette.white,
        },
      },
    },
  },
};

export default MuiMenuItem;
