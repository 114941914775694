import { types, flow } from 'mobx-state-tree';
import { getRootStore } from 'models/root';
import api from 'services/API';
import { Menu } from './types';

export const menusInitialState = {
  all: [],
  isLoaded: false,
  currentMenu: null,
  page: 0,
};

const MenuWithViews = Menu.views(self => ({
  get design() {
    const root = getRootStore();
    return root.menuDesignsStore.all.find(el => el.id === self.menu_design_id);
  },
  get lists() {
    const root = getRootStore();
    return root.menuListsStore.all.filter(el => self.menu_list_ids.includes(el.id));
  },
}));

export const menusModel = types
  .model({
    all: types.array(MenuWithViews),
    isLoaded: types.boolean,
    currentMenu: types.maybeNull(types.reference(MenuWithViews)),
    page: types.number,
  })
  .views(self => ({
    get menus() {
      return self.all
        .filter(({ archived }) => !Boolean(archived))
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    getMenuById: id => {
      return [...self.all.toJSON()].find(menu => menu.id === Number(id));
    },
  }))
  .actions(self => {
    return {
      fetch: flow(function* () {
        try {
          const menusData = yield api.getMenus();
          self.all.replace(menusData);
          self.isLoaded = true;
          return self.all;
        } catch (err) {
          self.all.replace([]);
          self.isLoaded = false;
          return Promise.reject(err);
        }
      }),

      update: flow(function* (id, body) {
        try {
          const response = yield api.updateMenu(id, body);
          if (response && response.data && response.data.row) {
            self.updateOrInsertMenu(response.data.row);
          }
          return response;
        } catch (err) {
          return Promise.reject(err);
        }
      }),

      updateOrInsertMenu(_menu) {
        if (_menu && _menu.id) {
          const menuIndex = self.all.findIndex(menu => menu && menu.id === _menu.id);
          const menuExist = menuIndex >= 0;
          if (menuExist) {
            self.all[menuIndex] = _menu;
          } else {
            const updatedMenus = [...self.all, _menu];
            self.all.replace(updatedMenus);
          }
        }
      },

      create: flow(function* (data) {
        try {
          const response = yield api.createMenu(data);
          self.all.push(response.data.row);
          return response;
        } catch (err) {
          return Promise.reject(err);
        }
      }),

      delete: flow(function* (id) {
        try {
          const response = yield api.deleteMenu(id);

          const menu = self.all.find(c => c.id === id);
          self.all.remove(menu);

          return response;
        } catch (err) {
          return Promise.reject(err);
        }
      }),

      setDigitalMenus(menus) {
        self.all.replace(menus);
        self.isLoaded = true;
      },

      setPage(page) {
        self.page = page;
      },

      setCurrentMenu(id) {
        self.currentMenu = id;
      },
    };
  });
