const arrayUtilities = {
  filterDataBySearch: (search, data, searchedFields) => {
    const searchInputs = search.toLowerCase().split(' ');
    return data.filter(item =>
      searchInputs.every(input =>
        searchedFields.some((field, i) => item[searchedFields[i]].toLowerCase().includes(input)),
      ),
    );
  },

  uniquifyArrayByKeys: (arr, keys) => {
    return Object.values(
      arr.reduce((a, c) => {
        a[keys.map((item, index) => (index === keys.length ? c[item] : c[item] + '|'))] = c;
        return a;
      }, {}),
    );
  },
};

export default arrayUtilities;
