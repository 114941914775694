const MuiListItemIcon = {
  styleOverrides: {
    root: {
      color: 'currentColor',
      minWidth: 40,
    },
  },
};

export default MuiListItemIcon;
