import { types } from 'mobx-state-tree';

const LineIdentifier = types.model({
  numerical: types.maybeNull(types.number),
  prefix: types.string,
});
export const Identifier = types.maybeNull(
  types.model({
    line: LineIdentifier,
    taps: types.array(LineIdentifier),
  }),
);
