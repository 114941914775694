import { types } from 'mobx-state-tree';

export const inventoryKegRate = types.model({
  beverage_id: types.maybeNull(types.number),
  container_id: types.maybeNull(types.number),
  producers_name: types.maybeNull(types.string),
  beverages_name: types.maybeNull(types.string),
  containers_name: types.maybeNull(types.string),
  containers_nickname: types.maybeNull(types.string),
  containers_volume_total_ml: types.maybeNull(types.number),
  poured: types.maybeNull(types.number),
  per_calendar_day: types.maybeNull(types.number),
  tapped_duration: types.model({
    days: types.maybeNull(types.number),
    hours: types.maybeNull(types.number),
    minutes: types.maybeNull(types.number),
    seconds: types.maybeNull(types.number),
    milliseconds: types.maybeNull(types.number),
  }),
  sku_available_duration: types.model({
    days: types.maybeNull(types.number),
    hours: types.maybeNull(types.number),
    minutes: types.maybeNull(types.number),
    seconds: types.maybeNull(types.number),
    milliseconds: types.maybeNull(types.number),
  }),
  pct_available: types.maybeNull(types.number),
  overlapped: types.maybeNull(types.boolean),
});
