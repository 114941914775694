import { types, flow } from 'mobx-state-tree';
import api from 'services/API';
import { MenuList } from './types';
import { getRootStore } from 'models/root';

export const menuListsInitialState = {
  all: [],
  isLoaded: false,
  currentMenu: null,
  page: 0,
};

const MenuListsWithViews = MenuList.views(self => ({
  get entries() {
    const root = getRootStore();
    switch (self.method) {
      case 1:
        return root.linesStore.activeLines;
      case 2:
        return root.linesStore.queuedLines;
      case 3: {
        const beverages = root.beveragesStore.all.filter(({ id }) => self.entry_ids.includes(id));

        return beverages;
      }
      default:
        return [];
    }
  },
  get servingSizes() {
    return self.metadata.servingSizes;
  },
}));

export const menuListsModel = types
  .model({
    all: types.array(MenuListsWithViews),
    isLoaded: types.boolean,
    currentMenu: types.maybeNull(types.reference(MenuListsWithViews)),
    page: types.number,
  })
  .views(self => ({
    get lists() {
      return self.all
        .filter(({ archived }) => !Boolean(archived))
        .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
    },
  }))
  .actions(self => {
    return {
      fetch: flow(function* () {
        try {
          const menuListsData = yield api.getMenuLists();
          self.all.replace(menuListsData);
          self.isLoaded = true;
          return self.all;
        } catch (err) {
          self.all.replace([]);
          self.isLoaded = false;
          return Promise.reject(err);
        }
      }),
      update: flow(function* (id, body) {
        try {
          const response = yield api.updateMenuList(id, body);
          if (response && response.data && response.data.row) {
            self.updateOrInsertMenuList(response.data.row);
          }
          return response;
        } catch (err) {
          return Promise.reject(err);
        }
      }),

      updateEntryIds(listId, entryId) {
        const list = self.all.find(list => list.id === listId);
        const newList = { ...list, entry_ids: [...list.entry_ids, entryId] };

        self.updateOrInsertMenuList(newList);
      },

      updateListMethod(listId, method) {
        const list = self.all.find(list => list.id === listId);
        const newList = { ...list, method, entry_ids: [] };

        self.updateOrInsertMenuList(newList);
      },

      updateOrInsertMenuList(_list) {
        if (_list && _list.id) {
          const listIndex = self.all.findIndex(list => list && list.id === _list.id);
          const listExist = listIndex >= 0;
          if (listExist) {
            self.all[listIndex] = _list;
          } else {
            const updatedMenus = [...self.all, _list];
            self.all.replace(updatedMenus);
          }
        }
      },

      create: flow(function* (data) {
        try {
          const response = yield api.createMenuList(data);
          if (response && response.data && response.data.id) {
            self.all.push(response.data.row);
            return response;
          }
        } catch (err) {
          return Promise.reject(err);
        }
      }),

      delete: flow(function* (id) {
        try {
          const response = yield api.deleteMenuList(id);

          const item = self.all.find(c => c.id === id);
          self.all.remove(item);

          return response;
        } catch (err) {
          return Promise.reject(err);
        }
      }),

      setMenuLists(menus) {
        self.all.replace(menus);
        self.isLoaded = true;
      },

      setPage(page) {
        self.page = page;
      },
    };
  });
