import React, { useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Box, styled } from '@mui/material';
import { ArrowBackIos } from '@mui/icons-material';

import { useStore } from 'hooks';

const BackButton = ({
  previousLocation,
  forceReplace,
  forcePush,
  className,
  onBackAction = null,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { ui } = useStore();

  const handleGoBack = useCallback(() => {
    const routeHandler = () => {
      if (typeof onBackAction === 'function') {
        onBackAction();
      }
      if (typeof previousLocation === 'string') {
        return {
          route: previousLocation,
          state: null,
        };
      } else if (typeof previousLocation === 'object') {
        return {
          route: previousLocation.route,
          state: previousLocation.state,
        };
      } else {
        return {
          route: ui.pathState.previous,
          state: null,
        };
      }
    };

    const { route, state } = routeHandler();

    if (forceReplace) {
      return navigate(route, { replace: true, state: { ...state, from: location } });
    }
    if (forcePush) {
      return navigate(route, { state: { ...state, from: location } });
    }
    navigate(route, { replace: true, state: { ...state, from: location } });
  }, [
    forceReplace,
    forcePush,
    previousLocation,
    navigate,
    location,
    ui.pathState.previous,
    onBackAction,
  ]);

  return (
    <StyledButton startIcon={<ArrowBackIos />} onClick={handleGoBack} className={className}>
      <Box sx={{ display: { xs: 'none', sm: 'block' } }}>Back</Box>
    </StyledButton>
  );
};

export default BackButton;

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: 'capitalize',
  fontSize: '16px',
  fontFamily: 'Montserrat',
  opacity: 0.5,
  color: 'inherit',
  [theme.breakpoints.down('lg')]: {
    minWidth: 0,
    paddingLeft: theme.spacing(2),
  },
  '& .MuiButton-startIcon': {
    marginRight: 0,
  },

  '&.MuiButtonBase-root': {
    '&.absolute': {
      position: 'absolute !important',
      left: 0,
    },
  },
}));
