import { alpha } from '@mui/material/styles';
import palette from 'theme/palette';

const MuiInputBase = {
  styleOverrides: {
    root: {
      height: 48,
      lineHeight: '1.1876em!important',
    },
    multiline: {
      height: 'auto',
      padding: '0px!important',
    },
    inputMultiline: {
      padding: '18.5px 14px',
    },
    input: {
      height: '1.1876em',
      '&.Mui-disabled': {
        color: alpha(palette.white, 0.4),
        WebkitTextFillColor: alpha(palette.white, 0.4),
        '& .Mui-notchedOutline': {
          color: alpha(palette.white, 0.2),
        },
      },
    },
  },
};

export default MuiInputBase;
