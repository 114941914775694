import React from 'react';
import { observer } from 'mobx-react';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
  Tooltip,
  TextField,
  Divider,
} from '@mui/material';

import tooltips from 'config/tooltips';

const ORIENTATIONS = ['Horizontal', 'Vertical'];
const TILES_PER_PAGE = [4, 6, 8, 10, 12, 14, 16];
const DURATIONS = [5, 10, 15, 30, 60];

const General = ({ state }) => {
  return (
    <Box>
      <TextField
        label="Design Name"
        variant="outlined"
        fullWidth
        value={state.name}
        onChange={state.handleChange}
        name="name"
        className="mb-0"
      />
      <Divider />
      <FormControl variant="outlined" fullWidth>
        <InputLabel id="demo-simple-select-outlined-label2">Tiles per Page</InputLabel>
        <Tooltip
          placement="top"
          title={
            state.groupBy !== 'n/a'
              ? 'Note: when Menu Groups are turned on, group titles are counted as tiles.'
              : ''
          }
        >
          <Select
            labelId="tiles-per-page"
            id="tiles-per-page"
            value={state.tilesPerPage}
            onChange={state.handleChange}
            label="Tiles per Page"
            name="tilesPerPage"
          >
            {TILES_PER_PAGE.map((val, index) => (
              <MenuItem key={index} value={val}>
                {val}
              </MenuItem>
            ))}
          </Select>
        </Tooltip>
      </FormControl>
      <FormControl variant="outlined" fullWidth>
        <InputLabel id="demo-simple-select-outlined-label2">Page Duration</InputLabel>
        <Select
          labelId="pageDurationInSeconds"
          id="pageDurationInSeconds"
          value={state.pageDurationInSeconds}
          onChange={state.handleChange}
          label="Page Duration"
          name="pageDurationInSeconds"
        >
          {DURATIONS.map((val, index) => (
            <MenuItem key={index} value={val}>
              {val} seconds
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl variant="outlined" fullWidth>
        <InputLabel id="demo-simple-select-outlined-label2">Page Orientation</InputLabel>
        <Select
          labelId="page-orientation"
          id="page-orientation"
          value={state.orientation}
          onChange={state.handleOrientationChange}
          label="Page Orientation"
          name="orientation"
        >
          {ORIENTATIONS.map((val, index) => (
            <MenuItem key={index} value={val.toLowerCase()}>
              {val}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControlLabel
        disabled={state.orientation === 'horizontal'}
        control={
          <Tooltip title={tooltips.menuEditor.flip_vertical}>
            <Switch
              checked={state.orientation !== 'horizontal' && state.flipVertical}
              onChange={state.handleFlipVertical}
              disabled={state.orientation === 'horizontal'}
              name="Rotate by 180 degrees"
              color="primary"
            />
          </Tooltip>
        }
        label="Rotate by 180 degrees"
      />
    </Box>
  );
};

export default observer(General);
