import typography from 'theme/typography';

const MuiListSubheader = {
  styleOverrides: {
    root: {
      ...typography.h3,
      marginBottom: 5,
    },
  },
};

export default MuiListSubheader;
