import { types } from 'mobx-state-tree';

export const Notification = types.model({
  id: types.integer,
  condition_id: types.maybeNull(types.integer),
  role_user_id: types.maybeNull(types.integer),
  status_code: types.maybeNull(types.integer),
  created_at: types.maybeNull(types.string),
  updated_at: types.maybeNull(types.string),
  archived: types.boolean,
  _alarms_alert_id: types.maybeNull(types.integer),
  _alarms_alert_caption: types.maybeNull(types.string),
  _conditions_earliest_trigger_at: types.maybeNull(types.string),
  _conditions_latest_trigger_at: types.maybeNull(types.string),
  _gateways_id: types.maybeNull(types.integer),
  _gateways_name: types.maybeNull(types.string),
  _gateways_cooler_id: types.maybeNull(types.integer),
  _coolers_cooler_name: types.maybeNull(types.string),
});
