import { types } from 'mobx-state-tree';

export const Role = types.model({
  id: types.number,
  establishment_id: types.number,
  name: types.string,
  permissions: types.maybeNull(types.string),
  archived: types.boolean,
  updated_at: types.maybeNull(types.string),
  updated_by: types.maybeNull(types.number),
  _establishments_name: types.maybeNull(types.string),
  _users_username_updated_by: types.maybeNull(types.string),
  _role_sort_value: types.maybeNull(types.number),
});
