import { ability } from 'services/casl';
import {
  ui_admin,
  ui_cleanings,
  ui_config,
  ui_inv,
  ui_inv_del,
  ui_keg_ch,
  ui_keg_ed,
  ui_login,
  ui_maint,
  ui_manager,
  ui_mybev,
  ui_owner,
  ui_qual,
  ui_roles,
  ui_rpts,
  ui_skus,
  ui_users,
  ui_dm,
} from 'config/permissions';

const checkPermissions = (...permissions) => {
  if (ability.can(ui_admin)) {
    return true;
  }
  return permissions.some(permission => ability.can(permission));
};

const permissions = {
  hasAdminAccess() {
    return ability.can(ui_admin);
  },
  allowManageInventory() {
    return checkPermissions(ui_inv);
  },
  allowReadOnlyInventory() {
    return checkPermissions(ui_login);
  },
  allowDeleteInventory() {
    return checkPermissions(ui_inv_del);
  },
  allowManageCleanings() {
    return checkPermissions(ui_cleanings, ui_maint);
  },
  allowSystemConfiguration() {
    return checkPermissions(ui_config);
  },
  allowReadOnlyMyBeverages() {
    return checkPermissions(ui_login);
  },
  allowManageMyBeverages() {
    return checkPermissions(ui_mybev);
  },
  allowQualityManagement() {
    return checkPermissions(ui_qual);
  },
  allowChangeKegsAndLines() {
    return checkPermissions(ui_keg_ch);
  },
  allowEditKegProperties() {
    return checkPermissions(ui_keg_ed);
  },
  allowManageRoles() {
    return checkPermissions(ui_roles);
  },
  allowManageReports() {
    return checkPermissions(ui_rpts);
  },
  allowManageSKUMapping() {
    return checkPermissions(ui_skus);
  },
  allowLineMaintenance() {
    return checkPermissions(ui_maint);
  },
  allowBarManager() {
    return checkPermissions(ui_manager, ui_owner, ui_users);
  },
  allowCreatingManagerUsers() {
    return checkPermissions(ui_owner);
  },
  allowCreatingRegularUsers() {
    return checkPermissions(ui_manager, ui_users);
  },
  allowDigitalMenu() {
    return checkPermissions(ui_dm);
  },
};

export default permissions;
