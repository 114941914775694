const tooltips = {
  systemConfiguration: {
    default_pour_cost_pct:
      'If a beverage costs you $2.00 and you sell it for $8.00, that is a pour cost of 25%. The default is 20%.',
  },
  menuEditor: {
    flip_vertical:
      'This change will not take effect in the live preview. If the change is saved, the setting will automatically take effect on your TV.',
  },
};

export default tooltips;
