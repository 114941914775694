import { types } from 'mobx-state-tree';

export const LineSensor = types.model({
  id: types.integer,

  archived: types.boolean,

  connected: types.maybeNull(types.string),
  connected_from: types.maybeNull(types.string),
  connected_to: types.maybeNull(types.string),

  line_id: types.maybeNull(types.integer),
  sensor_id: types.maybeNull(types.integer),
});
