import { flow, types } from 'mobx-state-tree';
import { startOfYesterday } from 'date-fns';
import { subDays } from 'date-fns';
import { format } from 'date-fns';

import { getRootStore } from 'models/root';
import { inventoryKegRate } from 'models/types';
import api from 'services/API';

export const inventoryKegRateInitialState = {
  all: [],
  isLoaded: true,
};

export const inventoryKegRateModel = types
  .model({
    all: types.array(inventoryKegRate),
    isLoaded: types.boolean,
  })
  .views(self => ({
    get loading() {
      return !self.isLoaded;
    },
    getItemByBeverageAndContainerId(beverage_id, container_id) {
      return self.all.find(
        item => item.beverage_id === beverage_id && item.container_id === container_id,
      );
    },
    getKegPerDayByBeverageAndContainerId(beverage_id, container_id) {
      const item = self.getItemByBeverageAndContainerId(beverage_id, container_id);
      if (item) {
        return item.per_calendar_day;
      }

      return null;
    },
  }))
  .actions(self => {
    return {
      fetch: flow(function* () {
        try {
          const root = getRootStore();
          if (root.establishmentStore.establishment.id) {
            const { inventory_consumption_days } = root.reportStore.report_configuration;

            console.log(
              'fetch',
              inventory_consumption_days,
              format(new Date(subDays(new Date(), inventory_consumption_days)), 'yyyy-MM-dd'),
              format(new Date(startOfYesterday()), 'yyyy-MM-dd'),
            );

            const body = {
              establishment_id: root.establishmentStore.establishment.id,
              from_ts_local: format(
                new Date(subDays(new Date(), inventory_consumption_days || 1)),
                'yyyy-MM-dd',
              ),
              to_ts_local: format(new Date(startOfYesterday()), 'yyyy-MM-dd'),
            };
            self.isLoaded = false;

            const response = yield api.getInventoryKegRate(body);
            self.all = response.data.result;
            self.isLoaded = true;
          }
        } catch (err) {
          console.error(err);
          return Promise.reject(err);
        }
      }),
    };
  });
