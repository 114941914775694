import { alpha } from '@mui/material/styles';

const MuiTable = {
  styleOverrides: {
    root: {
      backgroundImage: `linear-gradient(214.77deg, ${alpha('#292929', 0.3)} 0.48%, ${alpha(
        'rgba(41, 41, 41, 0.5)',
        0.3,
      )} 100%)`,
    },
  },
};

export default MuiTable;
