import axios from 'axios';
import qs from 'qs';
import { rootStore } from 'models/root';
import * as Sentry from '@sentry/react';

import { PUBLIC_ROUTES } from 'config/routes';

const { REACT_APP_API_BASE_URL } = process.env;

const injectStringParams = (str, params = {}) => {
  return str.replace(/:(\w+)/g, (match, param) => params[param]);
};

const httpClient = axios.create({
  baseURL: `${REACT_APP_API_BASE_URL}/api`,
  timeout: 2 * 60 * 1000,
});

httpClient.interceptors.request.use(config => {
  if (!PUBLIC_ROUTES.includes(config.url) && rootStore.userStore.auth_token) {
    config.headers.Authorization = `Bearer ${rootStore.userStore.auth_token}`;
  }
  config.paramsSerializer = {
    serialize: params => qs.stringify(params, { indices: false }),
  };
  if (config.requestParams) {
    // URL templates replacer
    config.url = injectStringParams(config.url, config.requestParams);
  }
  return config;
});

httpClient.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    if (error?.response?.status >= 400 && error?.response?.status !== 401) {
      error.name = `HttpError ${error.response.status}: ${error.config.url}`;
      Sentry.captureException(error, {
        extra: {
          'Request Data': error.config.data ? error.config.data : null,
          'Request Params': error.config.params ? error.config.params : null,
          'Response Data': error.response.data,
        },
      });
    }
    if (error?.response?.status === 401 && rootStore.userStore.isAuthenticated) {
      rootStore.userStore.logout(false);
    }
    if (error?.response?.status >= 500) {
      return Promise.reject(new Error('Cannot connect due to server issues. Try again later.'));
    }

    if (error?.code === 'ECONNABORTED') {
      try {
        const response = await fetch('https://google.com', {
          mode: 'no-cors',
        });
        if (response) {
          return Promise.reject(
            new Error('Cannot connect to server due to slow Internet connection.'),
          );
        }
        return Promise.reject(new Error('Internet connection is absent. Check your connection.'));
      } catch (err) {
        return Promise.reject(new Error('Internet connection is absent. Check your connection.'));
      }
    }

    return Promise.reject(error);
  },
);

export default httpClient;
