import { types } from 'mobx-state-tree';

export const Type = types.model({
  id: types.identifierNumber,
  name: types.maybeNull(types.string),
  short_name: types.maybeNull(types.string),
  top_name: types.maybeNull(types.string),
  tree_name: types.maybeNull(types.string),
  type_id: types.maybeNull(types.number),
});
