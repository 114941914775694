import { types, flow } from 'mobx-state-tree';
import api from 'services/API';
import { Unit } from './types';
import containerTypes from 'config/containerTypes';

export const unitsInitialState = {
  all: [],
};

const unitPriorities = {
  5: 0,
  12: 0,
  1: 1,
  3: 2,
  2: 3,
  4: 4,
};

export const unitModel = types
  .model({
    all: types.array(Unit),
  })
  .views(self => ({
    get units() {
      return self.all;
    },
    getUnitsByContainerType(containerType) {
      const availableUnitIds = [];
      if (containerType === containerTypes.Keg) {
        availableUnitIds.push(1, 2, 3, 4, 5, 12);
      } else if (containerType === containerTypes.Draft) {
        availableUnitIds.push(2, 3, 4);
      }
      return self.all
        .filter(unit => availableUnitIds.includes(unit.id))
        .sort((a, b) => unitPriorities[a.id] - unitPriorities[b.id]);
    },
    getById(id) {
      return self.all.find(unit => unit.id === id);
    },
  }))
  .actions(self => ({
    fetch: flow(function* () {
      try {
        const units = yield api.getUnits();
        self.all = units.data.result;
        return units.data.result;
      } catch (err) {
        self.all = [];
        return Promise.reject(err);
      }
    }),

    setUnits(units) {
      self.all.replace(units);
    },
  }));
