import React, { useContext, useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { ReferenceContext } from 'context/reference';

const UpdateToolbar = () => {
  const ref = useRef();
  const { updateBar } = useContext(ReferenceContext);
  const { setRef } = updateBar;

  useEffect(() => {
    setRef(ref);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Box ref={ref} />;
};

export default UpdateToolbar;
