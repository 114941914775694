import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import {
  DialogContent,
  Box,
  Select,
  MenuItem,
  TextField,
  Typography,
  Slider,
  Divider,
  InputAdornment,
  IconButton,
  useMediaQuery,
  styled,
} from '@mui/material';
import { Refresh } from '@mui/icons-material';

import { useStore } from 'hooks';
import Dialog from 'components/shared/dialogs/Dialog';

const DraftPricingCalculator = ({ open, ...restProps }) => {
  const isTabletAndHeight765OrSmaller = useMediaQuery('(max-height: 765px), (max-width: 1023px)');
  const { containersStore, ui, establishmentStore, reportStore } = useStore();
  const [costPercentage, setCostPercentage] = useState(
    Number(establishmentStore.establishment.default_pour_cost_pct).toFixed(1) || 20.0,
  );
  const [headPercentage, setHeadPercentage] = useState(
    Number(establishmentStore.establishment.default_head_space_pct).toFixed(1) || 10.0,
  );
  const [variancePercentage, setVariancePercentage] = useState(
    reportStore.report_configuration
      ? (100.0 - Number(reportStore.report_configuration?.benchmark_efficiency)).toFixed(1)
      : 22.5,
  );

  const [kegCost, setKegCost] = useState('');
  const [kegSize, setKegSize] = useState({});
  const [draftSize, setDraftSize] = useState({});

  const onReset = useCallback(() => {
    setHeadPercentage(Number(establishmentStore.establishment.default_head_space_pct) || 10.0);
    setCostPercentage(Number(establishmentStore.establishment.default_pour_cost_pct) || 20.0);
    setVariancePercentage(
      reportStore.report_configuration
        ? (100.0 - Number(reportStore.report_configuration.benchmark_efficiency)).toFixed(1)
        : '22.5',
    );
    setKegCost('');
    setKegSize({});
    setDraftSize({});
  }, [
    establishmentStore.establishment.default_head_space_pct,
    establishmentStore.establishment.default_pour_cost_pct,
    reportStore.report_configuration,
  ]);

  const onClose = useCallback(() => {
    ui.setModalVisibility('calculator', false);
  }, [ui]);

  const inputValueFormatter = useCallback(value => {
    return value === '' || Number(value) < 0 ? '' : Number(value);
  }, []);

  const handleChange = useCallback((setterFn, valueFormatterFn = value => value) => {
    return (event, newValue) => {
      setterFn(valueFormatterFn(event.target.value));
    };
  }, []);

  const handleChangeInput = useCallback(event => {
    const regex = /^[1-9]|[1-9][0-9]{0,2}$/;
    const { value, name } = event.target;

    const maxValue = {
      headPercentage: 50,
      costPercentage: 99,
      variancePercentage: 99,
    };

    const setterFn = {
      headPercentage: setHeadPercentage,
      costPercentage: setCostPercentage,
      variancePercentage: setVariancePercentage,
    };

    if (value > maxValue[name]) {
      setterFn[name](maxValue[name]);
      return;
    }

    if (value === '') {
      setterFn[name]('');
      return;
    }

    if (regex.test(value)) {
      setterFn[name](value);
    }
  }, []);

  const handleValidateInput = useCallback(event => {
    const { name, value } = event.target;

    const setterFn = {
      headPercentage: setHeadPercentage,
      costPercentage: setCostPercentage,
      variancePercentage: setVariancePercentage,
    };

    if (value === '') {
      setterFn[name](1);
      return;
    }

    setterFn[name](Number(value).toFixed(1));
  }, []);

  const handleSliderChange = useCallback((setterFn, valueFormatterFn = value => value) => {
    return (event, newValue) => {
      setterFn(valueFormatterFn(newValue));
    };
  }, []);

  const roundDouble = useCallback(
    value => (Math.round((value + Number.EPSILON) * 100) / 100).toFixed(2),
    [],
  );

  const calcTotalRevenue = useCallback(() => {
    // TotalRevenue = Keg Cost * (1.00 / Pour Cost)
    const revenue = Number(roundDouble(Number(kegCost) * (100 / costPercentage)));
    return revenue ? `$${revenue}` : '-';
  }, [costPercentage, kegCost, roundDouble]);

  const calcVolumeToSell = useCallback(() => {
    // Volume to Sell = (Keg Volume * (1.00 - Variance))
    const kegVolume = kegSize.volume_total;
    const mlEquivalent = kegSize.volume_unit?.ml_equivalent;
    const volumeToSell = Number(kegVolume * mlEquivalent * (1 - variancePercentage / 100.0));
    return volumeToSell
      ? `${(volumeToSell / mlEquivalent).toFixed(2)} (${kegSize.volume_unit?.abbreviation})`
      : '-';
  }, [kegSize, variancePercentage]);

  const calcCostPerServing = useCallback(() => {
    // Cost Per Serving = ((Keg Cost / Volume to Sell) * (Serving Size * (1.00 - Head Percentage)))
    const kegVolume = kegSize.volume_total;
    const mlEquivalent = kegSize.volume_unit?.ml_equivalent;
    const volumeToSell = Number(kegVolume * mlEquivalent * (1 - variancePercentage / 100.0));
    const servingSize = draftSize._volume_total_ml;
    const costPerServingSize =
      (kegCost / volumeToSell) * (servingSize * (1 - headPercentage / 100));

    return Number(costPerServingSize) ? `$${costPerServingSize.toFixed(2)}` : '-';
  }, [draftSize, headPercentage, kegCost, kegSize, variancePercentage]);

  const calcNumberOfServings = useCallback(() => {
    // Number of Serving = (Volume to Sell / (Serving Size * (1.00 - Head Percentage)))
    const kegVolume = kegSize.volume_total;
    const mlEquivalent = kegSize.volume_unit?.ml_equivalent;
    const volumeToSell = Number(kegVolume * mlEquivalent * (1 - variancePercentage / 100.0));
    const servingSize = draftSize._volume_total_ml;

    const numberOfServing = volumeToSell / (servingSize * (1 - headPercentage / 100));
    return isNaN(numberOfServing) ? '-' : Math.floor(numberOfServing);
  }, [draftSize, headPercentage, kegSize, variancePercentage]);

  const calcPricePerServing = useCallback(() => {
    // Price per Serving = (Total Revenue / Number of Servings)
    const totalRevenue = Number(roundDouble(Number(kegCost) * (100 / costPercentage)));
    const kegVolume = kegSize.volume_total;
    const mlEquivalent = kegSize.volume_unit?.ml_equivalent;
    const volumeToSell = Number(kegVolume * mlEquivalent * (1 - variancePercentage / 100.0));
    const servingSize = draftSize._volume_total_ml;
    const numberOfServing = volumeToSell / (servingSize * (1 - headPercentage / 100));
    const pricePerServing = totalRevenue / numberOfServing;
    return Number(pricePerServing) ? `$${pricePerServing.toFixed(2)}` : '-';
  }, [
    costPercentage,
    draftSize._volume_total_ml,
    headPercentage,
    kegCost,
    kegSize.volume_total,
    kegSize.volume_unit?.ml_equivalent,
    roundDouble,
    variancePercentage,
  ]);

  if (!containersStore.kegContainers.length || !containersStore.draftContainers.length) {
    return (
      <Dialog open={open} onClose={onClose} {...restProps}>
        <DialogContent>
          <Typography align="center">
            Calculator is missing important data. It seems there is no containers available for the
            selected establishment
          </Typography>
        </DialogContent>
      </Dialog>
    );
  }

  const resetButton = (
    <IconButton color="primary" onClick={onReset}>
      <Refresh />
    </IconButton>
  );
  const desktopResetButton = () => {
    return (
      <IconButton color="primary" onClick={onReset} className="desktopResetBtn">
        <Refresh />
      </IconButton>
    );
  };
  const renderValue = (value, placeholder) =>
    value && Object.keys(value).length ? value.nickname || value.name : placeholder;
  const checkedValue = value => (value && Object.keys(value).length ? value : '');

  return (
    <StyledRootDialog
      open={open}
      onClose={onClose}
      mobileHeaderRight={resetButton}
      desktopHeaderRight={desktopResetButton}
      {...restProps}
    >
      <DialogContent className="content">
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography color="primary">Keg Size</Typography>
          <Select
            style={{ width: 185 }}
            variant="outlined"
            value={checkedValue(kegSize)}
            onChange={handleChange(setKegSize)}
            displayEmpty={true}
            renderValue={value => renderValue(value, 'Select Keg Size')}
          >
            {containersStore.kegContainers.map(unit => (
              <MenuItem key={unit.id} value={unit}>
                <span className="option">{unit.nickname || unit.name}</span>
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography color="primary">Keg Cost ($)</Typography>
          <TextField
            variant="outlined"
            type="number"
            value={kegCost}
            placeholder="$0.00"
            onChange={handleChange(setKegCost, inputValueFormatter)}
            style={{ width: 185 }}
            className="mb-0"
            fullWidth
          />
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
          <Typography color="primary">Serving Size</Typography>
          <Select
            style={{ width: 185 }}
            variant="outlined"
            value={checkedValue(draftSize)}
            onChange={handleChange(setDraftSize)}
            displayEmpty={true}
            renderValue={value => renderValue(value, 'Select Serving Size')}
          >
            {containersStore.draftContainers.map(unit => (
              <MenuItem key={unit.id} value={unit}>
                <span className="option">{unit.nickname || unit.name}</span>
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Divider />
        <Box display="flex" alignItems="center" justifyContent="space-between" pt={1}>
          <Box flexGrow={1} pr={3}>
            <Box mb={1}>
              <Typography color="primary">Head Volume %</Typography>
            </Box>
            <Slider
              value={+headPercentage}
              onChange={handleSliderChange(setHeadPercentage)}
              aria-labelledby="input-slider"
              step={0.1}
              min={0}
              max={50}
              size="small"
            />
          </Box>
          <TextField
            className="textField"
            name="headPercentage"
            value={headPercentage}
            margin="dense"
            variant="filled"
            type="number"
            onChange={handleChangeInput}
            onBlur={handleValidateInput}
            InputProps={{
              className: 'square-black',
              endAdornment: (
                <InputAdornment position="start" className="adornment">
                  %
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box flexGrow={1} pr={3}>
            <Box mb={1}>
              <Typography color="primary">Pour Cost %</Typography>
            </Box>
            <Slider
              value={+costPercentage}
              onChange={handleSliderChange(setCostPercentage)}
              step={0.1}
              min={1}
              max={99}
              aria-labelledby="input-slider"
              size="small"
            />
          </Box>
          <TextField
            className="textField"
            name="costPercentage"
            value={costPercentage}
            margin="dense"
            variant="filled"
            onChange={handleChangeInput}
            onBlur={handleValidateInput}
            InputProps={{
              className: 'square-black',
              endAdornment: (
                <InputAdornment position="start" className="adornment">
                  %
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between" pt={1}>
          <Box flexGrow={1} pr={3}>
            <Box mb={1}>
              <Typography color="primary">Variance (waste)</Typography>
            </Box>
            <Slider
              value={+variancePercentage}
              onChange={handleSliderChange(setVariancePercentage)}
              aria-labelledby="input-slider"
              step={0.1}
              min={1}
              max={99}
              size="small"
            />
          </Box>
          <TextField
            className="textField"
            name="variancePercentage"
            value={variancePercentage}
            margin="dense"
            variant="filled"
            onChange={handleChangeInput}
            onBlur={handleValidateInput}
            InputProps={{
              className: 'square-black',
              endAdornment: (
                <InputAdornment position="start" className="adornment">
                  %
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Divider />
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          pt={1}
          mb={isTabletAndHeight765OrSmaller ? 2 : 3}
        >
          <Typography color="primary">Total Revenue (Goal)</Typography>
          <span className="value">{calcTotalRevenue()}</span>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={isTabletAndHeight765OrSmaller ? 2 : 3}
        >
          <Typography color="primary">Volume to Sell</Typography>
          <span className="value">{calcVolumeToSell()}</span>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={isTabletAndHeight765OrSmaller ? 2 : 3}
        >
          <Typography color="primary">Cost Per Serving</Typography>
          <span className="value">{calcCostPerServing()}</span>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={isTabletAndHeight765OrSmaller ? 2 : 3}
        >
          <Typography color="primary">Number of Servings</Typography>
          <span className="value">{calcNumberOfServings()}</span>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={isTabletAndHeight765OrSmaller ? 2 : 3}
        >
          <Typography color="primary">Price per Serving</Typography>
          <span className="value">{calcPricePerServing()}</span>
        </Box>
      </DialogContent>
    </StyledRootDialog>
  );
};

export default observer(DraftPricingCalculator);

const StyledRootDialog = styled(Dialog)(({ theme: { palette, spacing } }) => ({
  '.content': {
    '& p.MuiTypography-root': {
      fontSize: 20,
      '@media(max-width: 1023px), (max-height: 765px)': {
        fontSize: 18,
      },
    },
  },

  '.value': {
    fontSize: 20,
    fontWeight: 500,
    '@media(max-width: 1023px), (max-height: 765px)': {
      fontSize: 18,
    },
  },

  '.textField': {
    width: 'auto',
    marginBottom: '4px',
    '& .MuiInputBase-root': {
      width: 90,
      '& .MuiInputBase-input': {
        width: 50,
      },
    },
  },

  '.adornment': {
    '& > *': {
      color: palette.white,
      fontWeight: '500 ',
      paddingTop: 2,
      fontSize: 20,
      lineHeight: '20px',
      '@media(max-width: 1023px), (max-height: 765px)': {
        fontSize: 18,
        lineHeight: '18px',
      },
    },
  },

  '.desktopResetBtn': {
    position: 'absolute',
    right: spacing(1),
    top: '50%',
    transform: 'translateY(-50%)',
  },
}));
