import React from 'react';
import { observer } from 'mobx-react';
import { useMatch, useLocation } from 'react-router-dom';
import { Box, Container, styled } from '@mui/material';
import clsx from 'clsx';

import { useStore } from 'hooks';

import Header from './Header';
import Menu from './Menu';
import Drawer from './Drawer';

import {
  HEADER_HEIGHT,
  BIG_HEADER_HEIGHT,
  MENU_COLLAPSED_WIDTH,
  MENU_OPENED_WIDTH,
} from 'config/ui';

export default observer(({ children }) => {
  const { ui } = useStore();
  const isContactUs = useMatch({
    path: `/contact-us`,
  });
  const location = useLocation();
  const { pathname } = location;

  if (pathname) {
    ui.setPathState(pathname);
  }

  const match = ['/on-tap', '/inventory', '/inventory/cooler', '/inventory/ordering'].includes(
    location.pathname,
  );

  return (
    <StyledRootBox display="flex" flexDirection="column">
      <Header />
      <Box display="flex" flexGrow={1}>
        <Box display={{ xs: 'none', md: 'flex' }} sx={{ flexGrow: 1 }}>
          <Menu />
        </Box>
        <Box
          component="main"
          className={clsx('main', {
            contactUs: isContactUs,
            drawerOpen: ui.isOpenedMenu,
            drawerClose: !ui.isOpenedMenu,
            general_page: match,
          })}
        >
          <Container className="container" sx={{ position: 'relative' }}>
            {children}
          </Container>
        </Box>
        <Drawer />
      </Box>
    </StyledRootBox>
  );
});
const StyledRootBox = styled(Box)(({ theme }) => ({
  minHeight: '100%',
  backgroundColor: theme.palette.secondary.dark,

  '& .container': {
    display: 'block',
    flexDirection: 'column',

    height: '100%',
    flexGrow: 1,
    maxWidth: 1580,
  },

  '.main': {
    paddingTop: HEADER_HEIGHT,
    flexGrow: '10 !important',
    display: 'flex',

    flexDirection: 'column',
    background: 'inherit',
    '@media(max-width: 1023px)': {
      maxWidth: '100% !important',
    },
  },
  '.general_page': {
    [theme.breakpoints.down('md')]: {
      paddingTop: BIG_HEADER_HEIGHT,
    },
  },
  '.contactUs': {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundImage: "url('/assets/images/contact-us-mobile.jpg')",
    [theme.breakpoints.up('sm')]: {
      backgroundImage: "url('/assets/images/bg.webp')",
    },
    '& .container': {
      background: 'none',
    },
  },
  '.drawerClose': {
    display: 'flex',
    flexGrow: 1,

    maxWidth: `calc(100% - ${MENU_COLLAPSED_WIDTH}px)`,
    transition: theme.transitions.create('max-width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  '.drawerOpen': {
    display: 'flex',
    flexGrow: 1,

    maxWidth: `calc(100% - ${MENU_OPENED_WIDTH}px)`,
    transition: theme.transitions.create('max-width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));
