import { types, flow } from 'mobx-state-tree';

import api from 'services/API';
import { BarsRoleUser } from 'models/types';

export const barsRoleUserInitialState = {
  isLoaded: false,
  all: [],
};

export const barsRoleUserModel = types
  .model({
    isLoaded: types.boolean,
    all: types.array(BarsRoleUser),
  })
  .views(self => ({
    get bars() {
      return self.all.slice();
    },
  }))
  .actions(self => ({
    fetch: flow(function* () {
      try {
        const response = yield api.getRoleUserBars();
        if (response && response.data && response.data.result) {
          self.all.replace(response.data.result);
          self.isLoaded = true;
        } else {
          self.isLoaded = true;
        }
      } catch (err) {
        self.isLoaded = true;
        console.error(err);
        return Promise.reject(err);
      }
    }),
    findIdsByRoleUserId: id =>
      self.all
        .slice()
        .filter(({ role_user_id }) => role_user_id === id)
        .map(e => e.bar_id),
    findBar: (bar_id, role_user_id) =>
      self.all.slice().find(bar => bar.bar_id === bar_id && bar.role_user_id === role_user_id),
    assignUser: flow(function* (data) {
      try {
        const { bar_id, role_user_id } = data;
        return yield api.addUserToBar({
          role_user_id,
          bar_id,
          archived: false,
        });
      } catch (error) {
        self.isLoaded = false;
        console.error(error);
        return Promise.reject(error);
      }
    }),
    unassignUser: flow(function* (id) {
      try {
        yield api.deleteUserFromBar(id);
      } catch (error) {
        self.isLoaded = false;
        console.error(error);
        return Promise.reject(error);
      }
    }),
    setBarsRoleUser(barsRoleUser) {
      self.all.replace(barsRoleUser);
      self.isLoaded = true;
    },
    addBars(bars) {
      self.all.replace([...self.all, ...bars]);
    },
    removeBars(ids) {
      self.all.replace(self.all.filter(e => !ids.includes(e.id)));
    },
  }));
