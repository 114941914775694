import { types } from 'mobx-state-tree';
const Criteria = types.model({
  cable_color: types.maybeNull(types.string),
  cable_length_m: types.maybeNull(types.number),
});

export const TemperatureSensor = types.model({
  cable_color: types.maybeNull(types.string),
  cable_length_m: types.maybeNull(types.number),
  caption: types.maybeNull(types.string),
  criteria: types.maybeNull(Criteria),
  id: types.maybeNull(types.integer),
  latest_sample_age_seconds: types.maybeNull(types.number),
  latest_temp_c: types.maybeNull(types.number),
});

const Sensors = types.model({
  addr: types.integer,
  connected: types.boolean,
  enumerated: types.boolean,
  latest_heartbeat_age_seconds: types.maybeNull(types.number),
  line_id: types.integer,
  line_sensor_id: types.integer,
  sensor_id: types.integer,
});

const BRUs = types.model({
  addr: types.integer,
  bru_id: types.integer,
  enumerated: types.boolean,
  latest_heartbeat_age_seconds_max: types.maybeNull(types.number),
  latest_heartbeat_age_seconds_min: types.maybeNull(types.number),
  sensors: types.array(Sensors),
});

const Gateway = types.model({
  gateway_id: types.integer,
  latest_heartbeat_age_seconds_max: types.maybeNull(types.number),
  latest_heartbeat_age_seconds_min: types.maybeNull(types.number),
  latest_sample_age_seconds_max: types.maybeNull(types.number),
  latest_sample_age_seconds_min: types.maybeNull(types.number),
  brus: types.maybeNull(types.array(BRUs)),
  temp_sensors: types.array(TemperatureSensor),
});

export const EstablishmentSensors = types.model({
  establishment_id: types.integer,
  establishment_name: types.string,
  latest_heartbeat_age_seconds_min: types.maybeNull(types.number),
  latest_heartbeat_age_seconds_max: types.maybeNull(types.number),
  archived: types.boolean,
  gateways: types.array(Gateway),
});
