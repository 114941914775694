import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'qs';

const useURLQuery = (initialFilters = {}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const decodeParams = () => {
    let params = queryString.parse(location.search, { ignoreQueryPrefix: true });
    for (let key in params) {
      let boolKeywords = {
        true: true,
        false: false,
      };
      if (params[key] in boolKeywords) {
        params[key] = boolKeywords[params[key]];
      }
    }
    return params;
  };

  const [queryParams, setQueryParams] = useState(() => ({
    ...initialFilters,
    ...decodeParams(),
  }));

  useEffect(() => {
    navigate(
      {
        pathname: location.pathname,
        search: queryString.stringify(queryParams, { skipNulls: true }),
      },
      { replace: true },
    );
  }, [navigate, location.pathname, queryParams]);

  return [queryParams, setQueryParams];
};

export default useURLQuery;
