import { types } from 'mobx-state-tree';

const Metadata = types.model({
  servingSizes: types.array(types.integer),
});

export const MenuList = types.model({
  id: types.identifierNumber,
  name: types.maybeNull(types.string),
  establishment_id: types.integer,
  metadata: types.maybeNull(Metadata),
  method: types.integer,
  entry_ids: types.maybeNull(types.array(types.frozen(types.integer))),
  updated_by: types.integer,
  updated_at: types.string,
  archived: types.boolean,
});
