import React, { useEffect, useCallback } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { Box, styled } from '@mui/material';
import WebFont from 'webfontloader';

import { useStore, useToast } from 'hooks';
import { messages } from 'config/messages';
import Loader from 'components/shared/Loader';
import { FONT_LIST } from 'config/fonts';
import palette from 'theme/palette';

import MenuPreview from './Preview';
import SettingsPanel from './SettingsPanel';

const MIN_TILES = 8;
const MAX_HORIZONTAL_TILES = 12;

const sortTiles = (tiles, groupBy) => {
  const sortedTiles = [];
  const isGrouped = Boolean(groupBy !== 'n/a');
  const lists = tiles.reduce((acc, curr) => {
    let category;
    if (groupBy === 'type') {
      category = curr.beverage._type;
    } else if (groupBy === 'category') {
      category = curr.beverage?.metadata?.category || 'Un-categorized';
    } else {
      category = curr.list_id;
    }

    if (!acc[category]) {
      acc[category] = [];
    }

    acc[category].push(curr);
    return acc;
  }, {});

  const sortedLists = Object.keys(lists)
    .sort((a, b) => a.localeCompare(b))
    .reduce((acc, curr) => {
      acc[curr] = lists[curr];
      return acc;
    }, {});

  for (const [key, value] of Object.entries(sortedLists)) {
    const sortBy = value[0].sort_by;
    const isAscending = Boolean(value[0].sort_order === 'ascending');
    const sourceObject = value[0].sort_by === 'sort_value' ? 'line' : 'beverage';

    const sorted = value.sort((a, b) => {
      const x = a[sourceObject][sortBy];
      const y = b[sourceObject][sortBy];
      if (sourceObject === 'beverage') {
        if (isAscending) {
          return x.toLowerCase().localeCompare(y.toLowerCase());
        } else {
          return y.toLowerCase().localeCompare(x.toLowerCase());
        }
      } else {
        if (isAscending) {
          return x - y;
        } else {
          return y - x;
        }
      }
    });

    if (isGrouped) {
      sortedTiles.push(key);
    }

    sortedTiles.push(sorted);
  }

  return sortedTiles.flat();
};

const Editor = ({ design, lists, isCreating, cb }) => {
  const navigate = useNavigate();
  const { menuDesignsStore, itemsStore, linesStore, userStore, skuStore } = useStore();
  const { successToast, errorToast } = useToast();

  useEffect(() => {
    WebFont.load({
      google: {
        families: FONT_LIST,
      },
    });
  }, []);

  const filterValidSkus = useCallback(
    (beverageId, validContainerIds) => {
      return skuStore
        .getDraftSKUsByBeverageId(beverageId, true)
        .filter(({ container_id }) => validContainerIds.includes(container_id))
        .sort((a, b) => {
          const sortKey = Boolean(a.sort_value) ? 'sort_value' : 'id';
          return a[sortKey] - b[sortKey];
        });
    },
    [skuStore],
  );

  const state = useLocalObservable(() => ({
    name: 'Design #' + menuDesignsStore.all.length + 1,
    page: 0,
    lists: [],
    template: 1,
    portrait: true,
    logoColor: palette.primary.main,
    backgroundColor: palette.background.paper,
    beverageNameRowColor: palette.primary.main,
    producerRowColor: palette.white,
    beverageDetailsRowColor: palette.white,
    priceListColor: palette.white,
    titleColor: palette.primary.main,
    bubbleColor: palette.primary.main,
    bubbleTextColor: palette.background.paper,
    kegColor: palette.primary.main,
    footerColor: palette.primary.main,
    categoryColor: palette.primary.main,
    font: 'Montserrat, sans-serif',
    establishmentInfoPosition: 'header',
    orientation: 'horizontal',
    isGroupedByCategory: false,
    isKegLevelVisible: true,
    isProducerVisible: true,
    isProducerLocationVisible: true,
    isABVVisible: true,
    isIBUVisible: true,
    isStyleVisible: true,
    isTypeVisible: true,
    isPriceVisible: true,
    isCurrencyVisible: true,
    roundNumbers: false,
    areContainersVisible: true,
    areKeywordsVisible: true,
    tilesPerPage: 12,
    tilesColumns: 2,
    title: 'Enter a custom title...',
    pageDurationInSeconds: 10,
    pollingDuration: 300,
    sortBy: '_name',
    sortOrder: 'ascending',
    groupBy: 'n/a',
    flipVertical: false,
    get isVertical() {
      return state.orientation === 'vertical';
    },
    get isHorizontal() {
      return state.orientation === 'horizontal';
    },
    get pageCount() {
      return state.tilesGroupedByPage.length;
    },
    get pageDurationInMS() {
      return state.pageDurationInSeconds * 1000;
    },
    get isPaginationEnabled() {
      return state.pageCount > 1;
    },
    get tilesGroupedByPage() {
      let tiles = [];
      if (state.lists.slice().length === 0) {
        tiles = linesStore.activeLines.reduce((acc, curr) => {
          const beverage = curr.beverage;
          const skus = skuStore.getDraftSKUsByBeverageId(beverage.id, true);
          const item = curr.item;

          acc.push({
            line: curr,
            beverage,
            skus,
            item,
            sort_order: state.sortOrder,
            sort_by: state.sortBy,
            groupBy: state.groupBy,
          });
          return acc;
        }, []);
      } else {
        tiles = state.lists
          .reduce((acc, curr) => {
            acc.push(
              curr.entry_ids.slice().map(id => ({
                id,
                list_id: curr.id,
                method: curr.method,
                sort_order: state.sortOrder,
                sort_by: state.sortBy,
                groupBy: state.groupBy,
                servingSizes: curr.metadata.servingSizes,
              })),
            );
            return acc;
          }, [])
          .flat()
          .reduce((acc, curr) => {
            let obj = {
              list_id: curr.list_id,
              sort_order: state.sortOrder,
              sort_by: state.sortBy,
              groupBy: state.groupBy,
              method: curr.method,
            };
            if (curr.method === 3) {
              const item = itemsStore.getItemById(curr.id);
              const beverage = item.beverage;
              const skus = filterValidSkus(beverage.id, curr.servingSizes);
              obj = {
                ...obj,
                item,
                beverage,
                skus,
              };
            } else {
              const line = linesStore.getLineById(curr.id);
              const item = curr.method === 1 ? line.item : line.nextQueuedItem;
              if (!item) return acc;
              const beverage = item.beverage;
              const skus = filterValidSkus(beverage.id, curr.servingSizes);
              obj = {
                ...obj,
                line,
                beverage,
                item,
                skus,
              };
            }

            let isDuplicate = false;
            const currentId = obj.beverage.id;
            acc.forEach(item => {
              if (item.beverage.id === currentId) {
                isDuplicate = true;
              }
            });

            !isDuplicate && acc.push(obj);

            return acc;
          }, []);
      }

      tiles = sortTiles(tiles, state.groupBy);

      const pages = Math.ceil(tiles.length / state.tilesPerPage);

      return Array.from(new Array(pages), () => {
        return tiles.splice(0, state.tilesPerPage);
      });
    },
    get tiles() {
      return state.tilesGroupedByPage[state.page];
    },
    get tilesByColumn() {
      const tilesDuplicate = state.tiles ? [...state.tiles] : [];
      return Array.from(new Array(state.tilesColumns), () => {
        return tilesDuplicate.splice(0, Math.ceil(state.tilesPerPage / state.tilesColumns));
      });
    },
    set(field, value) {
      state[field] = value;
    },
    setPage(page) {
      state.set('page', page);
    },
    setState(values) {
      for (const [key, value] of Object.entries(values)) {
        state[key] = value;
      }
    },
    handleCheck(event) {
      const { name } = event.target;
      state.set(name, !state[name]);
    },
    handleChange(event) {
      const { name, value } = event.target;
      state.set(name, value);
    },
    handleSelectColor(name, value) {
      state.set(name, value);
    },
    handleOrientationChange() {
      state.set('orientation', state.orientation === 'vertical' ? 'horizontal' : 'vertical');
      if (state.orientation === 'horizontal' && state.tilesPerPage > MAX_HORIZONTAL_TILES) {
        state.set('tilesPerPage', MAX_HORIZONTAL_TILES);
      } else if (state.tilesPerPage < MIN_TILES) {
        state.set('tilesPerPage', MIN_TILES);
      } else {
        state.set('tilesPerPage', state.tilesPerPage);
      }
    },
    handleFlipVertical() {
      state.set('flipVertical', !state.flipVertical);
    },
    handleTemplateChange(template) {
      state.set('template', template);
    },
  }));

  useEffect(() => {
    if (design) {
      state.setState({
        lists: lists || [],
        template: design.template,
        portrait: design.layout.portrait,
        logoColor: design.layout.logoColor,
        backgroundColor: design.layout.backgroundColor,
        beverageNameRowColor: design.layout.beverageNameRowColor,
        producerRowColor: design.layout.producerRowColor,
        beverageDetailsRowColor: design.layout.beverageDetailsRowColor,
        priceListColor: design.layout.priceListColor,
        titleColor: design.layout.titleColor,
        bubbleColor: design.layout.bubbleColor,
        bubbleTextColor: design.layout.bubbleTextColor,
        kegColor: design.layout.kegColor,
        footerColor: design.layout.footerColor,
        categoryColor: design.layout.categoryColor,
        font: design.layout.font,
        establishmentInfoPosition: design.layout.establishmentInfoPosition,
        orientation: design.portrait ? 'vertical' : 'horizontal',
        isGroupedByCategory: design.layout.isGroupedByCategory,
        isKegLevelVisible: design.layout.isKegLevelVisible,
        isProducerVisible: design.layout.isProducerVisible,
        isProducerLocationVisible: design.layout.isProducerLocationVisible,
        isABVVisible: design.layout.isABVVisible,
        isIBUVisible: design.layout.isIBUVisible,
        isStyleVisible: design.layout.isStyleVisible,
        isTypeVisible: design.layout.isTypeVisible,
        isPriceVisible: design.layout.isPriceVisible,
        isCurrencyVisible: design.layout.isCurrencyVisible,
        roundNumbers: design.layout.roundNumbers,
        areContainersVisible: design.layout.areContainersVisible,
        areKeywordsVisible: design.layout.areKeywordsVisible,
        tilesPerPage: design.layout.tilesPerPage,
        tilesColumns: design.layout.tilesColumns,
        title: design.layout.title,
        name: design.name,
        pageDurationInSeconds: design.layout.pageDurationInSeconds,
        pollingDuration: design.layout.pollingDuration || 300,
        sortBy: design.layout.sortBy,
        sortOrder: design.layout.sortOrder,
        groupBy: design.layout.groupBy,
        flipVertical: design.layout.flipVertical,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lists, design]);

  const onSave = async (triggerRedirect = false) => {
    try {
      const body = {
        ...(!design && { establishment_id: userStore.currentRole._establishment_id }),
        name: state.name,
        portrait: state.orientation === 'vertical',
        template: state.template,
        category: 1,
        layout: {
          logoColor: state.logoColor,
          backgroundColor: state.backgroundColor,
          beverageNameRowColor: state.beverageNameRowColor,
          producerRowColor: state.producerRowColor,
          beverageDetailsRowColor: state.beverageDetailsRowColor,
          priceListColor: state.priceListColor,
          titleColor: state.titleColor,
          bubbleColor: state.bubbleColor,
          bubbleTextColor: state.bubbleTextColor,
          kegColor: state.kegColor,
          footerColor: state.footerColor,
          categoryColor: state.categoryColor,
          font: state.font,
          establishmentInfoPosition: state.establishmentInfoPosition,
          isGroupedByCategory: state.categories,
          isKegLevelVisible: state.isKegLevelVisible,
          isProducerVisible: state.isProducerVisible,
          isProducerLocationVisible: state.isProducerLocationVisible,
          isABVVisible: state.isABVVisible,
          isIBUVisible: state.isIBUVisible,
          isStyleVisible: state.isStyleVisible,
          isTypeVisible: state.isTypeVisible,
          isPriceVisible: state.isPriceVisible,
          isCurrencyVisible: state.isCurrencyVisible,
          roundNumbers: state.roundNumbers,
          areContainersVisible: state.areContainersVisible,
          areKeywordsVisible: state.areKeywordsVisible,
          tilesPerPage: state.tilesPerPage,
          tilesColumns: state.tilesColumns,
          title: state.title,
          pageDurationInSeconds: state.pageDurationInSeconds,
          sortBy: state.sortBy,
          sortOrder: state.sortOrder,
          groupBy: state.groupBy,
          flipVertical: state.flipVertical,
          pollingDuration: state.pollingDuration,
        },
      };

      if (!design) {
        const response = await menuDesignsStore.create(body);
        if (response?.data?.row) {
          successToast(messages.menu_designs.CREATE_SUCCESS, response);
        }
      } else {
        const response = await menuDesignsStore.update(design.id, body);
        if (response?.data?.row) {
          successToast(messages.menu_designs.UPDATE_SUCCESS, response);
        }
      }
    } catch (err) {
      errorToast(err);
    } finally {
      if (cb) return cb();
      if (triggerRedirect) navigate(-1);
    }
  };

  if (!design && !isCreating) {
    return <Loader fullScreen withShadow />;
  }

  return (
    <StyledRootBox>
      <div className="settingsPanel">
        <SettingsPanel state={state} onSave={onSave} />
      </div>
      <div className="preview">
        <MenuPreview state={state} />
      </div>
    </StyledRootBox>
  );
};

export default observer(Editor);

const StyledRootBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  '.settingsPanel': {
    flex: '0 0 350px',
    background: theme.palette.secondary.dark,
    top: 0,
    position: 'sticky',
    height: '85vh',
    overflowY: 'scroll',
  },
  '.preview': {
    flexGrow: 1,
    overflow: 'auto',
  },
}));
