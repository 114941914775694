import { types, flow } from 'mobx-state-tree';
import api from 'services/API';
import { RoleUser } from './types';
import { getRootStore } from './root';

export const roleUserStoreInitialState = {
  all: [],
};

export const roleUserStore = types
  .model({
    all: types.array(RoleUser),
  })
  .views(self => ({
    get availableRoleUsers() {
      try {
        const { userStore } = getRootStore();
        return self.all
          .filter(roleUser => {
            if (roleUser && roleUser.user) {
              if (userStore.isBarTrackUser) {
                return true;
              }
              return !roleUser.user.isBarTrackUser;
            }
            return false;
          })
          .sort((a, b) => {
            const userA = a.user.fullName || a.user.email;
            const userB = b.user.fullName || b.user.email;
            if (userA < userB) {
              return -1;
            } else if (userA > userB) {
              return 1;
            } else {
              return 0;
            }
          });
      } catch (err) {
        return [];
      }
    },
    get coolerColdRoleUsers() {
      return self.availableRoleUsers.filter(roleUser => roleUser.not_cooler_cold);
    },
    get coolerWarmRoleUsers() {
      return self.availableRoleUsers.filter(roleUser => roleUser.not_cooler_warm);
    },
    get beverageColdRoleUsers() {
      return self.availableRoleUsers.filter(roleUser => roleUser.not_bev_cold);
    },
    get beverageWarmRoleUsers() {
      return self.availableRoleUsers.filter(roleUser => roleUser.not_bev_hot);
    },
    get pressureLowRoleUsers() {
      return self.availableRoleUsers.filter(roleUser => roleUser.not_pr_low);
    },
    get pressureHighRoleUsers() {
      return self.availableRoleUsers.filter(roleUser => roleUser.not_pr_hi);
    },
    get leakRoleUsers() {
      return self.availableRoleUsers.filter(roleUser => roleUser.not_leak);
    },
    get outOfHoursRoleUsers() {
      return self.availableRoleUsers.filter(roleUser => roleUser.not_out_of_hours);
    },
    get kegRoleUsers() {
      return self.availableRoleUsers.filter(roleUser => roleUser.not_keg);
    },
    get dailyReportsUsers() {
      return self.availableRoleUsers.filter(roleUser => roleUser.reports_flag);
    },
    get weeklyReportsUsers() {
      return self.availableRoleUsers.filter(roleUser => roleUser.reports_weekly);
    },
    get monthlyReportsUsers() {
      return self.availableRoleUsers.filter(roleUser => roleUser.reports_monthly);
    },
  }))
  .actions(self => ({
    //action never used
    fetch: flow(function* () {
      try {
        const response = yield api.getRoleUsers();
        self.all = response.data.result;
        return response.data.result;
      } catch (err) {
        self.all = [];
        console.error(err);
        return Promise.reject(err);
      }
    }),

    setRoleUsers(roleUsers) {
      self.all.replace(roleUsers);
    },
  }));
