import React from 'react';
import clsx from 'clsx';
import {
  Dialog as MuiDialog,
  DialogTitle,
  Typography,
  IconButton,
  Box,
  styled,
  alpha,
} from '@mui/material';
import { Close as CloseIcon, ArrowBackIos as BackIcon } from '@mui/icons-material';

import { useMediaQueries } from 'hooks';

import MobileDialogHeader from './MobileDialogHeader';

const Dialog = ({
  title,
  hideTitle = false,
  mobileHeaderCenter = null,
  mobileHeaderRight = null,
  desktopHeaderRight = null,
  backIcon = <BackIcon />,
  labelledby = 'dialog',
  children,
  fullScreen,
  fullWidth = false,
  maxWidth = false,
  maxHeight = false,
  withoutGradient = false,
  onBack,
  onClose,
  className,
  preventClosing = false,
  disableMobileFullscreen = false,
  breakpoint = 'sm',
  hideCloseIcon = false,
  ...restProps
}) => {
  const { isMobileOrSmaller } = useMediaQueries();
  const handleClose = (event, reason) => {
    if (preventClosing && ['backdropClick', 'escapeKeyDown'].includes(reason)) return;
    if (reason === 'backdropClick') event?.stopPropagation();

    onClose();
  };

  return (
    <StyledRootDialog
      aria-labelledby={labelledby}
      classes={{
        paper: clsx('paper', className, {
          bgWithoutGradient: withoutGradient,
          maxHeight: maxHeight,
        }),
      }}
      fullScreen={fullScreen || (!disableMobileFullscreen && isMobileOrSmaller)}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      onClose={handleClose}
      breakpoint={breakpoint}
      {...restProps}
    >
      {!hideTitle && (
        <DialogTitle disabletypography="true" onClose={handleClose} className="titleDark">
          <MobileDialogHeader
            breakpoint={breakpoint}
            sx={{ display: disableMobileFullscreen ? 'none' : 'flex' }}
          >
            <IconButton
              aria-label="close1"
              onClick={onBack && typeof onBack === 'function' ? onBack : handleClose}
              className="btnBack"
            >
              {backIcon}
            </IconButton>
            {mobileHeaderCenter || (
              <Typography variant="h5" className="header">
                {title}
              </Typography>
            )}
            {mobileHeaderRight}
          </MobileDialogHeader>
          <Box alignItems="center" sx={{ display: { xs: 'none', [breakpoint]: 'flex' } }}>
            {!hideCloseIcon && desktopHeaderRight && (
              <IconButton aria-label="close2" onClick={handleClose} className="btnCloseLeft">
                <CloseIcon />
              </IconButton>
            )}
            <Typography variant="h5">{title}</Typography>
            {desktopHeaderRight && desktopHeaderRight()}
            {!hideCloseIcon && !desktopHeaderRight && (
              <IconButton aria-label="close3" onClick={handleClose} className="btnClose">
                <CloseIcon />
              </IconButton>
            )}
          </Box>
        </DialogTitle>
      )}
      {children}
    </StyledRootDialog>
  );
};

export default Dialog;

const StyledRootDialog = styled(MuiDialog, {
  shouldForwardProp: prop => prop !== 'breakpoint',
})(({ theme: { palette, spacing, breakpoints }, breakpoint }) => ({
  zIndex: 1201,

  '.paper': {
    boxShadow: `4px 4px 10px ${alpha(palette.black, 0.4)}`,
    background: palette.secondary.dark,
    [breakpoints.up(breakpoint)]: {
      background: palette.secondary.greyLight,
    },
    [breakpoints.down(breakpoint)]: {
      margin: 0,
    },
  },
  '.header': {
    [breakpoints.down(breakpoint)]: {
      fontSize: 18,
      lineHeight: 1.2,
    },
  },
  '.bgWithoutGradient': {
    [breakpoints.up(breakpoint)]: {
      background: palette.secondary.greyLight,
    },
    '& .MuiDialogContent-root': {
      background: 'inherit',
      '& .search': {
        top: 0,
      },
      '& .sticky-title': {
        top: 58,
      },
      '& .scroll-top': {
        bottom: 8,
      },
      '& .btn--scroll-top': {
        marginRight: 8,
      },
    },
  },
  '.btnClose': {
    position: 'absolute',
    right: spacing(1),
    top: '50%',
    transform: 'translateY(-50%)',
  },
  '.btnBack': {
    '& svg': {
      transform: 'translateX(5px)',
    },
  },
  '.btnCloseLeft': {
    position: 'absolute',
    left: spacing(1),
    top: '50%',
    transform: 'translateY(-50%)',
  },
  '.maxHeight': {
    [breakpoints.up(breakpoint)]: {
      height: 'calc(100vh - 80px)',
    },
  },
  '.titleDark': {
    background: palette.secondary.greyLight,
  },
}));
