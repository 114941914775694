import clsx from 'clsx';

const commonUtilities = {
  classHandlerGenerator: (staticValue, classes, basicClassName) => dynamicValue => {
    const selected = dynamicValue === staticValue ? classes.selected : null;

    return clsx(classes[basicClassName], selected);
  },

  validateResponse: response => !!(response && response.data && response.data.result),

  lazyRetry: callback => {
    return new Promise((resolve, reject) => {
      return callback()
        .then(component => {
          window.sessionStorage.setItem('retry-lazy-refreshed', 'false');
          resolve(component);
        })
        .catch(error => {
          if (!JSON.parse(window.sessionStorage.getItem('retry-lazy-refreshed') || 'false')) {
            window.sessionStorage.setItem('retry-lazy-refreshed', 'true');
            return window.location.reload();
          }
          reject(error);
        });
    });
  },
};

export default commonUtilities;
