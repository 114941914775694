import { types, flow } from 'mobx-state-tree';
import api from 'services/API';
import { LineTap } from './types';
import { getRootStore } from 'models/root';

export const lineTapsInitialState = {
  all: [],
  isLoaded: false,
};

const LineTapWithViews = LineTap.views(self => ({
  get tap() {
    const root = getRootStore();
    return root.tapsStore.taps.find(el => el.id === self.tap_id);
  },
}));

export const lineTapsModel = types
  .model({
    all: types.array(LineTapWithViews),
    isLoaded: types.boolean,
  })
  .views(self => ({
    get lineTaps() {
      return self.all;
    },
  }))
  .actions(self => {
    return {
      fetchLineTaps: flow(function* () {
        try {
          const response = yield api.getLineTaps();
          if (response && response.data && response.data.result) {
            self.all.replace(response.data.result);
          }
          self.isLoaded = true;
        } catch (err) {
          self.isLoaded = false;
          return Promise.reject(err);
        }
      }),

      disconnectCurrentTap: flow(function* (line_ids) {
        try {
          const response = yield api.disconnectCurrentTap(
            line_ids.map(line_id => ({
              line_id,
              tap_status_code: 0,
            })),
          );
          if (response?.data?.result?.disconnected?._line_taps[0]) {
            const updatedLineTaps = response?.data?.result?.disconnected?._line_taps;
            const updatedIds = updatedLineTaps.map(e => e.id);

            self.all.replace([
              ...self.all.filter(e => !updatedIds.includes(e.id)),
              ...updatedLineTaps,
            ]);
          }
          self.isLoaded = true;
        } catch (err) {
          self.isLoaded = false;
          return Promise.reject(err);
        }
      }),

      setLineTaps(lineTaps) {
        const filtered = lineTaps.filter(({ connected_to }) => !connected_to);

        self.all.replace(filtered);
        self.isLoaded = true;
      },
    };
  });
