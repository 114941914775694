import React from 'react';
import { observer } from 'mobx-react';
import {
  Box,
  Card,
  CardActionArea,
  ListItem,
  Typography,
  List,
  ListItemText,
  styled,
} from '@mui/material';
import clsx from 'clsx';

import menuTemplates from 'config/menuTemplates';

const Templates = ({ state }) => {
  const TemplateCard = ({ template, active }) => {
    return (
      <Card
        className={clsx('card', {
          active: active,
        })}
        onClick={() => state.handleTemplateChange(template.id)}
      >
        <CardActionArea>
          <Box display="flex" justifyContent="space-between">
            <List className="list">
              {template.rows.map(({ name, value }, index) => (
                <ListItem key={index}>
                  <ListItemText>
                    <Box component="span" sx={{ fontWeight: 'bold', mr: 1 }}>
                      {name}:
                    </Box>
                    {value}
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </Box>
        </CardActionArea>
      </Card>
    );
  };

  return (
    <StyledRootBox>
      <Typography className="heading">Bar & Restaurant Layouts</Typography>
      <Box py={2}>
        {menuTemplates
          .filter(({ type }) => type === 'bar')
          .map((template, index) => (
            <TemplateCard key={index} template={template} active={template.id === state.template} />
          ))}
      </Box>

      <Typography className="heading">Brewery Layouts</Typography>
      <Box pt={2}>
        {menuTemplates
          .filter(({ type }) => type === 'brewery')
          .map((template, index) => (
            <TemplateCard key={index} template={template} active={template.id === state.template} />
          ))}
      </Box>
    </StyledRootBox>
  );
};

export default observer(Templates);

const StyledRootBox = styled(Box)(({ theme }) => ({
  '.tabsOverride': {
    position: 'sticky',
    background: theme.palette.secondary.dark,
    zIndex: 1000,
    top: 0,
  },
  '.card': {
    margin: `0 0 ${theme.spacing(1)}`,
  },
  '.active': {
    background: theme.palette.secondary.light,
  },
  '.heading': {
    fontSize: theme.typography.pxToRem(22),
    fontWeight: theme.typography.fontWeightRegular,
  },
  '.chipContainer': {
    display: 'flex',
    alignItems: 'end',
    padding: '8px',
  },
  '.list': {
    '& li': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
}));
