import { useEffect } from 'react';
import { autorun } from 'mobx';

const useAutorunEffect = (callback, dependencies) => {
  useEffect(() => {
    return autorun(callback);
    // we can not add callback to dependency array -
    // it will trigger new effects each time component renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
};

export default useAutorunEffect;
