import React, { useState, useEffect } from 'react';
import { Button, styled, Box, Typography, CircularProgress } from '@mui/material';
import { LogoBarTrackIcon } from 'assets/icons';

import Loader from 'components/shared/Loader';

const MissingData = ({ onReload, isLoading, message, title, withBackground }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShow(true), 10000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  if (!show) return <Loader fullScreen withShadow />;

  return (
    <BoxComponent withBackground={withBackground}>
      <LogoBarTrackIconComponent />
      <MessageBoxComponent>
        <TitleTypographyComponent variant="h4">{title}</TitleTypographyComponent>
        {message && <MessageTypographyComponent variant="h4">{message}</MessageTypographyComponent>}
        <Button variant="contained" color="primary" disabled={isLoading} onClick={onReload}>
          {isLoading ? (
            <>
              Loading <LoaderComponent color="secondary" size={14} />
            </>
          ) : (
            'Reload'
          )}
        </Button>
      </MessageBoxComponent>
    </BoxComponent>
  );
};

export default MissingData;

export const BoxComponent = styled(Box, {
  shouldForwardProp: props => props !== 'withBackground',
})(({ withBackground }) => {
  const withBackgroundStyles = withBackground
    ? {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top',
        backgroundImage: "url('/assets/images/bg.webp')",
      }
    : {};
  return {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
    paddingRight: '20px',
    paddingLeft: '20px',

    ...withBackgroundStyles,
  };
});

export const LogoBarTrackIconComponent = styled(LogoBarTrackIcon)(() => {
  return {
    position: 'absolute',
    top: '50px',
    left: '50px',
    width: 160,
    height: 44,
  };
});

export const MessageBoxComponent = styled(Box)(() => {
  return {
    alignSelf: 'center',
  };
});

export const TitleTypographyComponent = styled(Typography)(() => {
  return {
    fontSize: 26,
    fontWeight: 500,
    marginBottom: 14,
    lineHeight: '32px',
  };
});

export const MessageTypographyComponent = styled(Typography)(() => {
  return {
    fontSize: 16,
    fontWeight: 400,
    marginBottom: 14,
    lineHeight: '18px',
    maxWidth: 400,
  };
});

export const LoaderComponent = styled(CircularProgress)(() => {
  return {
    marginLeft: 8,
  };
});
