import React, { useCallback, useMemo, useState } from 'react';
import { Box, Button, Checkbox, FormControlLabel, Typography, Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import { InfoOutlined } from '@mui/icons-material';
import debounce from 'lodash/debounce';

import { messages } from 'config/messages';
import { useMediaQueries, useStore, useToast } from 'hooks';
import palette from 'theme/palette';
import MobileHeader from 'components/shared/MobileHeader';
import BackButton from 'components/shared/BackButton';
import { DynamicInput } from '../_components/DynamicInput/DynamicInput';

const BarTrackAdmin = () => {
  const { isDesktopOrGreater } = useMediaQueries();
  const { isTabletOrSmaller } = useMediaQueries();
  const { reportStore } = useStore();
  const { successToast, errorToast } = useToast();

  const [settings, setSettings] = useState({
    report_delivery_status_code: reportStore.report_configuration?.report_delivery_status_code,
    volume_only: reportStore.report_configuration?.volume_only,
  });

  const patch = useMemo(
    () =>
      debounce(async body => {
        try {
          const res = await reportStore.patch(body);
          setSettings(prevState => ({
            ...prevState,
            ...body,
          }));
          successToast(res, messages.establishment.UPDATE_SUCCESS);
        } catch (err) {
          errorToast(messages.establishment.UPDATE_FAILED);
        }
      }, 500),
    [errorToast, reportStore, successToast],
  );

  const handleChangeSettings = useCallback(
    (newValue, type) => {
      setSettings(prevState => ({
        ...prevState,
        [type]: newValue,
      }));

      patch({
        [type]: newValue,
      });
    },
    [patch],
  );

  const handleChangeCheckboxes = useCallback(
    (e, type) => {
      e.persist();
      setSettings(prevState => ({
        ...prevState,
        [type]: e.target.checked,
      }));

      patch({
        [type]: e.target.checked,
      });
    },
    [patch],
  );

  return (
    <>
      <MobileHeader breakpoint={'sm'}>
        <BackButton previousLocation={'/reports/configuration'} />
        <Typography variant={'h5'}>BarTrack Admin</Typography>
      </MobileHeader>
      <StyledRootBox className={'bartrackAdmin'} pb={8}>
        {isTabletOrSmaller ? (
          <Box my={2} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Typography variant="h5" sx={{ marginRight: '10px' }}>
              BarTrack Admin
            </Typography>
            <Tooltip title="Account management that only BarTrack can see.">
              <InfoOutlined sx={{ fill: palette.whiteEmphasis.medium }} />
            </Tooltip>
          </Box>
        ) : (
          <Box mb={3} textAlign={'left'} width={'100%'}>
            <Typography variant={'h5'} mb={1}>
              BarTrack Admin
            </Typography>
            <Typography variant={'body1'} color={palette.whiteEmphasis.medium}>
              Account management that only BarTrack can see.
            </Typography>
            <Box mt={3}>
              <Button
                disabled
                variant={'contained'}
                style={{ borderColor: palette.primary.main, textTransform: 'capitalize' }}
              >
                Release Reports
              </Button>
            </Box>
          </Box>
        )}
        <Box className={'inputContainer'}>
          <Box>
            <DynamicInput
              title={'Customer Status'}
              type={'select'}
              name={'report_delivery_status_code'}
              valueState={settings}
              handleChange={handleChangeSettings}
              stacked
              values={[
                { value: 0, label: 'Onboarding, Reports Not Released' },
                {
                  value: 1,
                  label: 'Onboarded, Reports Pending Approval',
                },
                {
                  value: 2,
                  label: 'Reports Released',
                },
              ]}
            />
            <DynamicInput
              title={'BarTrack Manager'}
              type={'select'}
              stacked
              valueState={settings}
              values={[{ value: 0, label: 'N/A' }]}
              disabled
            />
          </Box>
          <Box mb={2} ml={-1} className={'checkboxContainer'}>
            <FormControlLabel
              disabled
              control={
                <Checkbox
                  className="checkbox"
                  // checked={}
                  // onChange={e => handleChangeCheckboxes(e, '')}
                  // name=""
                  color="primary"
                />
              }
              label="Pause Report Delivery"
            />
            <FormControlLabel
              disabled
              control={
                <Checkbox
                  className="checkbox"
                  // checked={}
                  // onChange={e => handleChangeCheckboxes(e, '')}
                  // name=""
                  color="primary"
                />
              }
              label="Pause Report Notifications"
            />
            <FormControlLabel
              control={
                <Checkbox
                  className="checkbox"
                  checked={settings.volume_only}
                  onChange={e => handleChangeCheckboxes(e, 'volume_only')}
                  name="volume_only"
                  color="primary"
                />
              }
              label="Volume Only Reports"
            />
          </Box>
          {!isDesktopOrGreater && (
            <Button
              disabled
              variant={'outlined'}
              fullWidth
              style={{ borderColor: palette.primary.main, textTransform: 'capitalize' }}
            >
              Release Reports
            </Button>
          )}
        </Box>
      </StyledRootBox>
    </>
  );
};

const StyledRootBox = styled(Box)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('md')]: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '64px',
  },
  '.barTrackAdminInputs': {
    display: 'grid',
    gap: '16px',
    gridTemplateColumns: '1fr',
  },
  '.inputContainer': {
    [theme.breakpoints.up('md')]: {
      backgroundColor: theme.palette.background.overlay1,
      borderRadius: '11px',
      padding: '32px 40px',
    },
    '.checkboxContainer': {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {},
    },
  },
}));

export default BarTrackAdmin;
