import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENV,
    release: `pwa@${process.env.REACT_APP_VERSION}`,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.25,
    beforeSend(event, hint) {
      const error = hint.originalException;
      if (error?.response?.data?.error?.detail?.includes('Key is still referenced')) {
        return null;
      }
      const isConnectionRelatedError = [
        'Cannot connect to server due to slow Internet connection.',
        'Internet connection is absent. Check your connection.',
      ].some(message => error?.message?.includes(message));
      if (isConnectionRelatedError) {
        return null;
      }
      return event;
    },
  });
}
