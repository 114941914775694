export const ui_admin = 'ui/admin';
export const ui_owner = 'ui/owner';
export const ui_manager = 'ui/manager';
export const ui_login = 'ui/login';

export const ui_cleanings = 'ui/cleanings';
export const ui_config = 'ui/config';

export const ui_inv = 'ui/inv';
export const ui_inv_del = 'ui/inv-del';

export const ui_keg_ch = 'ui/keg-ch';
export const ui_keg_ed = 'ui/keg-ed';

export const ui_mybev = 'ui/mybev';
export const ui_qual = 'ui/qual';
export const ui_roles = 'ui/roles';
export const ui_rpts = 'ui/rpts';
export const ui_skus = 'ui/skus';
export const ui_maint = 'ui/maint';
export const ui_users = 'ui/users';

export const ui_dm = 'ui/digitalmenu';
