import { format } from 'date-fns';
import { addHours } from 'date-fns';
import { subHours } from 'date-fns';
import { differenceInSeconds } from 'date-fns';
import { isDate } from 'date-fns';
import { formatDuration } from 'date-fns';
import { intervalToDuration } from 'date-fns';
import { isValid } from 'date-fns';
import { isToday } from 'date-fns';
import { isYesterday } from 'date-fns';

import { getRootStore } from 'models/root';

const dateUtilities = {
  convertUTCToLocal: timeHM =>
    format(new Date(`${new Date().toISOString().split('T')[0]}T${timeHM}:00.000Z`), 'p'),

  convertToken: timeHM =>
    format(new Date(`${new Date().toISOString().split('T')[0]}T${timeHM}:00.000`), 'p'),

  getDateInDays: days => {
    const startDate = new Date();
    const endDate = startDate.setDate(startDate.getDate() + days);
    return format(new Date(endDate), 'y-MM-d');
  },

  makeAgeDaysHours: startDate => {
    const difference = differenceInSeconds(new Date(new Date().toISOString()), new Date(startDate));
    const days = Math.floor(difference / 86400);
    const leftSeconds = difference % 86400;
    const hours = Math.floor(leftSeconds / 3600);
    return `${days}d ${hours}h`;
  },

  getTimestampToNowInSeconds: startDate => {
    return differenceInSeconds(
      addHours(new Date(), new Date().getTimezoneOffset() / 60),
      new Date(startDate),
    );
  },

  makeDateFormatMD: date => {
    return date ? format(new Date(date).getTime(), 'M/d') : 'N/A';
  },

  makeDuration: (startDate, finishDate) => {
    if (!startDate || !finishDate) {
      return '-';
    }
    const difference = differenceInSeconds(new Date(finishDate), new Date(startDate));
    if (difference && Number.isInteger(difference) && difference < 0) {
      return '-';
    }
    if (difference > 86400) {
      const days = Math.floor(difference / 86400);
      const leftSeconds = difference % 86400;
      const hours = Math.floor(leftSeconds / 3600);
      return `${days}d ${hours}h`;
    }
    const leftSecondsForMinutes = difference % 3600;
    const seconds = difference % 60;
    const hours = Math.floor(difference / 3600);
    const minutes = Math.floor(leftSecondsForMinutes / 60);
    if (hours && hours > 0) {
      return `${hours}h ${minutes}m`;
    }
    if (minutes && minutes > 0) {
      return `${minutes}m`;
    }
    if (seconds && seconds > 0) {
      return `${seconds}s`;
    }
    if (seconds === 0) {
      return `${seconds}s`;
    }
    return '-';
  },

  formatDate: date => {
    if (!date || !isDate(new Date(date))) {
      return '-';
    }
    return format(new Date(date), 'h:mm a');
  },

  getDifference: (startDate, finishDate) => {
    return differenceInSeconds(new Date(finishDate), new Date(startDate));
  },

  makeDateFormatMDYYYY: date => {
    return date ? format(new Date(date).getTime(), 'M/d/u') : null;
  },

  makeDateFormatMDYY: date => {
    return date ? format(new Date(date).getTime(), 'M/d/yy') : null;
  },

  durationToSeconds: duration => {
    const { days, hours, minutes, seconds } = duration;
    let total = 0;
    if (days) total += days * 24 * 60 * 60;
    if (hours) total += hours * 60 * 60;
    if (minutes) total += minutes * 60;
    if (seconds) total += seconds;

    return total;
  },

  secondsToDuration: seconds => {
    if (!seconds) return '0s';
    const duration = intervalToDuration({ start: 0, end: seconds * 1000 });
    const { months, days, hours, minutes } = duration;

    const format = months
      ? ['months', 'days']
      : days
      ? ['days', 'hours']
      : hours > 0
      ? ['hours', 'minutes']
      : minutes > 0
      ? ['minutes', 'seconds']
      : ['seconds'];

    return formatDuration(duration, { format })
      .replace(' years', 'y')
      .replace(' year', 'y')
      .replace(' months', 'mo')
      .replace(' month', 'mo')
      .replace(' days', 'd')
      .replace(' day', 'd')
      .replace(' hours', 'h')
      .replace(' hour', 'h')
      .replace(' minutes', 'm')
      .replace(' minute', 'm')
      .replace(' seconds', 's')
      .replace(' second', 's');
  },

  convertUtcToZonedTime: (date, fallback = null) => {
    const root = getRootStore();

    // * Version where we use _pg_timezone_names_utc_offset - probably more stable
    if (isValid(new Date(date))) {
      return addHours(
        new Date(date),
        root.establishmentStore.establishment?._pg_timezone_names_utc_offset.hours,
      );
    }

    // * Version where we use utcToZonedTime func
    // if (isValid(new Date(date))) {
    //   return utcToZonedTime(new Date(date), root.establishmentStore.establishment.time_zone);
    // }
    return fallback;
  },

  convertUTCToZonedTime: (date, fallback = null) => {
    const root = getRootStore();

    if (isValid(new Date(date))) {
      return addHours(
        new Date(date.slice(0, -1)),
        root.establishmentStore.establishment?._pg_timezone_names_utc_offset.hours,
      );
    }

    return fallback;
  },

  convertZonedToUtcTime: (date, fallback = null) => {
    const root = getRootStore();

    if (isValid(new Date(date))) {
      return subHours(
        new Date(date),
        root.establishmentStore.establishment?._pg_timezone_names_utc_offset.hours,
      );
    }

    return fallback;
  },

  convertTimeToEstablishmentOffset: date => {
    const root = getRootStore();

    if (isValid(new Date(date))) {
      const utc_offset = new Date().getTimezoneOffset() / 60;
      const establishmentOffset =
        root.establishmentStore.establishment?._pg_timezone_names_utc_offset.hours;

      const d = new Date(date);
      return new Date(d.setHours(d.getHours() + utc_offset + establishmentOffset));
    }

    return null;
  },

  timeZoneShortNameIfDiff: (start = ' (', end = ')') => {
    const root = getRootStore();

    var formatter = new Intl.DateTimeFormat('en-US', {
      timeZone: root?.establishmentStore?.establishment.time_zone,
      timeZoneName: 'short',
    });
    const shortDate = formatter.format(new Date());
    const [, timeZoneShortName] = shortDate.split(', ');
    return start + timeZoneShortName + end;
  },

  makeDateFormatddMMMMyyyy: date => {
    const root = getRootStore();

    let convertedDate;
    if (isValid(new Date(date))) {
      convertedDate = subHours(
        new Date(date),
        root.establishmentStore.establishment?._pg_timezone_names_utc_offset.hours,
      );
    }
    return convertedDate ? format(new Date(convertedDate).getTime(), 'dd MMMM yyyy') : null;
  },

  formatDateIncludingToday: (rawDate, options) => {
    if (!rawDate) return rawDate;

    let date = new Date(rawDate);
    const {
      fallback = 'N/A',
      withTimezone = true,
      dateFormat = 'M/d',
      applyEstablishmentOffset = true,
    } = options || {};
    if (!date) return fallback;

    const root = getRootStore();

    var formatter = new Intl.DateTimeFormat('en-US', {
      timeZone: root?.establishmentStore?.establishment.time_zone,
      timeZoneName: 'short',
    });
    const shortDate = formatter.format(new Date());

    const [, timeZoneShortName] = shortDate.split(', ');
    const timeZoneShortNameIfDiff = withTimezone ? ` (${timeZoneShortName})` : '';

    if (applyEstablishmentOffset) {
      date = addHours(
        new Date(date),
        root.establishmentStore.establishment?._pg_timezone_names_utc_offset.hours,
      );
    }

    if (isToday(new Date(date))) {
      return `Today at ${format(new Date(date), 'h:mm a')} ${timeZoneShortNameIfDiff}`;
    } else if (isYesterday(new Date(date))) {
      return `Yesterday at ${format(new Date(date), 'h:mm a')} ${timeZoneShortNameIfDiff}`;
    } else {
      return `${format(new Date(date), dateFormat)} at ${format(
        new Date(date),
        'h:mm a',
      )} ${timeZoneShortNameIfDiff}`;
    }
  },
  makeDisplayedTime: value => {
    if (!value) return 'N/A';
    return value.days
      ? `${value.days}d ${value.hours ? value.hours + 'h' : 0 + 'h'}`
      : value.hours
      ? `${value.hours}h ${value.minutes ? value.minutes + 'm' : 0 + 'm'}`
      : value.minutes
      ? `${value.minutes}m ${value.seconds ? value.seconds + 's' : 0 + 's'}`
      : value.seconds
      ? `${value.seconds}s`
      : value.milliseconds
      ? `${Math.round(value.milliseconds)}ms`
      : 'N/A';
  },

  parseTimeStringToSeconds: timeStr => {
    if (!timeStr) return timeStr;

    const str = timeStr.replace('days', 'day');

    const regex = new RegExp('(([0-9]*) day )?([0-9]{1,3}):([0-9]{2}):([0-9.]*)', 'g');
    let result = null;
    const matched = regex.exec(str);

    if (Array.isArray(matched)) {
      const [days, hours, minutes, seconds] = matched.slice(2);

      result =
        24 * 3600 * (days || 0) + 3600 * Number(hours) + 60 * Number(minutes) + Number(seconds);
    }

    return result;
  },
};

export default dateUtilities;
