import { types } from 'mobx-state-tree';

export const Alarm = types.model({
  id: types.integer,
  active: types.boolean,
  alert_caption: types.string,
  role_users_ids: types.maybeNull(types.array(types.number)),
  rule: types.string,
  default_rule: types.maybeNull(types.string),
  sensor_id: types.number,
  type: types.string,
  original_sensor_name: types.maybeNull(types.string),
  _gateways_id: types.number,
});
