import React from 'react';
import { observer } from 'mobx-react';

import Content from './Content';
import Appearance from './Appearance';
import Organization from './Organization';

const SettingsPanel = props => (
  <div>
    <Organization {...props} />
    <Content {...props} />
    <Appearance {...props} />
  </div>
);

export default observer(SettingsPanel);
