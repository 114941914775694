import palette from 'theme/palette';

const MuiDrawer = {
  styleOverrides: {
    paper: {
      color: palette.white,
    },
    paperAnchorDockedLeft: {
      borderRight: 'none',
    },
  },
};

export default MuiDrawer;
