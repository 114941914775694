import { useEffect, useRef } from 'react';

import lottie from 'lottie-web/build/player/lottie_light.min.js';
const useKegAnimation = ({ animationData, percentage = 0, hasAnimation = true }) => {
  const containerRef = useRef(null);
  const animationRef = useRef(null);
  const currentFrameRef = useRef(null);

  useEffect(() => {
    const destinationFrame = Math.max(0, percentage);

    animationRef.current = lottie.loadAnimation({
      container: containerRef.current,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData,
    });

    animationRef.current.setSpeed(hasAnimation ? 0.6 : 1000);
    animationRef.current.goToAndStop(destinationFrame, true);
    currentFrameRef.current = destinationFrame;

    return () => {
      if (animationRef.current) {
        animationRef.current.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [percentage]);

  useEffect(() => {
    if (
      animationRef.current &&
      currentFrameRef.current !== null &&
      currentFrameRef.current !== percentage
    ) {
      if (currentFrameRef.current < percentage) {
        const destinationFrame = Math.min(101, percentage);
        animationRef.current.setDirection(1);
        animationRef.current.playSegments([currentFrameRef.current, destinationFrame], true);
        currentFrameRef.current = destinationFrame;
      } else {
        const destinationFrame = Math.max(0, percentage);
        animationRef.current.setDirection(-1);
        animationRef.current.playSegments([currentFrameRef.current, destinationFrame], true);
        currentFrameRef.current = destinationFrame;
      }
    }
  }, [percentage]);

  return [containerRef];
};

export default useKegAnimation;
