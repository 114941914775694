import palette from 'theme/palette';

const MuiAutocomplete = {
  styleOverrides: {
    tag: {
      color: palette.white,
      background: 'rgba(218, 218, 218, 0.12)',
    },
    inputRoot: {
      height: 'auto',
    },
    popupIndicator: {
      color: 'inherit',
    },
    clearIndicatorDirty: {
      color: 'inherit',
      fontSize: '1.3rem',
    },
    endAdornment: {
      right: '7px!important',
    },
    input: {
      padding: '9.5px 4px 9.5px 6px',
    },
  },
};

export default MuiAutocomplete;
