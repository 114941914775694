import React from 'react';
import { Navigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStore } from 'hooks';

const PrivateRoute = ({ location, children }) => {
  const { userStore } = useStore();

  if (!userStore.isAuthenticated) {
    return (
      <Navigate
        to={{
          pathname: '/auth/login',
          state: userStore.isManuallyLoggedOut ? undefined : { referrer: location },
        }}
      />
    );
  }

  return children;
};

export default observer(PrivateRoute);
