import { createRoot } from 'react-dom/client';

import { registerServiceWorker } from 'serviceWorkerRegistration';

import App from 'components/App';
import MaintenancePage from 'components/@Maintenance';

import './serviceWorkerAutoRecovery';
import './vendors';

const container = document.getElementById('root');
const root = createRoot(container);

if (process.env.REACT_APP_MAINTENANCE_MODE === 'true') {
  root.render(<MaintenancePage />);
} else {
  root.render(<App />);
}

registerServiceWorker();
