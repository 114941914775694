import { types } from 'mobx-state-tree';
import { Tap } from './types';

export const tapsInitialState = {
  all: [],
  isLoaded: false,
};

export const tapsModel = types
  .model({
    all: types.array(Tap),
    isLoaded: types.boolean,
  })
  .views(self => ({
    get taps() {
      return self.all;
    },
  }))
  .actions(self => {
    return {
      setTaps(taps) {
        self.all.replace(taps);
        self.isLoaded = true;
      },
      getTapIdByIdentifier(identifier) {
        return self.all.find(t => t.identifier === `Tap-${identifier}`)?.id;
      },
    };
  });
