import { types, flow } from 'mobx-state-tree';

import api from 'services/API';

import { StatisticTest } from './types';

const TEMPERATURE_PROPERTY = 'sensor_temp_c.mean_x.pour_x';

export const StatisticTestWithViews = StatisticTest.views(self => ({
  get rules() {
    return self.rule.get('and').map(el => {
      const [operand] = Object.keys(el);
      const [pair] = Object.values(el);
      const [key, value] = pair;

      return { key: key.var, value, operand };
    });
  },
}));

export const statisticTestsInitialState = {
  all: [],
  isLoaded: false,
};

export const statisticTestsModel = types
  .model({
    all: types.array(StatisticTestWithViews),
    isLoaded: types.boolean,
  })
  .views(self => ({
    get statisticTests() {
      return self.all;
    },
    get highTempAlert() {
      return self
        .getAlert('high temperature')
        ?.rules?.find(({ key }) => key === TEMPERATURE_PROPERTY)?.value;
    },
    get lowTempAlert() {
      return self
        .getAlert('low temperature')
        ?.rules?.find(({ key }) => key === TEMPERATURE_PROPERTY)?.value;
    },
  }))
  .actions(self => {
    return {
      setStatisticTests(statisticTests) {
        self.all.replace(statisticTests);
        self.isLoaded = true;
      },
      getAlert(caption) {
        return self.all.find(({ alert_caption }) => alert_caption === caption);
      },
      patchTemperatureAlert: flow(function* ({ caption, temperature }) {
        try {
          const alert = self.getAlert(caption);

          const body = {
            rule: {
              and: alert.rules.map(({ operand, key, value }) => ({
                [operand]: [{ var: key }, key === TEMPERATURE_PROPERTY ? temperature : value],
              })),
            },
          };

          const response = yield api.patchTemperatureAlert(alert.id, body);

          const updatedAlert = response.data.row;

          self.all.replace([...self.all.filter(e => e.id !== updatedAlert.id), updatedAlert]);

          return response;
        } catch (error) {
          console.log(error);
          return Promise.reject(error);
        }
      }),
    };
  });
