import React, { useCallback } from 'react';
import palette from 'theme/palette';
import { useMediaQueries } from 'hooks';
import { MobileView } from './MobileView/MobileView';
import { DesktopView } from './DesktopView/DesktopView';
import { isValid } from 'date-fns';

export const DynamicInput = ({
  title,
  name,
  description,
  handleChange,
  handleBlur,
  disabled,
  valueState,
  setFocusedInput,
  type,
  values,
  stacked,
  textColor = palette.whiteEmphasis.high,
  nested = false,
  handleTimeChangeStart,
  handleTimeChangeEnd,
}) => {
  const { isDesktopOrGreater } = useMediaQueries();

  const getCurrentValue = useCallback(() => {
    if (valueState[name] === undefined) {
      return '';
    }
    let currentValue = valueState[name];

    if (values) {
      const item = values.find(e => e.value === valueState[name]);

      currentValue = item?.label;
    }

    if (type === 'time') {
      if (!valueState[name]) return valueState[name];
      if (!isValid(valueState[name])) {
        const timeString = valueState[name];
        const [time, meridiem] = timeString.split(' ');
        const [hours, minutes] = time.split(':');

        let date = new Date();

        if (meridiem === 'PM' && hours !== '12') {
          date.setHours(parseInt(hours) + 12);
        } else if (meridiem === 'AM' && hours === '12') {
          date.setHours(0);
        } else {
          date.setHours(parseInt(hours));
        }

        date.setMinutes(parseInt(minutes));

        return new Intl.DateTimeFormat('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        }).format(date);
      }

      currentValue = new Intl.DateTimeFormat('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      }).format(valueState[name]);
    }

    return currentValue;
  }, [valueState, name, values, type]);

  const onBlur = useCallback(
    event => {
      if (type === 'select') {
        return;
      }
      setFocusedInput && setFocusedInput({});
      handleBlur(event);
    },
    [type, setFocusedInput, handleBlur],
  );

  return isDesktopOrGreater ? (
    <DesktopView
      type={type}
      disabled={disabled}
      title={title}
      name={name}
      values={values}
      currentValue={getCurrentValue()}
      description={description}
      handleChange={handleChange}
      valueState={valueState}
      stacked={stacked}
      textColor={textColor}
      onBlur={onBlur}
      setFocusedInput={setFocusedInput}
    />
  ) : (
    <MobileView
      type={type}
      disabled={disabled}
      title={title}
      name={name}
      values={values}
      currentValue={getCurrentValue()}
      description={description}
      handleChange={handleChange}
      valueState={valueState}
      stacked={stacked}
      textColor={textColor}
      handleTimeChangeStart={handleTimeChangeStart}
      handleTimeChangeEnd={handleTimeChangeEnd}
      nested={nested}
    />
  );
};
