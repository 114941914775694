import React from 'react';
import { Grid, Typography, Box, styled } from '@mui/material';
import { observer } from 'mobx-react';

import Tile from '../Tile';

const Tiles = ({ state }) => {
  const template = state.template;
  let height =
    ((80 - state.tilesPerPage * 2) / state.tilesPerPage) * (state.isHorizontal ? 1.8 : 1);

  if (height > 14) height = 14; // for minimum of 6 tiles in vertical layout

  let fontSize = 0;

  if (height < 6) {
    fontSize = 1;
  } else if (height <= 10) {
    fontSize = 1.5;
  } else if (height > 10) {
    fontSize = 2;
  }

  if (!template) return;

  return (
    <StyledRootGrid container state={state} direction={state.isVertical ? 'column' : 'row'}>
      {state.isVertical &&
        state.tiles &&
        state.tiles.map((tile, index) =>
          typeof tile === 'string' ? (
            <Box key={index}>
              <Typography
                className="category"
                align="center"
                variant="h3"
                style={{ height: `${height}vh` }}
                sx={{ color: state.categoryColor }}
              >
                {tile}
              </Typography>
            </Box>
          ) : (
            <Tile
              template={template}
              data={tile}
              key={index}
              state={state}
              index={index}
              fontSize={fontSize}
              height={height}
            />
          ),
        )}

      {state.isHorizontal &&
        state.tilesByColumn.map((tiles, index, arr) => {
          return (
            <Grid xs={12 / arr.length} key={index} item>
              {tiles.map((tile, index) =>
                typeof tile === 'string' ? (
                  <Box key={tile}>
                    <Typography
                      className="category"
                      align="center"
                      variant="h3"
                      style={{ height: `${height}vh` }}
                      sx={{ color: state.categoryColor }}
                    >
                      {tile}
                    </Typography>
                  </Box>
                ) : (
                  <Tile
                    key={index}
                    template={template}
                    data={tile}
                    state={state}
                    index={index}
                    fontSize={fontSize}
                    height={height}
                  />
                ),
              )}
            </Grid>
          );
        })}
    </StyledRootGrid>
  );
};

export default observer(Tiles);

const StyledRootGrid = styled(Grid, {
  shouldForwardProp: props => props !== 'state',
})(({ state }) => ({
  width: '100%',
  height: '100%',
  '.vertical.flipped &': {
    alignItems: 'flex-end',
  },

  '.category': {
    fontSize: '2vw',
    lineHeight: '3vw',
    fontWeight: 700,
    width: '100%',
    textDecoration: 'underline',
  },
}));
