import { useState } from 'react';

const useDrawerContextValues = () => {
  const [drawerRef, setDrawerRef] = useState({ current: null });
  const [options, setOptions] = useState({
    anchor: 'right',
  });
  const [open, setOpen] = useState(false);

  return {
    ref: drawerRef,
    setRef: setDrawerRef,
    options: options,
    setOptions: setOptions,
    open: open,
    setOpen: setOpen,
  };
};

export default useDrawerContextValues;
