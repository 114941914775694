import { types, flow } from 'mobx-state-tree';
import api from 'services/API';
import { Integration } from './types';

export const integrationsInitialState = {
  all: [],
};

export const integrationsStore = types
  .model({
    all: types.array(Integration),
  })
  .views(self => ({
    getPosById(id) {
      return self.all.find(item => item.id === id);
    },
  }))
  .actions(self => ({
    //actions never used
    fetch: flow(function* () {
      try {
        const response = yield api.getIntegrations();
        if (response && response.data && Array.isArray(response.data.result)) {
          self.all.replace(response.data.result);
        }
        return response;
      } catch (err) {
        self.all = [];
        console.error(err);
        return Promise.reject(err);
      }
    }),

    setIntegrations(integrations) {
      self.all.replace(integrations);
    },
  }));
