import palette from 'theme/palette';
import EmptyKeg from 'assets/icons/emptyKegDetails.svg';
import OrangeFilling from 'assets/icons/orangeFilling.svg';

const MuiSlider = {
  styleOverrides: {
    root: {
      '&.keg': {
        height: 106,
        width: 90,
        padding: 0,
        color: 'transparent',
        pointerEvents: 'none',
        '& .MuiSlider-markActive': {
          display: 'none',
        },
        '& .MuiSlider-rail': {
          height: 147,
          width: 102,
          background: `url(${EmptyKeg}) no-repeat`,
          bottom: -8,
          zIndex: 1,
          opacity: 1,
        },
        '& .MuiSlider-track': {
          display: 'none',
          width: '100%',
          background: `url(${OrangeFilling}) no-repeat`,
          backgroundPosition: 'center bottom',
          backgroundSize: 'cover',
          borderRadius: '0px',
          transition: 'none',
        },
        '& .MuiSlider-thumb': {
          display: 'none',
          backgroundColor: palette.primary.main,
          width: 147,
          height: 1,
          marginBottom: 0,
          transition: 'none',
          zIndex: 3,
          '& span': {
            color: palette.secondary.light,
          },
        },
        '& .MuiSlider-valueLabel': {
          left: '50%',
          transform: 'scale(1) translate(-50%, -10px)',
          '&::before, &::after': {
            content: "''",
            width: 15,
            height: 15,
            borderRadius: '50%',
            backgroundColor: palette.primary.main,
            position: 'absolute',
            zIndex: 3,
          },
          '&::before': {
            left: -46,
            top: 10,
          },
          '&::after': {
            left: 86,
            top: 3,
          },
          '& span span': {
            color: palette.white,
            paddingTop: 4,
          },
        },
        '&.filled': {
          pointerEvents: 'visible',
          '& .MuiSlider-track': {
            display: 'block',
          },
          '& .MuiSlider-thumb': {
            display: 'block',
          },
        },
      },
    },
  },
};

export default MuiSlider;
