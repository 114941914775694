const containerTypes = {
  1: 'Keg',
  2: 'Bottle',
  3: 'Can',
  4: 'Draft',
  Keg: 1,
  Bottle: 2,
  Can: 3,
  Draft: 4,
};

export default containerTypes;
