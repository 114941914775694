import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Portal, Box, Typography, styled } from '@mui/material';

import { NO_INTERNET_HEIGHT, NO_INTERNET_HEIGHT_MOBILE } from 'config/ui';
import { useNetworkDetector, useReferenceContext, useStore } from 'hooks';
const baseTimer = 30;

const NetworkDetector = () => {
  const { ui } = useStore();
  const { noInternetBar } = useReferenceContext();
  const isDisconnected = useNetworkDetector();

  const reconnectCounter = useRef(baseTimer);
  const refreshCounter = useRef(baseTimer);
  const reconectCountdownInterval = useRef(null);
  const refreshCountdownInterval = useRef(null);
  let showRefresh = useRef(false);

  const noConnectionMessage = useCallback(
    counter => `Connectivity Issues Detected. Attempting to reconnect in ${counter}s...`,
    [],
  );
  const hasConnectionMessage = useCallback(
    counter => `Connectivity Restored. Attempting to reload data in  ${counter}s...`,
    [],
  );

  const [message, setMessage] = useState(() => noConnectionMessage(reconnectCounter.current));

  useEffect(() => {
    let previousCounter = baseTimer;

    if (isDisconnected) {
      ui.setNoInternetVisibility(true);
      clearInterval(refreshCountdownInterval.current);
      refreshCounter.current = baseTimer;
      showRefresh.current = false;
      reconectCountdownInterval.current = setInterval(() => {
        setMessage(noConnectionMessage(reconnectCounter.current));

        if (reconnectCounter.current > 0) {
          reconnectCounter.current--;
        } else {
          reconnectCounter.current = previousCounter * 2;
          previousCounter *= 2;
        }
      }, 1000);
    } else if (ui.isNoInternet) {
      refreshCountdownInterval.current = setInterval(() => {
        setMessage(hasConnectionMessage(refreshCounter.current));
        showRefresh.current = true;

        if (refreshCounter.current > 0) {
          refreshCounter.current--;
        } else {
          clearInterval(refreshCountdownInterval.current);
          window.location.reload();
        }
      }, 1000);
      clearInterval(reconectCountdownInterval.current);
      reconnectCounter.current = baseTimer;
    }
  }, [isDisconnected, reconnectCounter, noConnectionMessage, hasConnectionMessage, ui]);

  if (ui.isNoInternet) {
    return (
      <Portal container={noInternetBar.ref.current}>
        <BoxHeadingComponent
          sx={{
            background: ({ palette }) =>
              showRefresh.current ? palette.success.main : palette.primary.main,
          }}
        >
          <Box component="span">{message}</Box>
          {showRefresh.current && (
            <Typography className="link" component="span" onClick={() => window.location.reload()}>
              Reload Now
            </Typography>
          )}
        </BoxHeadingComponent>
      </Portal>
    );
  }

  return null;
};

export default NetworkDetector;

export const BoxHeadingComponent = styled(Box)(({ theme: { palette } }) => {
  return {
    fontSize: 17,
    lineHeight: 1.3,
    color: palette.secondary.dark,
    width: '100%',
    cursor: 'pointer',
    fontWeight: 700,
    padding: '4px 16px',
    textAlign: 'center',
    height: NO_INTERNET_HEIGHT,
    '@media (max-width: 640px)': {
      fontSize: 16,
      height: NO_INTERNET_HEIGHT_MOBILE,
    },
    '@media(max-width: 350px)': {
      fontSize: 15,
      padding: '6px 16px',
    },
    '.link': {
      marginLeft: '8px',
      textDecoration: 'underline',
      color: '#0000ff',
      fontWeight: 700,
      fontSize: 17,
      lineHeight: 1.3,
    },
  };
});
