import { alpha } from '@mui/material/styles';
import palette from 'theme/palette';

const MuiFormControlLabel = {
  styleOverrides: {
    root: {
      margin: 0,
    },
    label: {
      marginLeft: '4px',
      color: palette.whiteEmphasis.high,
      fontSize: '12px',
      lineHeight: '18px',
      '&.Mui-disabled': {
        color: alpha(palette.white, 0.6),
      },
    },
  },
};

export default MuiFormControlLabel;
