import { useCallback, useState } from 'react';
import { isAlive } from 'mobx-state-tree';

import { useStore, useToast } from 'hooks';
import api from 'services/API';

const useOnTapData = () => {
  const {
    linesStore,
    itemsStore,
    itemLinesStore,
    beveragesStore,
    tapsStore,
    lineTapsStore,
    containersStore,
    skuStore,
  } = useStore();
  const { errorToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const loadData = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await api.getBundle({ number: 1 });

      const { beverages, containers, items_statistics, lines, taps, line_taps, item_lines, skus } =
        response.data.result;

      if (isAlive(containersStore)) {
        containers &&
          containers.rows &&
          Array.isArray(containers.rows) &&
          containersStore.setContainers(containers.rows);
      }

      if (isAlive(beveragesStore)) {
        beverages &&
          beverages.rows &&
          Array.isArray(beverages.rows) &&
          beveragesStore.setBeverages(beverages.rows);
      }

      if (isAlive(itemsStore)) {
        items_statistics &&
          items_statistics.rows &&
          Array.isArray(items_statistics.rows) &&
          itemsStore.setItems(items_statistics.rows);
      }

      if (isAlive(linesStore)) {
        lines && lines.rows && Array.isArray(lines.rows) && linesStore.setLines(lines.rows);
      }

      if (isAlive(tapsStore)) {
        taps && taps.rows && Array.isArray(taps.rows) && tapsStore.setTaps(taps.rows);
      }

      if (isAlive(lineTapsStore)) {
        line_taps &&
          line_taps.rows &&
          Array.isArray(line_taps.rows) &&
          lineTapsStore.setLineTaps(line_taps.rows);
      }

      if (isAlive(itemLinesStore)) {
        item_lines &&
          item_lines.rows &&
          Array.isArray(item_lines.rows) &&
          itemLinesStore.setItemLines(item_lines.rows);
      }

      if (isAlive(skuStore)) {
        skus && skus.rows && Array.isArray(skus.rows) && skuStore.setSKUs(skus.rows);
      }
    } catch (err) {
      errorToast(err);
    } finally {
      setIsLoading(false);
    }
  }, [
    errorToast,
    beveragesStore,
    containersStore,
    itemLinesStore,
    itemsStore,
    lineTapsStore,
    linesStore,
    skuStore,
    tapsStore,
  ]);

  const isLoaded =
    linesStore.isLoaded &&
    itemsStore.isLoaded &&
    itemLinesStore.isLoaded &&
    beveragesStore.isLoaded &&
    tapsStore.isLoaded &&
    lineTapsStore.isLoaded &&
    containersStore.isLoaded &&
    skuStore.isLoaded;

  return { isLoading, isLoaded, loadData };
};

export default useOnTapData;
