import { createTheme } from '@mui/material/styles';

import * as components from 'theme/components';

import palette from 'theme/palette';
import typography from 'theme/typography';
import mixins from 'theme/mixins';
import breakpoints from 'theme/breakpoints';
import props from 'theme/props';

const themeV5 = createTheme({
  breakpoints,
  mixins,
  components,
  palette,
  typography,
  props,
  zIndex: {
    appBar: 1200,
    drawer: 1201,
  },
});

export default themeV5;
