import { alpha } from '@mui/material/styles';
import palette from 'theme/palette';

const MuiButton = {
  styleOverrides: {
    root: {
      height: 36,
      borderRadius: 3,
      minWidth: 105,
      '&.btn--circle': {
        minWidth: 36,
        borderRadius: '50%',
        padding: 0,
      },
      '&.capitalize': {
        textTransform: 'capitalize',
      },
    },
    sizeLarge: {
      height: 46,
    },
    label: {
      whiteSpace: 'nowrap',
    },

    text: {
      color: palette.white,
      '&:hover': {
        backgroundColor: alpha(palette.white, 0.15),
      },
      '&:hover:focus': {
        backgroundColor: alpha(palette.white, 0.3),
      },
      '&.Mui-disabled': {
        color: palette.white,
        opacity: 0.3,
      },
      '&.active': {
        color: palette.primary.main,
      },
    },
    outlined: {
      backgroundColor: palette.secondary.dark,
      borderColor: palette.secondary.light,
      color: palette.white,
      '&:hover': {
        backgroundColor: alpha(palette.white, 0.15),
      },
      '&:hover:focus': {
        backgroundColor: alpha(palette.white, 0.3),
      },
      '&.Mui-disabled': {
        borderColor: palette.white,
        color: palette.white,
        opacity: 0.5,
      },
      '&.add': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        minHeight: 36,
        backgroundColor: palette.primary.main,
        borderRadius: 3,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: palette.primary.main,
        '&.dashed-border': {
          backgroundColor: palette.secondary.greyLight,
        },
        '&.add--md': {
          height: 46,
        },
        '&.add--lg': {
          height: 100,
          '@media(max-width: 1023px)': {
            height: 79,
          },
        },
      },
      '&.edit': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        minHeight: 36,
        borderRadius: 7,
        borderWidth: 1,
        borderColor: palette.primary.main,
        backgroundColor: palette.secondary.greyLight,
        borderStyle: 'solid',
      },

      '&.delete': {
        border: `1px solid ${palette.primary.main}`,
      },
    },
    containedPrimary: {
      transition: '0.5s',
      backgroundSize: '200% auto',
      background: palette.primary.main,
      '&:hover': {
        backgroundPosition: 'right center',
      },
      '&.Mui-disabled': {
        background: 'rgba(255, 255, 255, 0.3)',
        color: palette.black,
      },
    },
  },
};

export default MuiButton;
