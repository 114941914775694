import { useContext } from 'react';
import { MobxContext } from 'context/store';

const useStore = () => {
  const store = useContext(MobxContext);
  if (store === null) {
    throw new Error('No store provider found.');
  }
  return store;
};

export default useStore;
