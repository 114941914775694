import palette from 'theme/palette';

const MuiPickersDay = {
  styleOverrides: {
    root: {
      '&.Mui-disabled': {
        '&:not(.Mui-selected)': {
          color: palette.whiteEmphasis.low,
        },
      },
    },
  },
};

export default MuiPickersDay;
