import { types, flow } from 'mobx-state-tree';
import sortBy from 'lodash/sortBy';

import api from 'services/API';
import { CoolerStatistic } from 'models/types';

import { numberUtilities, stringUtilities } from 'utils';

export const coolersStatisticsInitialState = {
  isLoaded: false,
  all: [],
  state: 'done',
  updated: null,
  cooler: 0,
};

export const coolersStatisticsModel = types
  .model({
    isLoaded: types.boolean,
    all: types.array(CoolerStatistic),
    state: types.enumeration('state', ['done', 'pending', 'error']),
    updated: types.maybeNull(types.Date),
    cooler: types.integer,
  })
  .views(self => ({
    get list() {
      return sortBy(self.all, 'name');
    },
    get currentCooler() {
      // temporary solution. Only one cooler available right now
      return self.all[0];
    },

    getCoolerHumidityById: id => {
      const cooler = self.all.find(c => c.id === id);
      return {
        current: cooler?._heartbeats_current_statistics?.bru_hum.mean,
        low: cooler?._heartbeats_current_statistics?.bru_hum.min,
        max: cooler?._heartbeats_current_statistics?.bru_hum.max,
      };
    },

    getCoolerTempById: id => {
      const cooler = self.all.find(c => c.id === id);
      return {
        current: numberUtilities.celsiusToFahrenheit(
          cooler?._heartbeats_current_statistics?.bru_temp_c.mean,
        ),
        low: numberUtilities.celsiusToFahrenheit(
          cooler?._heartbeats_current_statistics?.bru_temp_c.min,
        ),
        max: numberUtilities.celsiusToFahrenheit(
          cooler?._heartbeats_current_statistics?.bru_temp_c.max,
        ),
      };
    },
    getCoolerHumidity: () => ({
      current: stringUtilities.fixedSuffix(
        self.list[0]?._heartbeats_current_statistics?.bru_hum?.mean,
        '%',
      ),
      low: stringUtilities.fixedSuffix(
        self.list[0]?._heartbeats_current_statistics?.bru_hum?.min,
        '%',
      ),
      max: stringUtilities.fixedSuffix(
        self.list[0]?._heartbeats_current_statistics?.bru_hum?.max,
        '%',
      ),
    }),
    getCoolerTemp: () => ({
      current: stringUtilities.fixedSuffix(
        numberUtilities.celsiusToFahrenheit(
          self.list[0]?._heartbeats_current_statistics?.bru_temp_c?.mean,
        ),
        '°F',
      ),
      low: stringUtilities.fixedSuffix(
        numberUtilities.celsiusToFahrenheit(
          self.list[0]?._heartbeats_current_statistics?.bru_temp_c?.min,
        ),
        '°F',
      ),
      max: stringUtilities.fixedSuffix(
        numberUtilities.celsiusToFahrenheit(
          self.list[0]?._heartbeats_current_statistics?.bru_temp_c?.max,
        ),
        '°F',
      ),
    }),
  }))
  .actions(self => ({
    fetch: flow(function* () {
      try {
        self.state = 'pending';
        const response = yield api.getCoolersStatistics();

        if (response?.data?.result) {
          self.all.replace(response.data.result);
          self.cooler = response.data.result[0]?.id;
          self.state = 'done';
          self.isLoaded = true;
          self.updated = new Date();
          return response.data.result;
        } else {
          self.isLoaded = false;
          self.state = 'done';
          self.updated = new Date();
        }
      } catch (error) {
        self.isLoaded = false;
        self.state = 'error';
        self.updated = new Date();
        console.error(error);
        return Promise.reject(error);
      }
    }),
    setCooler: id => {
      if (Number.isInteger(id)) {
        self.cooler = id;
      }
    },
  }));
