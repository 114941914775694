import { types, flow } from 'mobx-state-tree';

import api from 'services/API';
import { getRootStore } from 'models/root';
import { format, subDays } from 'date-fns';
import { orderBy } from 'lodash';

import { ItemEvent } from 'models/types';

export const itemEventsInitialState = {
  period: {
    from: format(subDays(new Date(), 7), 'yyyy-MM-dd HH:mm:ss'),
    to: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
  },
  all: [],
  state: 'done',
  updated: null,
};

export const ItemEventWithViews = ItemEvent.views(self => ({
  get item() {
    const root = getRootStore();
    return root.itemsStore.all.find(item => item.id === self.item_id);
  },

  get user() {
    const root = getRootStore();
    return root.usersStore.all.find(user => user.email === self._users_username);
  },
}));

export const itemEventsModel = types
  .model({
    period: types.model({
      from: types.string,
      to: types.string,
    }),
    all: types.array(ItemEventWithViews),
    state: types.enumeration('state', ['done', 'pending', 'error']),
    updated: types.maybeNull(types.Date),
  })
  .views(self => ({
    get itemEvents() {
      return orderBy(self.all, ['changed_at'], ['desc']);
    },
  }))
  .actions(self => {
    return {
      fetchItemEvents: flow(function* () {
        try {
          self.state = 'pending';

          const response = yield api.getItemEvents({
            'from[changed_at]': new Date(self.period.from).toISOString(),
            'to[changed_at]': new Date(self.period.to).toISOString(),
          });

          if (response?.data?.result) {
            self.all.replace(response.data.result);
            self.state = 'done';
            self.isLoaded = true;
            self.updated = new Date();
            return response.data.result;
          } else {
            self.isLoaded = false;
            self.state = 'done';
            self.updated = new Date();
          }
        } catch (error) {
          self.isLoaded = false;
          self.state = 'error';
          self.updated = new Date();
          console.error(error);
          return Promise.reject(error);
        }
      }),

      setPeriod(period) {
        self.period = period;
      },

      updateOrInsertItemEvent(_event) {
        if (_event && _event.id) {
          const eventIndex = self.all.findIndex(event => event.id === _event.id);
          const eventExist = eventIndex >= 0;
          if (eventExist) {
            self.all[eventIndex] = _event;
          } else {
            const updatedEvents = [...self.all, _event];
            self.all.replace(updatedEvents);
          }
        }
      },

      handleAddItem(result) {
        const [added] = result?.added || [];

        if (Array.isArray(added) && added.length > 0) {
          added.forEach(el => {
            if (el._item_event) {
              self.updateOrInsertItemEvent(el._item_event);
            }
          });
        }
      },

      handleNewItemLine(result) {
        if (result?.added?.[0]?._item_event) {
          self.updateOrInsertItemEvent(result?.added?.[0]?._item_event);
        }
      },

      handleItemLineRemove(result) {
        const removed = result?.removed || [];

        if (Array.isArray(removed) && removed.length > 0) {
          removed.forEach(removedEl => {
            if (removedEl?._item_event) {
              self.updateOrInsertItemEvent(removedEl?._item_event);
            }
          });
        }
      },

      handleRemoveItem(result) {
        if (result && result._item_event) {
          self.updateOrInsertItemEvent(result._item_event);
        }
      },

      handleNextItem({ disconnected = {}, connected = {} } = {}) {
        const { _item_events: disconnectedItemEvents } = disconnected;
        const { _item_events: connectedItemEvents } = connected;

        disconnectedItemEvents &&
          Array.isArray(disconnectedItemEvents) &&
          disconnectedItemEvents.forEach(self.updateOrInsertItemEvent);
        connectedItemEvents &&
          Array.isArray(connectedItemEvents) &&
          connectedItemEvents.forEach(self.updateOrInsertItemEvent);
      },

      handleSwapItems({ _item_events_created = [], _item_events_updated = [] } = {}) {
        _item_events_created &&
          Array.isArray(_item_events_created) &&
          _item_events_created.forEach(self.updateOrInsertItemEvent);
        _item_events_updated &&
          Array.isArray(_item_events_updated) &&
          _item_events_updated.forEach(self.updateOrInsertItemEvent);
      },

      setItemEvents(itemEvents) {
        self.all.replace(itemEvents);
      },
    };
  });
