import { alpha } from '@mui/material/styles';
import palette from 'theme/palette';

const MuiMenu = {
  styleOverrides: {
    paper: {
      marginRight: -16,
      background: '#383838 !important',
      boxShadow: `0px 2px 1px ${alpha(palette.black, 0.2)}, 0px 2px 1px ${alpha(
        palette.black,
        0.12,
      )}, 0px 2px 1px ${alpha(palette.black, 0.14)} !important`,
    },
  },
};

export default MuiMenu;
