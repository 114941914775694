import { alpha } from '@mui/material/styles';
import palette from 'theme/palette';

const MuiTableRow = {
  styleOverrides: {
    root: {
      backgroundColor: alpha(palette.secondary.lightest, 0.05),
      '&:nth-of-type(odd)': {
        backgroundColor: alpha(palette.secondary.lightest, 0.1),
      },
    },
  },
};

export default MuiTableRow;
