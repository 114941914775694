import React, { useContext, useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { ReferenceContext } from 'context/reference';

const NoInternetPlaceholder = () => {
  const ref = useRef();
  const { noInternetBar } = useContext(ReferenceContext);
  const { setRef } = noInternetBar;

  useEffect(() => {
    setRef(ref);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Box ref={ref} />;
};

export default NoInternetPlaceholder;
