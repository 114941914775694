import { types } from 'mobx-state-tree';
import { Identifier } from './line-identifier';

const TimeModel = types.model({
  days: types.maybeNull(types.integer),
  hours: types.maybeNull(types.integer),
  minutes: types.maybeNull(types.integer),
  seconds: types.maybeNull(types.integer),
  milliseconds: types.maybeNull(types.number),
});
const Sensor = types.model({
  stdevp: types.maybeNull(types.number),
  mean: types.maybeNull(types.number),
  max: types.maybeNull(types.number),
  min: types.maybeNull(types.number),
});

const Pours = types.model({
  n_pours: types.number,
  sensor_temp_c: types.maybeNull(Sensor),
});

export const CleaningsStatistic = types.model({
  id: types.maybeNull(types.integer),
  line_id: types.maybeNull(types.integer),
  type_code: types.maybeNull(types.integer),
  cleaned_from: types.maybeNull(types.string),
  cleaned_to: types.maybeNull(types.string),
  cleaned: types.maybeNull(types.string),
  created_at: types.maybeNull(types.string),
  created_by: types.maybeNull(types.integer),
  updated_at: types.maybeNull(types.string),
  updated_by: types.maybeNull(types.integer),
  archived: types.maybeNull(types.boolean),
  _duration: types.maybeNull(TimeModel),
  _interval_before: types.maybeNull(TimeModel),
  _interval_after: types.maybeNull(TimeModel),

  _lines_identifiers: Identifier,

  _lines_line_identifier: types.maybeNull(types.string),
  _users_display_name_created_by: types.maybeNull(types.string),
  _users_display_name_updated_by: types.maybeNull(types.string),

  _pours_statistics: types.maybeNull(Pours),
});
