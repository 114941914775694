const MuiPickersPopper = {
  styleOverrides: {
    root: {
      // inset: '0px auto auto 0px !important',
      // '@media(min-width: 1024px)': {
      //   transform: 'translate(40vw, 60px)!important',
      // },
      // '@media(max-width: 1023px)': {
      //   transform: 'translate(30vw, 60px)!important',
      // },
      // '@media(max-width: 599px)': {
      //   transform: 'translate(20vw, 60px)!important',
      // },
    },
  },
};

export default MuiPickersPopper;
