import { types } from 'mobx-state-tree';

const TimeModel = types.model({
  days: types.maybeNull(types.integer),
  hours: types.maybeNull(types.integer),
  minutes: types.maybeNull(types.integer),
  seconds: types.maybeNull(types.integer),
  milliseconds: types.maybeNull(types.number),
});

export const CleaningStatistic = types.model({
  establishment_id: types.maybeNull(types.integer),
  line_id: types.maybeNull(types.integer),
  _avg_duration: types.maybeNull(TimeModel),
  _stddevp_duration: types.maybeNull(TimeModel),
  _count_duration: types.maybeNull(types.union(types.string, types.number)),
  _avg_interval: types.maybeNull(TimeModel),
  _stddevp_interval: types.maybeNull(TimeModel),
  _count_interval: types.maybeNull(types.union(types.string, types.number)),
});
