import React, { forwardRef } from 'react';

import { PatternFormat } from 'react-number-format';

const InputPattern = forwardRef(({ onChange, format, ...restProps }, ref) => {
  return (
    <PatternFormat
      getInputRef={ref}
      format={format || '+#-###-###-##-##'}
      allowEmptyFormatting
      mask="_"
      onValueChange={values => {
        onChange(values.formattedValue);
      }}
      {...restProps}
    />
  );
});

export default InputPattern;
