import palette from 'theme/palette';
import { TextField } from '@mui/material';
import React from 'react';

const DynamicTextInput = ({
  title,
  name,
  valueState,
  handleChange,
  onBlur,
  setFocusedInput,
  description,
}) => {
  return (
    <TextField
      label={`${title} %`}
      name={name}
      type={'number'}
      InputProps={{ inputProps: { min: 0, max: 100 } }}
      value={valueState[name]}
      onChange={handleChange}
      onBlur={onBlur}
      onFocus={setFocusedInput && (() => setFocusedInput({ title, description }))}
      onMouseEnter={setFocusedInput && (() => setFocusedInput({ title, description }))}
      onMouseLeave={setFocusedInput && (() => setFocusedInput({}))}
      sx={{
        '.MuiInputBase-root': {
          backgroundColor: palette.secondary.dark,
        },
        '&.MuiTextField-root': {
          marginBottom: 0,
        },
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: palette.outline.main,
        },
      }}
    />
  );
};

export { DynamicTextInput };
