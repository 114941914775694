import palette from 'theme/palette';

const MuiDialogContent = {
  styleOverrides: {
    root: {
      padding: '16px 16px',
      '&:first-of-type': {
        paddingTop: '20px',
      },
      '&.pt-4': {
        paddingTop: 12,
      },

      '& * .MuiOutlinedInput-root': {
        background: palette.secondary.dark,

        '& > .MuiOutlinedInput-notchedOutline': {
          borderColor: `${palette.secondary.main} !important`,
        },
      },
    },
  },
};

export default MuiDialogContent;
