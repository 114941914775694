import React from 'react';
import { observer } from 'mobx-react';
import { Typography, CircularProgress, Box } from '@mui/material';

import { HEADER_HEIGHT } from 'config/ui';

import { alpha } from '@mui/material/styles';

const Loader = ({
  progressMessage = '',
  className,
  withShadow = false,
  fullScreen = false,
  isStatic = false,
  centered = false,
  size = 40,
  indent = 1,
  fullWidth = false,
  zIndex = 3,
  ...restProps
}) => (
  <Box
    height={1}
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    className={className}
    sx={{
      position: fullScreen ? 'fixed' : isStatic ? 'static' : 'absolute',
      ...((withShadow || fullScreen) && {
        position: 'absolute',
        display: 'flex',
        background: theme => alpha(theme.palette.secondary.dark, 0.5),
        left: 0,
        top: 0,
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        zIndex: zIndex,
        transition: '.3s',
      }),
      ...(centered && {
        position: 'absolute',
        left: `calc(50% - ${(indent * size) / 2}px)`,
        top: `calc(50% - ${HEADER_HEIGHT}px)`,
        transform: 'translate(-50%, -50%)',
        '& .progress': {
          width: 20,
          height: 20,
          marginBottom: 0,
        },
      }),
      ...(fullWidth && { width: '100%' }),
    }}
    {...restProps}
  >
    <CircularProgress size={size} />
    {progressMessage && (
      <Typography
        sx={{
          paddingTop: '30px',
          fontSize: 18,
          fontWeight: 500,
        }}
      >
        {progressMessage}
      </Typography>
    )}
  </Box>
);

export default observer(Loader);
