import React from 'react';
import { observer } from 'mobx-react';
import { Box } from '@mui/material';

import Layout from './components/Layout';
import Menu from './components/Menu';

const MenuPreview = props => {
  const { state } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        height: '85vh',
        fontFamily: `${state.font} !important`,
        '& h3': {
          fontFamily: `${state.font} !important`,
        },
        '& p': {
          fontFamily: `${state.font} !important`,
        },
        '& span': {
          fontFamily: `${state.font} !important`,
        },
      }}
    >
      <Layout {...props}>
        <Menu {...props} />
      </Layout>
    </Box>
  );
};

export default observer(MenuPreview);
