import { types } from 'mobx-state-tree';

const Metadata = types.model({
  cable_color: types.maybeNull(types.string),
  cable_length_m: types.maybeNull(types.number),
});

export const TemperatureSensor = types.model({
  id: types.integer,
  serial_number: types.maybeNull(types.string),
  metadata: types.maybeNull(Metadata),
  correction_factor: types.maybeNull(types.number),
  created_at: types.maybeNull(types.string),
  updated_at: types.maybeNull(types.string),
  reported_as: types.maybeNull(types.string),
  sequence_number: types.maybeNull(types.number),
  latest_sample_id: types.maybeNull(types.integer),
  latest_sample_received_at: types.maybeNull(types.string),
  latest_sample_reported_by: types.maybeNull(types.string),
  latest_sample_value: types.maybeNull(types.number),
  latest_sample_age_seconds: types.maybeNull(types.number),
  _gateways_id: types.maybeNull(types.integer),
  _gateway_types_sensors_caption: types.maybeNull(types.string),
});
