import { types } from 'mobx-state-tree';

const Metadata = types.model({
  category: types.maybeNull(types.string),
});

export const Beverage = types.model({
  id: types.integer,
  name: types.maybeNull(types.string),
  // category_code: types.maybeNull(types.integer),
  style: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  abv: types.maybeNull(types.union(types.number, types.string)),
  ibu: types.maybeNull(types.union(types.number, types.string)),
  image_url: types.maybeNull(types.string),
  producer_id: types.maybeNull(types.integer),
  // archived: types.maybeNull(types.boolean),
  metadata: types.maybeNull(types.union(Metadata, types.string)),
  // establishment_id: types.maybeNull(types.integer),
  prototype_beverage_id: types.maybeNull(types.integer),
  // indexable: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  ingredients: types.maybeNull(types.array(types.string)),
  keywords: types.maybeNull(types.array(types.string)),
  // weighting: types.number,
  // updated_at: types.date,
  // updated_by: types.integer,
  _name: types.maybeNull(types.string),
  // _category_code: types.maybeNull(types.integer),
  _style: types.maybeNull(types.string),
  _type: types.maybeNull(types.string),
  _abv: types.maybeNull(types.union(types.number, types.string)),
  _ibu: types.maybeNull(types.union(types.number, types.string)),
  _image_url: types.maybeNull(types.string),
  // _metadata: types.string,
  _description: types.maybeNull(types.string),
  _ingredients: types.maybeNull(types.array(types.string)),
  _keywords: types.maybeNull(types.array(types.string)),
  // _weighting: types.number,
  _producers_name: types.maybeNull(types.string),
  _producers_address_locality: types.maybeNull(types.string),
  // _producers_indexable: types.maybeNull(types.string),
  // _producers_image_url: types.maybeNull(types.string),
  // _prototype_name: types.maybeNull(types.string),
  // _users_username_updated_by: types.string,
  isConnectedToItem: types.maybeNull(types.boolean),
  in_distribution: types.maybeNull(types.boolean),
  in_production: types.maybeNull(types.boolean),
  hidden: types.maybeNull(types.boolean),
});
