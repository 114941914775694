import { flow, types } from 'mobx-state-tree';
import { getRootStore } from 'models/root';
import api from '../../services/API';

export const RoleUser = types
  .model({
    id: types.identifierNumber,
    // alerts_flag: types.maybeNull(types.boolean),
    // archived: types.maybeNull(types.boolean),
    not_bev_cold: types.maybeNull(types.boolean),
    not_bev_hot: types.maybeNull(types.boolean),
    not_cooler_cold: types.maybeNull(types.boolean),
    not_cooler_warm: types.maybeNull(types.boolean),
    not_keg: types.maybeNull(types.boolean),
    not_leak: types.maybeNull(types.boolean),
    not_out_of_hours: types.maybeNull(types.boolean),
    not_pr_hi: types.maybeNull(types.boolean),
    not_pr_low: types.maybeNull(types.boolean),
    reports_flag: types.maybeNull(types.boolean),
    reports_monthly: types.maybeNull(types.boolean),
    reports_weekly: types.maybeNull(types.boolean),
    role_id: types.maybeNull(types.number),
    // updated_at: types.maybeNull(types.string),
    // updated_by: types.maybeNull(types.number),
    user_id: types.maybeNull(types.number),
    // _establishments_id: types.maybeNull(types.number),
    // _establishments_name: types.maybeNull(types.string),
    _roles_name: types.maybeNull(types.string),
    // _roles_sort_value: types.maybeNull(types.number),
    // _users_username: types.maybeNull(types.string),
    // _users_username_updated_by: types.maybeNull(types.string),
    invited_at: types.maybeNull(types.string),
    invited_by: types.maybeNull(types.number),
  })
  .views(self => ({
    get user() {
      const { usersStore } = getRootStore();
      if (self.user_id) {
        return usersStore.getUserById(self.user_id);
      }
      return null;
    },
  }))
  .actions(self => ({
    patch: flow(function* (patch) {
      try {
        yield api.patchRoleUserFlags({ ...patch, role_user_id: self.id });
        Object.assign(self, patch);
      } catch (err) {
        return Promise.reject(err);
      }
    }),
  }));
