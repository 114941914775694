import { types } from 'mobx-state-tree';

export const Unit = types.model({
  id: types.identifierNumber,
  name: types.string,
  abbreviation: types.maybeNull(types.string),
  category: types.string,
  ml_equivalent: types.number,
  archived: types.boolean,
});
