import React from 'react';
import { useSnackbar } from 'notistack';
import { Button } from '@mui/material';

const SnackbarAction = ({ keyProp }) => {
  const { closeSnackbar } = useSnackbar();

  return <Button onClick={() => closeSnackbar(keyProp)}>Dismiss</Button>;
};

export default SnackbarAction;
