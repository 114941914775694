import { types } from 'mobx-state-tree';

const ReportThreshold = types.model({
  comp_threshold_pct: types.maybeNull(types.number),
  efficiency_threshold_pct: types.maybeNull(types.number),
  human_threshold_pct: types.maybeNull(types.number),
  max_target_efficiency_pct: types.maybeNull(types.number),
  min_target_efficiency_pct: types.maybeNull(types.number),
  overpour_threshold_pct: types.maybeNull(types.number),
  pourcost_threshold_pct: types.maybeNull(types.number),
  quality_threshold_pct: types.maybeNull(types.number),
  system_threshold_pct: types.maybeNull(types.number),
  underpour_threshold_pct: types.maybeNull(types.number),
});

const DeliveryInfo = types.model({
  daily_report_recipients: types.maybeNull(types.array(types.string)),
  weekly_report_recipients: types.maybeNull(types.array(types.string)),
  monthly_report_recipients: types.maybeNull(types.array(types.string)),
});

const Rotation = types.model({
  hours: types.maybeNull(types.number),
});

const DefaultReportPreference = types.model({
  daily: types.model({
    daily_report_type: types.number,
  }),

  weekly: types.model({
    daily_report_type: types.number,
  }),

  monthly: types.model({
    daily_report_type: types.number,
  }),
});

export const Report = types.model({
  archived: types.maybeNull(types.boolean),
  auto_deliver_daily: types.maybeNull(types.boolean),
  auto_deliver_daily_hour: types.maybeNull(types.number),
  auto_deliver_monthly: types.maybeNull(types.boolean),
  auto_deliver_monthly_day: types.maybeNull(types.number),
  auto_deliver_monthly_hour: types.maybeNull(types.number),
  auto_deliver_weekly: types.maybeNull(types.boolean),
  auto_deliver_weekly_day: types.maybeNull(types.number),
  auto_deliver_weekly_hour: types.maybeNull(types.number),
  benchmark_efficiency: types.maybeNull(types.number),
  inventory_consumption_days: types.maybeNull(types.number),
  combine_voids_and_comps: types.maybeNull(types.boolean),
  day_week_begins: types.maybeNull(types.number),
  default_report_type: types.maybeNull(types.number),
  establishment_id: types.maybeNull(types.number),
  hourly_breakdown_combine_sales_and_comps: types.maybeNull(types.boolean),
  hourly_breakdown_exclude_overlay: types.maybeNull(types.boolean),
  hourly_breakdown_exclude_sales: types.maybeNull(types.boolean),
  id: types.maybeNull(types.number),
  reports_valid_from: types.maybeNull(types.string),
  updated_at: types.maybeNull(types.string),
  updated_by: types.maybeNull(types.number),
  volume_only: types.maybeNull(types.boolean),
  zero_dollar_comps: types.maybeNull(types.boolean),
  _establishments_name: types.maybeNull(types.string),
  _users_display_name_updated_by: types.maybeNull(types.string),
  delivery_info: DeliveryInfo,
  report_thresholds: ReportThreshold,
  rotation: Rotation,
  report_delivery_status_code: types.maybeNull(types.number),
  default_report_preferences: types.maybeNull(DefaultReportPreference),
});
