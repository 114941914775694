import { Box, DialogContent, List, ListItemButton, Typography } from '@mui/material';
import { Check } from '@mui/icons-material';
import React from 'react';

export const SelectDialog = ({ title, description, values, handleChange, currentValue }) => {
  return (
    <DialogContent>
      <Box>
        {title && (
          <Typography
            variant={'h6'}
            color={({ palette }) => palette.whiteEmphasis.high}
            textAlign={'center'}
            pb={2}
          >
            {title}
          </Typography>
        )}
        {description && (
          <Box mb={6}>
            {description.map((paragraph, index) => (
              <Typography key={index} variant={'body2'} mb={1}>
                {paragraph}
              </Typography>
            ))}
          </Box>
        )}
      </Box>
      {values && (
        <List>
          {values.map(({ label, value }) => {
            return (
              <ListItemButton
                key={value}
                sx={({ palette }) => ({
                  borderBottom: `1px solid ${palette.background.overlay3}`,
                  padding: '12px 0',
                })}
                value={value}
              >
                <Box
                  justifyContent={'space-between'}
                  width={'100%'}
                  display={'flex'}
                  alignItems={'center'}
                  onClick={handleChange}
                  value={value}
                >
                  <Typography
                    variant={'h7'}
                    color={({ palette }) => palette.whiteEmphasis.high}
                    value={value}
                  >
                    {label}
                  </Typography>
                  <Check
                    sx={({ palette }) => ({
                      fontSize: '32px',
                      color: label === currentValue ? palette.primary.main : palette.secondary.dark,
                    })}
                  />
                </Box>
              </ListItemButton>
            );
          })}
        </List>
      )}
    </DialogContent>
  );
};
