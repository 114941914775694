import palette from 'theme/palette';

const MuiAppBar = {
  styleOverrides: {
    colorPrimary: {
      color: palette.white,
      backgroundColor: palette.secondary.main,
    },
  },
};

export default MuiAppBar;
