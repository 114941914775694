import { types, flow, typecheck } from 'mobx-state-tree';
import * as Sentry from '@sentry/react';

import api from 'services/API';
import socketClient from 'services/socketClient';
import { updateAbility } from 'services/casl';
import { getRootStore } from 'models/root';
import { UserRole } from 'models/types/userRole';
import { UserProfileWithViews } from 'models/types/userProfileWithViews';
import { UserProfile } from 'models/types/userProfile';
import userRolePriority from 'config/userRolePriority';
import { arrayUtilities } from 'utils';

const TOKEN = 'bar_track_pwa_token';
const USER = 'bar_track_pwa_user';
const ROLES = 'bar_track_pwa_roles';
const CURRENT_ROLE = 'bar_track_pwa_current_role';

export const userInitialState = {
  isAuthenticated: false,
  state: 'done',
  auth_token: '',
  roles: [],
  isManuallyLoggedOut: false,
  profile: null,
  currentRole: null,
  searchString: '',
};

const clearLocalStorage = () => {
  localStorage.removeItem(TOKEN);
  localStorage.removeItem(USER);
  localStorage.removeItem(ROLES);
  localStorage.removeItem(CURRENT_ROLE);
};

const processRoles = (inputRoles = []) => {
  // roles are sorted server side by locked -> roleuser_id -> default_roleuser_id -> id
  // agreed with Dave that the first role should be considered the default role
  // client should not rely on "default" flag
  const defaultRole = inputRoles[0];
  const roles = inputRoles.sort((a, b) => {
    const roleAEstablishment = a._establishment_name;
    const roleBEstablishment = b._establishment_name;
    if (roleAEstablishment < roleBEstablishment) {
      return -1;
    } else if (roleAEstablishment > roleBEstablishment) {
      return 1;
    } else {
      const roleAPriority = userRolePriority[a._role_name];
      const roleBPriority = userRolePriority[b._role_name];
      if (roleAPriority < roleBPriority) {
        return -1;
      } else if (roleAPriority > roleBPriority) {
        return 1;
      }
      return 0;
    }
  });

  return { roles, defaultRole };
};

const composeRules = rules => {
  if (Array.isArray(rules)) {
    return rules.reduce((all, aclRule) => {
      if (Array.isArray(aclRule.ruleset)) {
        return all.concat(aclRule.ruleset);
      }
      return all;
    }, []);
  }
  return [];
};

export const userModel = types
  .model({
    isAuthenticated: types.boolean,
    state: types.enumeration('State', ['done', 'pending', 'error']),
    auth_token: types.string,
    roles: types.array(UserRole),
    isManuallyLoggedOut: types.boolean,
    profile: types.maybeNull(UserProfileWithViews),
    currentRole: types.maybeNull(UserRole),
    searchString: types.maybeNull(types.string),
  })
  .views(self => ({
    get isBarTrackUser() {
      if (self.currentRole && self.currentRole._role_name) {
        return self.currentRole._role_name === 'BarTrack';
      }
      return false;
    },
    get activeRoles() {
      return self.roles.filter(role => !role._roleuser_locked);
    },
    get establishmentName() {
      return self.roles.find(role => role._establishment_id === self.currentRole._establishment_id)
        ._establishment_name;
    },
    filteredRolesBySearch: search => {
      let filteredRoles;

      filteredRoles =
        !search || search === ''
          ? self.activeRoles
          : arrayUtilities.filterDataBySearch(search, self.activeRoles, ['_establishment_name']);

      if (search && filteredRoles.length === 0) {
        filteredRoles = [];
      }
      return filteredRoles;
    },

    get getRoles() {
      return self.roles;
    },
  }))
  .actions(self => ({
    afterCreate() {
      const token = localStorage.getItem(TOKEN);
      const userJSON = localStorage.getItem(USER);
      const rolesJSON = localStorage.getItem(ROLES);

      try {
        if (token && userJSON && rolesJSON) {
          const user = JSON.parse(userJSON);
          typecheck(UserProfile, user);
          const parsedRoles = JSON.parse(rolesJSON);
          const { roles, defaultRole } = processRoles(parsedRoles);
          const currentRoleString = localStorage.getItem(CURRENT_ROLE);
          const currentRole = JSON.parse(currentRoleString);
          const role = currentRole || defaultRole;

          if (token && Array.isArray(roles) && roles.length > 0 && role && role._role_aclrules) {
            typecheck(UserRole, role);
            self.auth_token = token;
            self.profile = user;
            self.isAuthenticated = true;
            self.roles = roles;
            self.currentRole = role;

            self.setSentryUser(user, role);

            updateAbility(composeRules(role._role_aclrules));
          }
        }
      } catch (err) {
        console.log(err);
        clearLocalStorage();
        window.location.reload();
      }
    },

    setProfile(profile) {
      try {
        self.profile = profile;
        localStorage.setItem(USER, JSON.stringify(profile));
      } catch (err) {
        console.log(err);
      }
    },

    setSentryUser(user, role) {
      const firstName = user.first_name || '';
      const lastName = user.last_name || '';
      const fullName = `${firstName} ${lastName}`.trim();

      Sentry.setUser({
        id: user.id,
        email: user.email,
        'Full Name': fullName ? fullName : 'N/A',
        UUID: user.uuid,
        Role: {
          _establishment_id: role._establishment_id,
          _establishment_name: role._establishment_name,
          _role_id: role._role_id,
          _role_name: role._role_name,
          _roleuser_id: role._roleuser_id,
        },
        'App Version': process.env.REACT_APP_VERSION,
      });
    },

    login: flow(function* (data, isSSO = false) {
      self.state = 'pending';
      try {
        let auth_token;
        let user;

        if (isSSO) {
          const { usersStore } = getRootStore();
          user = yield usersStore.fetchUser(data.user_id, {
            Authorization: `Bearer ${data.auth_token}`,
          });
          auth_token = data.auth_token;
        } else {
          const response = yield api.login(data);
          if (response?.data) {
            user = response.data.user;
            auth_token = response.data.auth_token;
          }
        }

        if (auth_token && user) {
          const { roles, defaultRole } = processRoles(user._roles);
          if (roles.length === 0) {
            self.logout();
            return Promise.reject(new Error('The user has no valid roles assigned'));
          }
          self.setProfile(user);
          self.auth_token = auth_token;
          self.roles = roles;
          self.setCurrentRole(defaultRole);
          self.state = 'done';

          self.setSentryUser(user, defaultRole);

          self.isAuthenticated = true;
          localStorage.setItem(TOKEN, auth_token);
          localStorage.setItem(ROLES, JSON.stringify(roles));
          socketClient.connect(auth_token);
        }

        return Promise.resolve();
      } catch (err) {
        self.state = 'error';
        self.isAuthenticated = false;
        return Promise.reject(err);
      }
    }),

    silentLogin({ user, auth_token, role_user_id = null, reinitialize = false }) {
      self.state = 'pending';
      try {
        const { roles } = processRoles(user._roles);
        const roleUserId = role_user_id || user.current_role_user || user.default_roleuser_id;
        const currentRole = user._roles.find(({ _roleuser_id }) => _roleuser_id === roleUserId);
        self.setProfile(user);
        self.auth_token = auth_token;
        self.roles = roles;
        self.setCurrentRole(currentRole);
        self.state = 'done';
        self.isAuthenticated = true;
        Sentry.setUser({
          id: self.profile.id,
          email: self.profile.email,
        });
        localStorage.setItem(TOKEN, auth_token);
        localStorage.setItem(ROLES, JSON.stringify(roles));
        if (reinitialize) {
          socketClient.reinitialize(auth_token);
        } else {
          socketClient.connect(auth_token);
        }
      } catch (err) {
        console.log(err);
        self.state = 'error';
      }
    },

    logout(isManuallyLoggedOut = true) {
      api.logout(self.auth_token);
      clearLocalStorage();
      self.isAuthenticated = false;
      self.isManuallyLoggedOut = isManuallyLoggedOut;
      self.state = 'done';
      self.auth_token = '';
      self.roles = [];
      if (socketClient.connection) {
        socketClient.connection.close();
      }
    },

    setAuthToken(auth_token) {
      self.auth_token = auth_token;
      localStorage.setItem(TOKEN, auth_token);
    },

    setAuthData(data) {
      const { roles } = processRoles(data.roles);
      self.auth_token = data.auth_token;
      self.roles = roles;
      localStorage.setItem(TOKEN, data.auth_token);
      localStorage.setItem(ROLES, JSON.stringify(roles));
    },

    setCurrentRole(role) {
      localStorage.setItem(CURRENT_ROLE, JSON.stringify(role));
      self.currentRole = role;
      const { _role_aclrules } = role;
      self.setACLRules(_role_aclrules);
    },

    setACLRules(rules) {
      updateAbility(composeRules(rules));
    },

    changeEstablishment: flow(function* (establishment) {
      const { ui } = getRootStore();
      ui.setIsDataLoading(true);
      try {
        const authData = yield api.changeUserRole({
          role_user_id: establishment._roleuser_id,
        });
        if (authData && authData.data && authData.data.auth_token) {
          self.setAuthData({
            auth_token: authData.data.auth_token,
            roles: authData.data.user._roles,
          });
          self.setCurrentRole(establishment);
          socketClient.reinitialize(authData.data.auth_token);
        }
      } catch (err) {
        return Promise.reject(err);
      }
    }),

    resetPassword: flow(function* (new_password, headers) {
      try {
        const response = yield api.resetPassword({ new_password }, { headers });
        if (response && response.data) {
          return response.data;
        }
      } catch (err) {
        return Promise.reject(err);
      }
    }),

    forgotPassword: flow(function* (email) {
      try {
        return yield api.forgotPassword({ email });
      } catch (err) {
        return Promise.reject(err);
      }
    }),

    setSearchString(value) {
      self.searchString = value;
    },

    updateEstablishmentNameById({ name, id }) {
      const index = self.roles.findIndex(item => item._establishment_id === id);
      self.roles[index]._establishment_name = name;
    },
  }));
