const MuiAccordionSummary = {
  styleOverrides: {
    root: {
      minHeight: 44,
      '&.Mui-expanded': {
        minHeight: 44,
      },
    },
    content: {
      margin: '9px 0',
      '&.Mui-expanded': {
        margin: '9px 0',
      },
    },
  },
};

export default MuiAccordionSummary;
