import { types } from 'mobx-state-tree';

const ItemEvent = types.model({
  earliest_at: types.string,
  earliest_by: types.number,
  earliest_by_displayname: types.maybeNull(types.string),
  latest_at: types.string,
  latest_by: types.number,
  latest_by_displayname: types.maybeNull(types.string),
  type_code: types.number,
});

const Duration = types.model({
  hours: types.maybeNull(types.number),
  minutes: types.maybeNull(types.number),
  seconds: types.maybeNull(types.number),
  milliseconds: types.maybeNull(types.number),
});

export const Item = types.model({
  id: types.identifierNumber,
  establishment_id: types.maybeNull(types.integer),
  beverage_id: types.maybeNull(types.integer),
  container_id: types.maybeNull(types.integer),
  status_code: types.maybeNull(types.integer),
  use_by: types.maybeNull(types.string),
  unit_revenue_goal: types.maybeNull(types.number),
  unit_cost: types.maybeNull(types.number),
  notes: types.maybeNull(types.string),
  initial_volume_total: types.maybeNull(types.number),
  cooler_id: types.maybeNull(types.number),
  _beverages_name: types.maybeNull(types.string),
  _containers_name: types.maybeNull(types.string),
  _containers_type_code: types.maybeNull(types.number),
  _containers_volume_unit_id: types.maybeNull(types.number),
  _containers_volume_total: types.maybeNull(types.number),
  _units_name: types.maybeNull(types.string),
  _volume_total_ml: types.maybeNull(types.number),
  _volume_remaining_ml: types.maybeNull(types.number),
  _containers_volume_total_ml: types.maybeNull(types.number),
  _item_events_summary: types.maybeNull(types.array(ItemEvent)),
  _fully_connected_duration: types.maybeNull(Duration),
  _queued_count: types.maybeNull(types.number),
  _bar_ids: types.maybeNull(types.array(types.number)),
});
