import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { styled } from '@mui/system';

export const TextDialog = ({
  title,
  handleDialogClose,
  description,
  name,
  valueState,
  handleChange,
}) => {
  return (
    <>
      <DialogActions
        sx={{
          padding: '16px',
          justifyContent: 'start',
        }}
      >
        <Box onClick={handleDialogClose}>
          <CloseIcon />
        </Box>
      </DialogActions>
      <StyledDialogContent>
        <DialogContentText component={'div'}>
          {title && <span className={'title'}>{title}</span>}
          {description && (
            <Box mb={6} mt={2}>
              {description.map((paragraph, index) => (
                <Typography key={index} variant={'body2'} mb={1}>
                  {paragraph}
                </Typography>
              ))}
            </Box>
          )}
        </DialogContentText>
        <TextField
          label={`${title} (%)`}
          name={name}
          type={'number'}
          InputProps={{ inputProps: { min: 0, max: 100 } }}
          value={valueState[name]}
          onChange={handleChange}
        />
      </StyledDialogContent>
      <DialogActions>
        <Button
          variant={'contained'}
          fullWidth={true}
          onClick={handleDialogClose}
          sx={{ borderRadius: '36px', margin: '16px!important' }}
        >
          Save
        </Button>
      </DialogActions>
    </>
  );
};

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  '.title': {
    fontWeight: '500',
    fontSize: '20px',
    letterSpacing: '-0.012px',
    color: theme.palette.whiteEmphasis.high,
    paddingBottom: '16px',
  },
}));
