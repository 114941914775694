import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useStore, useQuery, useDigitalMenuData } from 'hooks';
import Editor from './Editor';

const DigitalMenuLayout = ({ cb }) => {
  const { linesStore, menusStore, menuDesignsStore } = useStore();
  const query = useQuery();
  const navigate = useNavigate();
  const { isLoaded } = useDigitalMenuData();

  const [design, setDesign] = useState(undefined);
  const [lists, setLists] = useState(undefined);
  const [isCreating, setIsCreating] = useState(false);

  const activeLines = useMemo(() => {
    return linesStore.activeLines.reduce((acc, curr) => {
      acc.push({
        line: curr,
        beverage: curr.beverage,
        item: curr.item,
      });
      return acc;
    }, []);
  }, [linesStore.activeLines]);

  useEffect(() => {
    const menuId = query.get('menu_id');
    if (menuId) {
      const currentMenu = menusStore.getMenuById(menuId);
      if (currentMenu) {
        if (currentMenu.design) {
          setDesign(currentMenu.design);
        }
        if (currentMenu.lists) {
          setLists(currentMenu.lists);
        }
      }
    } else {
      const designId = query.get('design_id');
      if (designId) {
        const currentDesign = menuDesignsStore.getDesignById(designId);
        setDesign(currentDesign);
      } else {
        setIsCreating(true);
      }
    }
  }, [activeLines, menuDesignsStore, menusStore, query]);

  if (!isLoaded) {
    navigate('/digital-menu/menus');
  }

  return (
    <Box>
      <Editor design={design} lists={lists} isCreating={isCreating} cb={cb} />
    </Box>
  );
};

export default observer(DigitalMenuLayout);
