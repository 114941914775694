import { types } from 'mobx-state-tree';
import { HEADER_HEIGHT } from 'config/ui';
import { UPDATE_TOOLBAR_HEIGHT, NO_INTERNET_HEIGHT, NO_INTERNET_HEIGHT_MOBILE } from 'config/ui';

export const uiInitialState = {
  isOpenedMenu: true,
  isOpenedMobileMenu: false,
  isOpenedEstablishmentMenu: false,
  modalsVisibility: {
    calculator: false,
    userProfileVisibility: false,
    missingUserDetails: false,
  },
  isNoInternet: false,
  isUpdatedAvailable: false,
  isDataLoading: true,
  isDataLoaded: false,
  tapDetails: {
    mobileDashboardScrollToBottom: false,
  },
  onTapScrollTop: 0,
  beverageScrollTop: 0,
  pathState: {
    previous: '/on-tap',
    current: '/on-tap',
  },
};

const ModalsVisibility = types.model({
  calculator: types.boolean,
  userProfileVisibility: types.boolean,
  missingUserDetails: types.boolean,
});

export const uiModel = types
  .model({
    isOpenedMenu: types.boolean,
    isOpenedMobileMenu: types.boolean,
    isOpenedEstablishmentMenu: types.boolean,
    modalsVisibility: ModalsVisibility,
    isNoInternet: types.boolean,
    isUpdatedAvailable: types.boolean,
    isDataLoading: types.boolean,
    isDataLoaded: types.boolean,
    tapDetails: types.model({
      mobileDashboardScrollToBottom: types.boolean,
    }),
    onTapScrollTop: types.number,
    beverageScrollTop: types.number,
    pathState: types.model({
      previous: types.string,
      current: types.string,
    }),
  })
  .views(self => ({
    headerHeight: isMobile => {
      if (self.isUpdatedAvailable && !self.isNoInternet) {
        return UPDATE_TOOLBAR_HEIGHT + HEADER_HEIGHT;
      } else if (self.isNoInternet && !self.isUpdatedAvailable) {
        return (isMobile ? NO_INTERNET_HEIGHT_MOBILE : NO_INTERNET_HEIGHT) + HEADER_HEIGHT;
      } else if (self.isNoInternet && self.isUpdatedAvailable) {
        return (
          (isMobile
            ? NO_INTERNET_HEIGHT_MOBILE + UPDATE_TOOLBAR_HEIGHT
            : NO_INTERNET_HEIGHT + UPDATE_TOOLBAR_HEIGHT) + HEADER_HEIGHT
        );
      } else {
        return HEADER_HEIGHT;
      }
    },
    getNotificationBarHeight(isMobile) {
      let height = 0;

      if (self.isNoInternet) {
        height += isMobile ? NO_INTERNET_HEIGHT_MOBILE : NO_INTERNET_HEIGHT;
      }

      if (self.isUpdatedAvailable) {
        height += UPDATE_TOOLBAR_HEIGHT;
      }

      return height;
    },
  }))
  .actions(self => ({
    toggleMenu() {
      self.isOpenedMenu = !self.isOpenedMenu;
    },
    setEstablishmentMenu(value) {
      self.isOpenedEstablishmentMenu = value;
    },
    setModalVisibility(modal, value) {
      if (modal in self.modalsVisibility) {
        self.modalsVisibility[modal] = value;
      }
    },
    setUpdateAvailability(isAvailable) {
      self.isUpdatedAvailable = isAvailable;
    },
    setNoInternetVisibility(isNoInternet) {
      self.isNoInternet = isNoInternet;
    },
    setIsDataLoading(value) {
      self.isDataLoading = value;
    },
    setIsDataLoaded(value) {
      self.isDataLoaded = value;
    },
    setTapDetailsMobileDashboardScrollToBottom(value) {
      self.tapDetails.mobileDashboardScrollToBottom = value;
    },
    setOnTapScrollTop(value) {
      self.onTapScrollTop = value;
    },
    setBeverageScrollTop(value) {
      self.beverageScrollTop = value;
    },
    setPathState(value) {
      const index = self.pathState.current.slice(1).indexOf('/');
      const parentPath = self.pathState.current.slice(
        0,
        (index > 0 ? index : self.pathState.current.length) + 1,
      );

      if (self.pathState.current !== value && !value.startsWith(parentPath)) {
        self.pathState.previous = self.pathState.current;
        self.pathState.current = value;
      }
    },
    setMobileMenu(value) {
      self.isOpenedMobileMenu = value;
    },
  }));
