import { types } from 'mobx-state-tree';

export const ItemLine = types.model({
  id: types.integer,
  item_id: types.maybeNull(types.integer),
  line_id: types.integer,
  connected_from: types.maybeNull(types.string),
  connected_to: types.maybeNull(types.string),
  // connected: types.Date,
  queue_index: types.maybeNull(types.number),
  archived: types.maybeNull(types.boolean),
});
