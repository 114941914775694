import palette from 'theme/palette';

const MuiLink = {
  styleOverrides: {
    root: {
      position: 'relative',
      cursor: 'pointer',
      '&::after': {
        content: "''",
        width: 0,
        height: 1,
        backgroundColor: palette.white,
        position: 'absolute',
        bottom: -3,
        left: '50%',
        transform: 'translateX(-50%)',
        transition: '.3s',
      },
      '&:hover': {
        '&::after': {
          width: '100%',
        },
      },
      '&[disabled]': {
        color: 'rgba(218, 218, 218, 0.10)',
        cursor: 'default',
        '&:hover': {
          textDecoration: 'none',
          '&::after': {
            width: 0,
          },
        },
      },
    },
    underlineHover: {
      '&:hover': {
        textDecoration: 'none',
      },
    },
    underlineAlways: {
      textDecoration: 'none',
    },
  },
};

export default MuiLink;
