import React, { useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { Button, Grid, Box, styled, alpha } from '@mui/material';
import { Brush, Build } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import Tabs from 'components/shared/Tabs';
import Design from './Design';
import Layout from './Layout';

const SettingsPanel = props => {
  const { onSave } = props;
  const [currentTab, setCurrentTab] = useState(null);
  const navigate = useNavigate();

  const onTabChange = useCallback(value => {
    setCurrentTab(value);
  }, []);

  const onExit = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <StyledRootBox>
      <Tabs
        className="tabs"
        onTabChange={onTabChange}
        currentTab={currentTab}
        isFullWidth
        isSquareTabs
        tabs={[
          {
            label: 'Layout',
            icon: <Build />,
            panel: <Layout {...props} />,
          },
          {
            label: 'Design',
            icon: <Brush />,
            panel: <Design {...props} />,
          },
        ]}
      />
      <Box className="buttonGroup">
        <Button color="primary" variant="contained" onClick={() => onSave(true)} fullWidth>
          Save and Close
        </Button>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button variant="outlined" onClick={() => onSave(false)} fullWidth>
              Save
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="outlined" onClick={onExit} fullWidth>
              Exit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </StyledRootBox>
  );
};

export default observer(SettingsPanel);

const StyledRootBox = styled(Box)(({ theme: { breakpoints, palette, spacing } }) => ({
  '.MuiAccordionSummary-expandIconWrapper': {
    color: 'inherit',
  },
  [breakpoints.down('xs')]: {
    margin: '0 -16px',
  },
  '& .MuiIconButton-edgeEnd': {
    marginRight: -3,
    color: alpha(palette.white, 0.5),
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
  },
  '.buttonGroup': {
    display: 'grid',
    gridGap: spacing(2),
    padding: `0 ${spacing(2)} ${spacing(2)}`,
    position: 'sticky',
    zIndex: 1000,
    bottom: 0,
    background: palette.secondary.dark,
  },
  '.tabs': {
    position: 'sticky',
    background: palette.secondary.dark,
    zIndex: 1000,
    top: 0,
    '& button.MuiTab-root': {
      minHeight: 70,
      marginTop: spacing(1),
    },
  },
}));
