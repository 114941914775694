import { alpha } from '@mui/material/styles';
import palette from 'theme/palette';

const MuiOutlinedInput = {
  styleOverrides: {
    root: {
      height: '100%',
      color: palette.white,
      borderRadius: 5,
      transition: '0.3s',
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: palette.white,
      },
      // todo check focused
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: palette.primary.main,
        borderWidth: 1,
      },
      '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
        borderColor: alpha(palette.white, 0.2),
      },
      '&$error': {
        '&:hover, &$focused': {
          '& $notchedOutline': {
            borderColor: palette.error.main,
          },
        },
      },
    },
    notchedOutline: {
      transition: '0.3s',
      borderColor: alpha(palette.white, 0.32),
      '&:hover': {
        borderColor: palette.white,
      },
    },
    input: {
      padding: '16px 14px',
      minHeight: '1.1876em!important',
    },
    inputAdornedStart: {
      paddingLeft: 0,
    },
  },
};

export default MuiOutlinedInput;
