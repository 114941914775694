import React from 'react';
import {
  AccountCircle as AccountCircleIcon,
  Tv as TvIcon,
  PeopleAlt as PeopleAltIcon,
} from '@mui/icons-material';
import {
  TapIcon,
  InventoryIcon,
  JarIcon,
  CleanIcon,
  FiltersIcon,
  UserIcon,
  CalculatorIcon,
  PolicyIcon,
  MailIcon,
  ExitIcon,
  ReportsIcon,
} from 'assets/icons';

import permissions from 'services/permissions';
import { getRootStore } from 'models/root';

const { userStore, ui } = getRootStore();

const DesktopMenuItems = [
  {
    id: 'tap',
    path: '/on-tap',
    name: 'On-Tap',
    icon: <TapIcon />,
    guard: (props = {}) => {
      const { leftMenu } = props || {};
      return Boolean(leftMenu);
    },
  },
  {
    id: 'inventory',
    path: '/inventory',
    name: 'Inventory',
    icon: <InventoryIcon />,
    hasDivider: true,
    guard: (props = {}) => {
      if (permissions.allowManageInventory() || permissions.allowReadOnlyInventory()) {
        const { leftMenu } = props || {};
        return Boolean(leftMenu);
      }
      return false;
    },
  },
  {
    id: 'my-beverages',
    path: '/my-beverages',
    name: 'My Beverages',
    icon: <JarIcon />,
    guard: () => permissions.allowManageMyBeverages() || permissions.allowReadOnlyMyBeverages(),
  },
  {
    id: 'quality-management',
    path: '/quality-management',
    name: 'Quality Management',
    icon: <CleanIcon />,
    guard: permissions.allowQualityManagement,
  },
  {
    id: 'reports',
    path: '/reports',
    name: 'Reports',
    icon: <ReportsIcon />,
    guard: permissions.allowManageReports,
  },
  {
    id: 'digital-menu',
    path: '/digital-menu',
    name: 'Digital Menu',
    icon: <TvIcon />,
    guard: permissions.allowDigitalMenu,
    hasDivider: true,
  },
  {
    id: 'bar-manager',
    path: '/bar-manager',
    name: 'Bar Manager',
    icon: <UserIcon />,
    guard: permissions.allowBarManager,
  },
  {
    id: 'employees',
    path: '/employees',
    name: 'Employees',
    icon: <PeopleAltIcon />,
    guard: () => ['BarTrack', 'Owner', 'Manager'].includes(userStore.currentRole?._role_name),
  },
  {
    id: 'system-configuration',
    path: '/system-configuration',
    name: 'System Configuration',
    icon: <FiltersIcon />,
    guard: permissions.allowSystemConfiguration,
    hasDivider: true,
  },
  {
    id: 'calculator',
    name: 'Draft Pricing Calculator',
    icon: <CalculatorIcon />,
    hasDivider: true,
    isButton: true,
    mobileVisibility: true,
    action: () => {
      ui.setModalVisibility('calculator', true);
    },
  },
  {
    id: 'eusa',
    path: '/end-user-subscription-agreement',
    name: 'EUSA',
    icon: <PolicyIcon />,
  },
  {
    id: 'contact-us',
    path: '/contact-us',
    name: 'Contact Us',
    icon: <MailIcon />,
    hasDivider: true,
    isLast: true,
  },
  {
    id: 'my-account',
    path: '/my-account',
    name: 'My Account',
    icon: <AccountCircleIcon />,
    isBottom: true,
  },
  {
    id: 'log-out',
    path: '/auth/login',
    name: 'Log Out',
    icon: <ExitIcon />,
    isButton: true,
    action: () => userStore.logout(),
  },
];

export default DesktopMenuItems;
