import React, { useContext, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { Box, styled, alpha } from '@mui/material';
import { ReferenceContext } from 'context/reference';

const MobileHeaderContainer = () => {
  const ref = useRef();
  const { mobileHeader } = useContext(ReferenceContext);
  const { setRef } = mobileHeader;

  useEffect(() => {
    setRef(ref);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <StyledRootBox ref={ref} />;
};

export default observer(MobileHeaderContainer);

const StyledRootBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '60px',
  [theme.breakpoints.down('md')]: {
    '& .MuiIconButton-root': {
      color: alpha(theme.palette.white, 0.6),
    },
  },
  '& h4': {
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      lineHeight: 1.2,
    },
  },
}));
