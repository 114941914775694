const menuTemplates = [
  {
    id: 1,
    rows: [
      {
        name: 'Row #1',
        value: 'Beverage Name',
      },
      {
        name: 'Row #2',
        value: 'Producer, Type / Style, ABV, IBU',
      },
      {
        name: 'Row #3',
        value: 'Serving Sizes & Pricing',
      },
    ],
    type: 'bar',
  },
  {
    id: 2,
    rows: [
      {
        name: 'Row #1',
        value: 'Beverage Name & Producer',
      },
      {
        name: 'Row #2',
        value: 'Type/Style, ABV, IBU',
      },
      {
        name: 'Row #3',
        value: 'Serving Sizes & Pricing',
      },
    ],
    type: 'bar',
  },
  {
    id: 3,
    rows: [
      {
        name: 'Row #1',
        value: 'Beverage Name',
      },
      {
        name: 'Row #2',
        value: 'Type/Style, ABV, IBU',
      },
      {
        name: 'Row #3',
        value: 'Keywords / Ingredients',
      },
      {
        name: 'Row #4',
        value: 'Serving Sizes & Pricing',
      },
    ],
    type: 'brewery',
  },
  {
    id: 4,
    rows: [
      {
        name: 'Row #1',
        value: 'Beverage Name',
      },
      {
        name: 'Row #2',
        value: 'Producer Name',
      },
      {
        name: 'Row #3',
        value: 'Type/Style, ABV, IBU',
      },
      {
        name: 'Stacked',
        value: 'Serving Sizes & Pricing',
      },
    ],
    type: 'bar',
  },
  {
    id: 5,
    rows: [
      {
        name: 'Row #1',
        value: 'Beverage Name',
      },
      {
        name: 'Row #2',
        value: 'Type/Style, ABV, IBU',
      },
      {
        name: 'Row #3',
        value: 'Keywords / Ingredients',
      },
      {
        name: 'Stacked',
        value: 'Serving Sizes & Pricing',
      },
    ],
    type: 'brewery',
  },
  {
    id: 6,
    rows: [
      {
        name: 'Row #1',
        value: 'Beverage Name',
      },
      {
        name: 'Row #2',
        value: 'Producer Name',
      },
      {
        name: 'Row #3',
        value: 'Type/Style, ABV, IBU',
      },
      {
        name: 'Row #4',
        value: 'Keywords / Ingredients',
      },
      {
        name: 'Stacked',
        value: 'Serving Sizes & Pricing',
      },
    ],
    type: 'bar',
  },
  {
    id: 7,
    rows: [
      {
        name: 'Row #1',
        value: 'Beverage Name',
      },
      {
        name: 'Row #2',
        value: 'Producer Name',
      },
      {
        name: 'Row #3',
        value: 'Type/Style, ABV, IBU',
      },
      {
        name: 'Row #4',
        value: 'Serving Sizes & Pricing',
      },
    ],
    type: 'bar',
  },
  {
    id: 8,
    rows: [
      {
        name: 'Row #1',
        value: 'Beverage Name',
      },
      {
        name: 'Row #2',
        value: 'Producer Name',
      },
      {
        name: 'Row #3',
        value: 'Type/Style, ABV, IBU',
      },
    ],
    type: 'bar',
  },
];

export default menuTemplates;
