const MuiInputAdornment = {
  styleOverrides: {
    filled: {
      '&.MuiInputAdornment-positionStart': {
        marginTop: '0!important',
        color: 'white',
      },

      '&.MuiInputAdornment-positionEnd': {
        color: 'white !important',
      },
    },
  },
};

export default MuiInputAdornment;
