const MuiAccordion = {
  styleOverrides: {
    root: {
      margin: 0,
      '&.Mui-expanded': {
        margin: 0,
      },
      '&:before': {
        display: 'none',
      },
    },
  },
};

export default MuiAccordion;
