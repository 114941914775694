const MuiGrid = {
  styleOverrides: {
    root: {
      '& .MuiFormControl-root': {
        marginBottom: 0,
      },
    },
  },
};

export default MuiGrid;
