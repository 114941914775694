import { alpha } from '@mui/material/styles';
import palette from 'theme/palette';

const MuiSelect = {
  styleOverrides: {
    root: {
      '&.Mui-disabled, &.Mui-disabled:hover': {
        color: alpha(palette.white, 0.4),
        cursor: 'not-allowed',

        '& svg': {
          color: alpha(palette.white, 0.4),
        },
      },
    },
    iconOutlined: {
      color: 'inherit',
    },
    iconFilled: {
      color: 'inherit',
    },
    select: {
      '& span': {
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '20px',
      },
    },
  },
};

export default MuiSelect;
