import { types } from 'mobx-state-tree';

export const LineTap = types.model({
  id: types.integer,
  line_id: types.integer,
  tap_id: types.integer,
  connected_from: types.string,
  connected_to: types.maybeNull(types.string),
  // connected: types.Date,
  archived: types.boolean,
});
