import { Box, Button, DialogActions, DialogContent, Divider } from '@mui/material';
import React from 'react';
import { DynamicInput } from '../../../DynamicInput';
import { styled } from '@mui/system';

const OperatingHours = ({
  title,
  handleDialogClose,
  valueState,
  handleTimeChangeStart,
  handleTimeChangeEnd,
}) => {
  return (
    <>
      <StyledDialogContent>
        <Box className={'title'}>{title}</Box>
        <DynamicInput
          title={'Open'}
          type={'time'}
          name={'from'}
          valueState={valueState}
          nested
          handleChange={handleTimeChangeStart}
        />
        <Divider sx={{ margin: 0 }} />
        <DynamicInput
          title={'Close'}
          type={'time'}
          name={'to'}
          valueState={valueState}
          nested
          handleChange={handleTimeChangeEnd}
        />
        <Divider sx={{ margin: 0 }} />
      </StyledDialogContent>
      <DialogActions>
        <Button
          variant={'contained'}
          fullWidth={true}
          onClick={handleDialogClose}
          sx={{ borderRadius: '36px' }}
        >
          Save
        </Button>
      </DialogActions>
    </>
  );
};

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  '.title': {
    fontSize: '11px',
    fontWeight: 500,
    letterSpacing: '0.75px',
    color: theme.palette.whiteEmphasis.high,
    marginBottom: '8px',
    display: 'flex',
    justifyContent: 'center',
  },
}));

export { OperatingHours };
