import palette from 'theme/palette';

const MuiSwitch = {
  styleOverrides: {
    root: {
      // disabled background around Switch on hover
      '&.MuiSwitch-sizeLarge': {
        borderRadius: '',
        padding: '6px',
        width: '68px',

        '.MuiSwitch-colorPrimary': {
          color: 'white',
        },
        '.Mui-checked': {
          transform: 'translateX(30px)',
          '&+.MuiSwitch-track ': {
            border: 0,
          },
        },
        '.MuiSwitch-track': {
          border: `1px solid ${palette.background.overlay6}`,
          borderRadius: '13px',
        },
      },
      '&:hover .MuiIconButton-root .MuiTouchRipple-root': {
        backgroundColor: 'transparent',
      },
      '.MuiSwitch-colorPrimary': {
        color: 'white',
      },
      '.MuiSwitch-track': {
        // border: '1px solid #666',
        borderRadius: '13px',
      },
    },
    colorPrimary: {
      '& + $track': {
        backgroundColor: '#666',
        opacity: 1,
      },
      '&$checked': {
        color: palette.white,
        '& + $track': {
          opacity: 0.7,
        },
      },
      '&$disabled': {
        color: '#535353',
        '& + $track': {
          opacity: 0.7,
          backgroundColor: '#414141',
        },
      },
    },
  },
};

export default MuiSwitch;
