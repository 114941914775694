const MuiListItemText = {
  styleOverrides: {
    root: {
      marginTop: 0,
      marginBottom: 0,
    },
    primary: {
      color: 'inherit',
    },
  },
};

export default MuiListItemText;
