import { types } from 'mobx-state-tree';

export const ItemEvent = types.model({
  id: types.union(types.string, types.integer),
  // collection_schema: types.string,
  // collection_name: types.string,
  // collection_element_id: types.integer,
  // change_type: types.enumeration('change_type', 'C', 'R', 'U', 'D'),
  changed_at: types.maybeNull(types.string),
  changed_by: types.maybeNull(types.integer),
  // changed_keys: ['status_code', 'updated_at'],
  change_before: types.maybeNull(types.map(types.frozen())),
  change_after: types.maybeNull(types.map(types.frozen())),
  operation: types.maybeNull(types.string),
  _beverages_name: types.maybeNull(types.string),
  _users_display_name_changed_by: types.maybeNull(types.string),
});
