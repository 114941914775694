import { useCallback, useMemo, useState } from 'react';
import { useStore, useToast } from 'hooks';

const useInventoryData = () => {
  const { itemsStore, beveragesStore, containersStore, skuStore } = useStore();
  const { errorToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const loadData = useCallback(async () => {
    try {
      setIsLoading(true);
      await Promise.all([
        skuStore.fetchSKUs(),
        containersStore.fetch(),
        itemsStore.fetchItems(),
        beveragesStore.fetchBeverages(),
      ]);
    } catch (err) {
      errorToast(err);
    } finally {
      setIsLoading(false);
    }
  }, [beveragesStore, containersStore, errorToast, itemsStore, skuStore]);

  const isLoaded = useMemo(() => {
    return (
      containersStore.isLoaded &&
      beveragesStore.isLoaded &&
      itemsStore.isLoaded &&
      skuStore.isLoaded
    );
  }, [containersStore.isLoaded, beveragesStore.isLoaded, itemsStore.isLoaded, skuStore.isLoaded]);

  return { isLoading, isLoaded, loadData };
};
export default useInventoryData;
