import React, { useContext, useEffect, useRef } from 'react';
import { Drawer as MuiDrawer } from '@mui/material';
import { observer } from 'mobx-react';
import { Box } from '@mui/material';
import { DrawerContext } from 'context/drawer';

const Drawer = () => {
  const ref = useRef();
  const drawerContext = useContext(DrawerContext);
  const { setRef, open, options, setOpen } = drawerContext;

  useEffect(() => {
    setRef(ref);
  }, [ref, setRef]);

  return (
    <MuiDrawer
      sx={{ flexGrow: 1 }}
      classes={{
        paper: {
          backgroundColor: '#2F2D2D',
          boxShadow: '-6px 0px 8px rgba(0, 0, 0, 0.25)',
          padding: '15px 0',
        },
      }}
      open={open}
      anchor={options.anchor}
      onClose={() => setOpen(false)}
      keepMounted
    >
      <Box ref={ref} />
    </MuiDrawer>
  );
};

export default observer(Drawer);
