import React from 'react';
import { observer } from 'mobx-react';

import UpdateToolbarPlaceholder from 'components/shared/NotificationBar/UpdateToolbarPlaceholder';
import NoInternetPlaceholder from 'components/shared/NotificationBar/NoInternetPlaceholder';
import { useStore } from 'hooks';

const NotificationBar = () => {
  const { ui } = useStore();

  return (
    <>
      {ui.isUpdatedAvailable && <UpdateToolbarPlaceholder />}
      {ui.isNoInternet && <NoInternetPlaceholder />}
    </>
  );
};

export default observer(NotificationBar);
