import { alpha } from '@mui/material/styles';
import palette from 'theme/palette';

const MuiFilledInput = {
  styleOverrides: {
    root: {
      color: palette.white,
      borderRadius: 3,
      transition: '0.3s',
      backgroundColor: alpha('#EFEFEF', 0.1),
      height: 34,
      textAlign: 'center',
      '&.Mui-focused': {
        backgroundColor: alpha('#EFEFEF', 0.1),
      },
      '&:hover, &.Mui-focused:hover': {
        backgroundColor: alpha('#EFEFEF', 0.2),
      },
      '&.Mui-disabled, &.Mui-disabled:hover': {
        backgroundColor: alpha(palette.white, 0.1),
      },
      '@media (hover: none)': {
        '&:hover': {
          backgroundColor: `${alpha('#EFEFEF', 0.1)} !important`,
        },
        '&.Mui-focused:hover': {
          backgroundColor: `${alpha('#EFEFEF', 0.2)} !important`,
        },
      },
      '&.number': {
        color: palette.black,
        backgroundColor: palette.secondary.lightest,
        width: 45,
        height: 45,
        fontSize: 22,
        '@media(max-width: 599px)': {
          fontSize: 18,
        },
        fontWeight: 500,
        margin: '0 5px',
        '& input': {
          textAlign: 'center',
          padding: 0,
        },
      },
      '&.square-black': {
        display: 'flex',
        justifyContent: 'center',
        paddingLeft: 10,
        paddingRight: 0,
        color: palette.white,
        backgroundColor: alpha(palette.white, 0.03),
        borderRadius: 10,
        margin: 0,
        fontWeight: 500,
        width: 75,
        height: 75,
        fontSize: 22,
        '@media(max-width: 1023px), (max-height: 765px)': {
          paddingLeft: 8,
          fontSize: 18,
          width: 55,
          height: 55,
        },
        '& input': {
          textAlign: 'center',
          padding: 0,
          width: 32,
        },
      },
    },
    input: {
      padding: '6px 12px',
    },
    underline: {
      '&::before, &::after': {
        visibility: 'hidden',
      },
    },
  },
};

export default MuiFilledInput;
