import { alpha } from '@mui/material/styles';
import palette from 'theme/palette';

const MuiIconButton = {
  styleOverrides: {
    root: {
      color: palette.white,
      padding: 3,
      '&.Mui-disabled': {
        opacity: 0.4,
        color: 'inherit',
        pointerEvents: 'auto',
      },
      '& .MuiTouchRipple-root': {
        transition: '0.3s',
      },
      '&:hover': {
        '& .MuiTouchRipple-root': {
          backgroundColor: alpha(palette.white, 0.3),
        },
      },
      '&.active': {
        color: `${palette.primary.main} !important`,
      },
      '&.confirm': {
        '&:focus, &:hover': {
          color: palette.primary.main,
        },
      },
    },
    edgeStart: {
      marginLeft: -20,
      padding: 12,
    },
    edgeEnd: {
      marginRight: '-5px',
    },
  },
};

export default MuiIconButton;
