import React, { lazy, Suspense, useMemo } from 'react';
import { observer } from 'mobx-react';
import { styled } from '@mui/material';

const StyledRootBox = styled('div')(() => ({
  width: '100%',
  '.container': {
    background: 'transparent',
    boxShadow: 'none',
    borderRadius: 5,
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
    '.vertical &': {
      margin: '0 2vh 2vh',
    },
    '.horizontal &': {
      margin: '0 2vh 2vh',
    },
  },
  '.rightSide': {
    display: 'flex',
    alignItems: 'center',
  },
  '.image': {
    borderRadius: 5,
    width: 'auto',
  },
  '.tile-content': {
    width: '100%',
    height: '100%',
    display: 'flex',
    margin: '0 10px',
    flexDirection: 'column',
    justifyContent: 'top',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flexGrow: 1,
  },
  '& .iconContainer': {
    justifyContent: 'flex-end',
  },
}));

const Tile = ({ state, ...props }) => {
  const Template = useMemo(() => {
    if (!state.template) return;
    return lazy(() => import(`./templates/template_${state.template}/index.js`));
  }, [state.template]);

  return (
    <Suspense fallback={null}>
      <StyledRootBox>
        <Template state={state} {...props} />
      </StyledRootBox>
    </Suspense>
  );
};

export default observer(Tile);
