import React from 'react';
import { DateAddedIcon, DateDequeuedIcon, DateQueuedIcon, DateSavedIcon } from 'assets/icons';

// TODO: Remove; Refactor it in a such way that MST store won't use this icons; And move out this
export const eventsIcons = {
  Added: <DateAddedIcon />,
  Queued: <DateQueuedIcon />,
  Dequeued: <DateDequeuedIcon />,
  Saved: <DateSavedIcon />,
};

const statuses = {
  full: 0,
  saved: 2,
  tapped: 5,
  kicked: 7,
  sold: 11,
  expired: 12,
  empty: 13,
  mistake: 14,
  infected: 15,
  0: 'full',
  2: 'saved',
  5: 'tapped',
  7: 'kicked',
  11: 'sold',
  12: 'expired',
  13: 'empty',
  14: 'mistake',
  15: 'infected',
};

export const historyStatuses = [
  {
    label: 'Kicked',
    value: 7,
  },
  {
    label: 'Sold',
    value: 11,
  },
  {
    label: 'Mistake',
    value: 14,
  },
  {
    label: 'Infected',
    value: 15,
  },
  {
    label: 'Expired',
    value: 12,
  },
  {
    label: 'Empty',
    value: 13,
  },
  {
    label: 'Tapped',
    value: 5,
  },
];

export default statuses;
