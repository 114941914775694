const MuiPaper = {
  styleOverrides: {
    root: {
      backgroundImage: 'unset',
      '& button > .PrivatePickersToolbarText-root': {
        marginBottom: '-10px',
      },
    },
    rounded: {
      borderRadius: 3,
      '&.MuiCard-root': {
        borderRadius: 5,
      },
    },
  },
};

export default MuiPaper;
