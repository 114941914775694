const MuiExpansionPanel = {
  styleOverrides: {
    root: {
      margin: 0,
      '&$expanded': {
        margin: 0,
      },
    },
  },
};

export default MuiExpansionPanel;
