import React, { forwardRef } from 'react';
import { NavLink } from 'react-router-dom';
import { ListItem, ListItemIcon, ListItemText, Button, styled, alpha } from '@mui/material';
import clsx from 'clsx';

import { useStore } from 'hooks';

const MenuItem = item => {
  const { name, icon, path, hasDivider, isLast, isBottom, isButton, mobileVisibility } = item;
  const { ui } = useStore();

  const ListItemComponent = forwardRef((props, ref) =>
    !isButton ? (
      <NavLink ref={ref} to={path} {...props} />
    ) : (
      <Button ref={ref} {...props} onClick={() => item.action()} />
    ),
  );

  return (
    <StyledRootLi
      className={clsx({
        itemCollapsed: !ui.isOpenedMenu,
        isDivided: hasDivider,
        'last-divided': isLast,
        bottom: isBottom,
        'mobile-visible': mobileVisibility,
      })}
    >
      <ListItem
        button
        component={ListItemComponent}
        className={clsx('link', {
          linkCollapsed: !ui.isOpenedMenu,
        })}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText className="linkText" primary={name} />
      </ListItem>
    </StyledRootLi>
  );
};

export default MenuItem;

const StyledRootLi = styled('li')(({ theme: { palette, breakpoints } }) => ({
  marginBottom: 5,
  [breakpoints.up('sm')]: {
    paddingRight: 10,
    '&.mobile-visible': {
      display: 'none',
    },
    '@media(min-height: 640px)': {
      '&.bottom': {
        marginTop: 'auto',
      },
    },
  },

  '&.itemCollapsed': {
    paddingRight: 0,
  },
  '&.isDivided': {
    borderBottom: `1px solid ${palette.background.overlay5}`,
    paddingBottom: 10,
    marginBottom: 10,
    '@media(min-height: 640px) and (min-width: 1024px)': {
      '&.last-divided': {
        borderBottom: 'none',
      },
    },
  },
  '.link': {
    height: 43,
    transition: '.4s',
    borderRadius: 0,
    padding: '0 16px',

    '&.active': {
      color: palette.primary.main,
      background: alpha(palette.white, 0.1),
      '& .MuiTouchRipple-child': {
        backgroundColor: alpha(palette.white, 0.5),
      },
    },

    '& .MuiButton-label': {
      '& .MuiTouchRipple-root': {
        display: 'none',
      },
    },

    '& .MuiTouchRipple-child': {
      backgroundColor: 'transparent',
      backgroundImage: `linear-gradient(to right, transparent 0%, ${alpha(
        palette.white,
        0.3,
      )} 10%, ${palette.white} 50%, ${alpha(palette.white, 0.3)} 90%, transparent 100%)`,
    },

    '&.MuiButton-text': {
      '&:hover, &:hover:focus': {
        backgroundColor: 'transparent',
      },
    },

    [breakpoints.up('sm')]: {
      borderBottomRightRadius: '19px !important',
      borderTopRightRadius: '19px !important',
      '&, &.MuiButton-text': {
        backgroundSize: '200% auto',
        backgroundPosition: 'right center',
        backgroundColor: 'transparent',
        backgroundImage: `linear-gradient(to right, ${alpha(palette.white, 0.1)} 0%, ${alpha(
          palette.white,
          0.1,
        )} 37%, transparent 50%, transparent 100%)`,
        '&:hover': {
          backgroundPosition: 'left center',
        },
      },
    },
  },
  '.linkCollapsed': {
    '&.active': {
      background: 'none',
    },
    [breakpoints.up('sm')]: {
      borderRadius: 0,
    },
  },
  '.linkText': {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    '& span': {
      fontSize: '18px',
      textTransform: 'capitalize',
      [breakpoints.up('md')]: {
        fontSize: '16px',
      },
    },
  },
}));
