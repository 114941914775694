import yup from 'services/yup';

import { phonePattern } from 'config/patterns';

export const schema = yup.object().shape({
  first_name: yup.string().trim().required('First Name is required'),
  last_name: yup.string().trim().required('Last Name is required'),
  phone_number: yup
    .string()
    .trim()
    .typeError('Phone is required')
    .required('Phone is required')
    .test('is-phone', 'Invalid Phone', value => (value + '').match(phonePattern)),
});
