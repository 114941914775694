import { types, flow } from 'mobx-state-tree';
import { sortBy, groupBy, uniqBy } from 'lodash';

import api from 'services/API';
import { getRootStore } from 'models/root';
import { normalize } from 'utils/diacriticNormalizer';

import {
  Alarm,
  BRU,
  Gateway,
  LineSensor,
  Sensor,
  TemperatureSensor,
  EstablishmentSensors,
} from 'models/types';

export const topologyManagementInitialState = {
  isLoaded: false,
  gateways: [],
  state: 'done',
  updated: null,
  alarms: [],
  brus: [],
  sensors: [],
  temperatureSensors: [],
  lineSensors: [],
  isMultiSelect: false,
  selectedLineIds: [],
  establishments_sensors: null,
  searchString: '',
  filters: {
    sensorsWithoutLines: false,
    orderBy: 'asc',
    excludedCoolersIds: [],
    excludedGatewaysIds: [],
    excludedBRUsIds: [],
    linesWithoutGeometry: false,
    selectedLineIDs: [],
  },
};

export const topologyManagementModel = types
  .model({
    isLoaded: types.boolean,
    state: types.enumeration('state', ['done', 'pending', 'error']),
    updated: types.maybeNull(types.Date),
    alarms: types.array(Alarm),
    gateways: types.array(Gateway),
    brus: types.array(BRU),
    sensors: types.array(Sensor),
    temperatureSensors: types.array(TemperatureSensor),
    lineSensors: types.array(LineSensor),
    isMultiSelect: types.boolean,
    selectedLineIds: types.array(types.number),
    establishments_sensors: types.maybeNull(EstablishmentSensors),
    searchString: types.maybeNull(types.string),
    filters: types.maybeNull(
      types.model({
        excludedCoolersIds: types.array(types.number),
        excludedGatewaysIds: types.array(types.number),
        excludedBRUsIds: types.array(types.number),
        orderBy: types.enumeration('orderBy', ['asc', 'desc']),
        sensorsWithoutLines: types.boolean,
        linesWithoutGeometry: types.boolean,
        selectedLineIDs: types.array(types.number),
      }),
    ),
  })
  .views(self => ({
    get sortedGateways() {
      const grouped = groupBy(self.gateways, 'cooler_id');
      const result = [];
      Object.values(grouped).forEach(items => {
        if (items.length > 1) {
          const filtered = items.filter(item => item.type_id !== 0);
          result.push(...filtered);
        } else {
          result.push(...items);
        }
      });

      return sortBy(result, '_coolers_name');
    },

    get subscribedAlarmsIds() {
      const root = getRootStore();
      const roleUserId = root.userStore.profile.currentEstablishmentRole._roleuser_id;
      return self.alarms
        .filter(({ role_users_ids }) => role_users_ids?.includes(roleUserId))
        .map(({ id }) => id);
    },

    get mappedEquipment() {
      const root = getRootStore();

      const mergedGateways = self.establishments_sensors?.gateways.map(gateway => {
        const mergedBru =
          gateway.brus?.map(bru => {
            const mergedSensor =
              bru?.sensors
                ?.map(sensor => {
                  const lineSensor = self.lineSensors.find(ls => ls.sensor_id === sensor.sensor_id);

                  return {
                    ...sensor,
                    ...self.sensors.find(s => s.id === sensor.sensor_id),
                    line: lineSensor
                      ? root.linesStore.lines.find(line => line.id === lineSensor.line_id)
                      : null,
                  };
                })
                .sort((a, b) => a.bru_sensor_address - b.bru_sensor_address) || [];

            return {
              ...bru,
              ...self.brus?.find(b => b.id === bru.bru_id),
              sensors: uniqBy(mergedSensor, 'sensor_id'),
            };
          }) || [];

        const mergedTempSensors = self.temperatureSensors
          .filter(ts => ts._gateways_id === gateway.gateway_id)
          .map(sensor => {
            return gateway?.temp_sensors?.length
              ? {
                  ...sensor,
                  ...gateway.temp_sensors.find(s => s.id === sensor.id),
                }
              : { ...sensor };
          });

        const mergedGateway = {
          ...self.gateways.find(g => g.id === gateway.gateway_id),
          ...gateway,
          brus: mergedBru,
          sensors_count: mergedBru
            ?.map(({ sensors }) => sensors.length)
            .reduce((sum, curr) => sum + curr, 0),
          sensors: mergedTempSensors,
        };

        return mergedGateway || [];
      });

      return root.coolersStore.all.map(cooler => {
        const gateways = groupBy(mergedGateways, 'cooler_id')[cooler.id] || [];
        return {
          ...cooler,
          gateways: gateways.map(g => ({
            ...g,
            gw_type: g.brus?.length ? 'bru' : g.sensors?.length ? 'temperature' : 'empty',
          })),
        };
      });
    },

    get filteredEquipment() {
      let result = self.mappedEquipment || [];

      if (self.searchString) {
        const searchArray = self.searchString.toLowerCase().split(' ');

        result = result
          .map(cooler => ({
            ...cooler,
            gateways: cooler.gateways
              .map(gateway => ({
                ...gateway,
                brus: gateway.brus
                  .map(bru => ({
                    ...bru,
                    sensors: bru.sensors.filter(sensor => {
                      if (!sensor.line?.item?._beverages_name) return false;

                      const every = searchArray.every(
                        element =>
                          normalize(sensor.line?.item?.beverage?._name)
                            .toLowerCase()
                            .includes(element) ||
                          normalize(sensor.line?.item?.beverage?._producers_name)
                            .toLowerCase()
                            .includes(element),
                      );

                      return every;
                    }),
                  }))
                  .filter(({ sensors }) => !!sensors.length),
              }))
              .filter(({ brus }) => !!brus.length),
          }))
          .filter(({ gateways }) => !!gateways.length);
      }

      result = result.filter(({ id }) => !self.filters.excludedCoolersIds.includes(id));

      result = result
        .map(cooler => {
          const filteredGateways = cooler.gateways
            .map(gateway => ({
              ...gateway,
              brus: gateway?.brus?.filter(({ id }) => !self.filters.excludedBRUsIds.includes(id)),
            }))
            .filter(({ id }) => !self.filters.excludedGatewaysIds.includes(id));

          return {
            ...cooler,
            // gateways: filteredGateways,
            gateways: filteredGateways.map(g => ({
              ...g,
              gw_type: g.brus?.length ? 'bru' : g.sensors?.length ? 'temperature' : 'empty',
            })),
          };
        })
        .sort((a, b) => a.name.localeCompare(b.name));

      return self.filters.orderBy === 'asc' ? result : result.reverse();
    },

    get unmappedEquipment() {
      return self.gateways.filter(gw => !gw.cooler_id);
    },

    get mappedLines() {
      if (!self.gateways.length) return [];

      const root = getRootStore();
      const allLines = root.linesStore.all.map(e => e);
      const mappedLinesIds = self.lineSensors.map(ls => ls.line_id);
      return allLines.filter(line => mappedLinesIds.includes(line.id));
    },

    get isDefaultFilters() {
      return (
        self.filters.orderBy === 'asc' &&
        self.filters.excludedCoolersIds.length === 0 &&
        self.filters.excludedGatewaysIds.length === 0 &&
        self.filters.excludedBRUsIds.length === 0 &&
        self.filters.selectedLineIDs.length === 0 &&
        !self.filters.sensorsWithoutLines &&
        !self.filters.linesWithoutGeometry
      );
    },

    get filteredLinesGroupedByBRU() {
      const root = getRootStore();
      return self.brus
        .filter(({ enumerated }) => enumerated)
        .sort((a, b) => a.gateway_bru_address - b.gateway_bru_address)
        .map(bru => {
          let lines = self.sensors
            .filter(sensor => sensor.bru_id === bru.id)
            .map(
              sensor =>
                root.linesStore.getLineById(
                  self.lineSensors.find(({ sensor_id }) => sensor.id === sensor_id)?.line_id,
                ) || {},
            );

          if (self.filters) {
            if (self.filters.linesWithoutGeometry) {
              lines = lines.filter(line => !line.geometries);
            }

            if (self.filters.sensorsWithoutLines) {
              lines = lines.filter(line => Object.keys(line).length === 0);
            }

            if (self.filters.selectedLineIDs.length) {
              lines = lines.filter(line => self.filters.selectedLineIDs.includes(line.id));
            }

            if (self.searchString) {
              lines = lines.filter(line => {
                if (!line.beverage) return false;

                return self.searchString
                  .toLowerCase()
                  .split(' ')
                  .every(
                    element =>
                      normalize(line.beverage._name).toLowerCase().includes(element) ||
                      normalize(line.beverage._producers_name).toLowerCase().includes(element),
                  );
              });
            }
          }

          lines = lines.sort((a, b) => a?.sort_value - b?.sort_value);

          return {
            bru,
            lines,
            gateway: self.gateways.find(({ id }) => bru.gateway_id === id),
          };
        });
    },

    get selectedSensor() {
      if (!self.selectedLineIds.length) return null;
      const lineSensor = self.lineSensors.find(({ line_id }) =>
        self.selectedLineIds.includes(line_id),
      );

      return self.mappedEquipment
        .flatMap(({ gateways }) =>
          gateways.flatMap(({ brus }) => brus.flatMap(({ sensors }) => sensors)),
        )
        .find(({ id }) => lineSensor.sensor_id === id);
    },

    get hasMappedGateways() {
      return Boolean(self.gateways.filter(gw => !!gw.cooler_id)?.length);
    },
  }))
  .actions(self => ({
    patchGateway: flow(function* (id, body) {
      try {
        const response = yield api.patchGateways(body, id);
        const updatedGateway = response.data?.row;

        self.gateways.replace([
          ...self.gateways.filter(e => e.id !== updatedGateway.id),
          updatedGateway,
        ]);
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    }),

    patchAlert: flow(function* (body, params) {
      try {
        const response = yield api.patchAlerts(body, params);

        const updatedAlarms = response.data.result;
        const updatedIds = updatedAlarms.map(({ id }) => id);

        self.alarms = [
          ...self.alarms.filter(({ id }) => !updatedIds.includes(id)),
          ...updatedAlarms,
        ];
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    }),

    disconnectLinesSensors: flow(function* (line_ids) {
      try {
        line_ids.map(line_id =>
          api.disconnectCurrentItem({
            line_id,
            item_status_code: 2,
          }),
        );
        const response = yield api.disconnectCurrentSensors(
          line_ids.map(line_id => ({
            line_id,
          })),
        );

        if (response?.data?.result) {
          const updated = response?.data?.result?.disconnected._line_sensor;
          const updatedIds = updated.map(({ id }) => id);
          self.lineSensors.replace(self.lineSensors.filter(e => !updatedIds.includes(e.id)));

          return self.lineSensors;
        }
      } catch (err) {
        return Promise.reject(err);
      }
    }),

    connectNewLineToSensor: flow(function* (sensor_id, body) {
      try {
        const root = getRootStore();
        const newLineResponse = yield api.createLine(body);

        const newTapResponse = yield api.createTap({
          establishment_id: body.establishment_id,
          identifier: `Tap-${body.sort_value}`,
          status_code: 0,
          archived: false,
        });

        const connectLineTapResponse = yield api.connectNewLineTap([
          {
            line_id: newLineResponse?.data?.id,
            tap_id: newTapResponse?.data?.id,
          },
        ]);

        root.lineTapsStore.setLineTaps([
          ...root.lineTapsStore.lineTaps,
          ...connectLineTapResponse.data?.result?.connected?._line_taps,
        ]);

        if (newLineResponse?.data?.row) {
          root.linesStore.setLines([
            ...root.linesStore.all.map(e => e),
            newLineResponse?.data?.row,
          ]);
        }

        const connectLineSensorResponse = yield api.connectNewLineSensor({
          line_id: newLineResponse?.data?.id,
          sensor_id: sensor_id,
        });

        if (connectLineSensorResponse.data?.result?.connected?._line_sensors) {
          self.lineSensors.push(
            connectLineSensorResponse.data?.result?.connected?._line_sensors[0],
          );
        }

        return newLineResponse?.data?.row;
      } catch (err) {
        return Promise.reject(err);
      }
    }),

    getCoolerIdByGatewayId(id) {
      return self.gateways.find(g => g.id === id)?.cooler_id;
    },

    getUsedIdentifiersByGatewayId(id) {
      const coolerId = self.gateways.find(g => g.id === id)?.cooler_id;
      const cooler = self.mappedEquipment.find(cooler => cooler.id === coolerId);

      if (!id || !coolerId) return [];

      return cooler?.gateways
        ?.flatMap(g => g?.brus?.flatMap(({ sensors }) => sensors))
        .filter(sensor => Boolean(sensor?.line))
        .map(({ id, line: { identifiers } }) => ({
          id,
          lineId: identifiers.line.numerical,
          tapId: identifiers.taps[0].numerical,
        }));
    },

    getFilteredItemsByKey(key) {
      switch (key) {
        case 'cooler':
          return self.filteredEquipment;
        case 'gateway':
          return self.filteredEquipment.flatMap(({ gateways }) => gateways);
        case 'bru':
          return self.filteredEquipment.flatMap(({ gateways }) =>
            gateways.flatMap(({ brus }) => brus),
          );
        default:
          return [];
      }
    },

    setAlarms(rows) {
      self.alarms.replace(rows);
    },
    setBRUs(rows) {
      self.brus.replace(rows);
    },
    setGateways(rows) {
      self.gateways.replace(rows);
    },
    setSensors(rows) {
      self.sensors.replace(rows);
    },

    setTemperatureSensors(rows) {
      self.temperatureSensors.replace(rows);
    },
    setLineSensors(rows) {
      // no connected_to date or it in the future
      const filtered = rows.filter(
        ({ connected_to }) => !connected_to || Date.parse(connected_to) > Date.now(),
      );

      self.lineSensors.replace(filtered);
    },
    setMultiSelect(flag) {
      self.isMultiSelect = flag;
    },
    setSelectedLines(ids) {
      self.selectedLineIds = ids;
    },

    setEstablishmentSensors(rows) {
      self.establishments_sensors = rows;
    },

    setSearchString(value) {
      self.searchString = value;
    },
    setFilters(filters) {
      self.filters = {
        ...self.filters,
        ...filters,
      };
    },
    resetFilters() {
      self.filters = topologyManagementInitialState.filters;
    },
  }));
