import React, { useEffect, useState, lazy, Suspense } from 'react';

import { observer } from 'mobx-react';
import { useLocation, Outlet, useParams, useNavigate } from 'react-router-dom';
import Bowser from 'bowser';

import { useStore, useToast, useEstablishmentData } from 'hooks';
import { messages } from 'config/messages';

import BaseLayout from 'components/layouts/BaseLayout';
import MenuEditorLayout from 'components/layouts/MenuEditorLayout';

import MissingData from 'components/shared/MissingData';
import GlobalModals from 'components/@Root/GlobalModals';
import SentryErrorBoundary from 'components/shared/SentryErrorBoundary';
import { commonUtilities } from 'utils';

const GlassLoader = lazy(() =>
  commonUtilities.lazyRetry(() => import('components/@Root/GlassLoader')),
);

const isMobile = () => {
  if (navigator && navigator.userAgent) {
    const browser = Bowser.getParser(navigator.userAgent);
    const type = browser.getPlatformType();
    const platform = navigator.platform;
    return (
      type === 'mobile' ||
      type === 'tablet' ||
      platform === 'iPad' ||
      (navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 2 &&
        /MacIntel/.test(navigator.platform))
    );
  }
  return false;
};

const Root = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const { userStore, ui, socketsController } = useStore();
  const { errorToast, warningToast } = useToast();
  const [error, setError] = useState(null);

  const establishmentId = userStore.currentRole?._establishment_id;
  const { pathname } = location;

  useEffect(() => {
    socketsController.subscribe();
    return () => socketsController.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let path = pathname;
    Object.entries(params).forEach(([paramName, paramValue]) => {
      if (paramValue) {
        path = path.replace(paramValue, `:${paramName}`);
      }
    });

    if (path.includes('/:')) {
      navigate(path.split('/:')[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [establishmentId]);

  useEstablishmentData(
    () => 'Establishment data successfully loaded!',
    err => {
      if (err && err.response && err.response.status === 401) {
        errorToast(messages.network.ERROR_401);
      } else {
        warningToast(messages.common.ESTABLISHMENT_DATA_LOAD_ERROR);
      }

      if (err && err.code && err.code === 'ECONNABORTED') {
        setError('Cannot connect to server due to slow Internet connection.');
      }

      if (err && err.response && err.response.status >= 500) {
        setError('Cannot connect due to server issues. Try again later.');
      }
    },
    [establishmentId],
  );

  if (
    !userStore.profile.first_name ||
    !userStore.profile.last_name ||
    !userStore.profile.phone_number
  ) {
    ui.setModalVisibility('missingUserDetails', true);
  }

  useEffect(() => {
    const visibilityCallback = () => {
      if (document.visibilityState === 'visible') {
        if (window.location && window.location.reload) {
          window.location.reload();
        }
      }
    };

    if (isMobile()) {
      document.addEventListener('visibilitychange', visibilityCallback);
    }
    return () => document.removeEventListener('visibilitychange', visibilityCallback);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  if (ui.isDataLoading) {
    return (
      <Suspense fallback={null}>
        <GlassLoader />
      </Suspense>
    );
  }

  if (error) {
    return <MissingData onReload={() => window.location.reload()} title={error} withBackground />;
  }

  if (pathname.includes('menu-editor')) {
    return (
      <SentryErrorBoundary fallbackPage={{ path: '/digital-menu/menus', title: 'Digital Menu' }}>
        <MenuEditorLayout />
      </SentryErrorBoundary>
    );
  }

  return (
    <BaseLayout>
      <SentryErrorBoundary>
        <Outlet />
        <GlobalModals />
      </SentryErrorBoundary>
    </BaseLayout>
  );
};

export default observer(Root);
