import { useCallback, useMemo, useState } from 'react';
import { useStore, useToast } from 'hooks';

const useBarManagerSKUData = () => {
  const { errorToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const { skuMappingStore, skuStore, beveragesStore, itemsStore } = useStore();
  const loadData = useCallback(async () => {
    try {
      setIsLoading(true);
      await Promise.all([
        skuMappingStore.fetchPOSSKUs(),
        skuStore.fetchSKUs(),
        beveragesStore.fetchBeverages(),
        itemsStore.fetchItems(),
      ]);
    } catch (err) {
      errorToast(err);
    } finally {
      setIsLoading(false);
    }
  }, [beveragesStore, errorToast, itemsStore, skuMappingStore, skuStore]);

  const isLoaded = useMemo(() => {
    return (
      skuMappingStore.isLoaded &&
      skuStore.isLoaded &&
      beveragesStore.isLoaded &&
      itemsStore.isLoaded
    );
  }, [skuMappingStore.isLoaded, skuStore.isLoaded, beveragesStore.isLoaded, itemsStore.isLoaded]);

  return { isLoading, isLoaded, loadData };
};

export default useBarManagerSKUData;
