import { useCallback, useMemo, useState } from 'react';
import { useStore, useToast } from 'hooks';

const useQualityManagementData = () => {
  const {
    linesStore,
    itemsStore,
    establishmentStore,
    beveragesStore,
    lineTapsStore,
    containersStore,
    cleaningStatisticsStore,
    cleaningsStore,
  } = useStore();
  const { errorToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const loadData = useCallback(async () => {
    try {
      setIsLoading(true);
      await Promise.all([
        establishmentStore.fetch(true),
        beveragesStore.fetchBeverages(),
        containersStore.fetch(),
        itemsStore.fetchItems(),
        linesStore.fetch(),
        cleaningStatisticsStore.fetch(),
        lineTapsStore.fetchLineTaps(),
        cleaningsStore.fetchCleanings(),
      ]);
    } catch (err) {
      errorToast(err);
    } finally {
      setIsLoading(false);
    }
  }, [
    beveragesStore,
    cleaningsStore,
    containersStore,
    errorToast,
    establishmentStore,
    itemsStore,
    lineTapsStore,
    cleaningStatisticsStore,
    linesStore,
  ]);

  const isLoaded = useMemo(() => {
    return (
      establishmentStore.isLoaded &&
      beveragesStore.isLoaded &&
      containersStore.isLoaded &&
      itemsStore.isLoaded &&
      linesStore.isLoaded &&
      cleaningStatisticsStore.isLoaded &&
      lineTapsStore.isLoaded
    );
  }, [
    establishmentStore.isLoaded,
    beveragesStore.isLoaded,
    containersStore.isLoaded,
    itemsStore.isLoaded,
    linesStore.isLoaded,
    cleaningStatisticsStore.isLoaded,
    lineTapsStore.isLoaded,
  ]);

  return { isLoading, isLoaded, loadData };
};

export default useQualityManagementData;
