import React, { useState, useCallback } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Box,
  Typography,
  Checkbox,
  Divider,
  styled,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { observer } from 'mobx-react';

const DURATIONS = [60, 120, 180, 240, 300];

const Content = ({ state }) => {
  const [expanded, setExpanded] = useState(true);

  const handleExpansionChange = useCallback(() => {
    setExpanded(expanded => !expanded);
  }, []);

  return (
    <StyledRootAccordion expanded={expanded} onChange={handleExpansionChange}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className="heading">Content</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="h5" className="subheading">
          Beer Information
        </Typography>
        <Box display="flex" flexDirection="column" alignItems="start">
          <FormControlLabel
            control={
              <Checkbox
                checked={state.isProducerVisible}
                name="isProducerVisible"
                onChange={state.handleCheck}
                color="primary"
              />
            }
            label="Brewery Name"
          />

          {[4, 6, 8].includes(state.template) && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={[4, 6].includes(state.template) && state.isProducerLocationVisible}
                  onChange={state.handleCheck}
                  name="isProducerLocationVisible"
                  color="primary"
                />
              }
              label="Brewery Location"
            />
          )}
          <FormControlLabel
            control={
              <Checkbox
                checked={state.isTypeVisible}
                onChange={state.handleCheck}
                color="primary"
                name="isTypeVisible"
              />
            }
            label="Beer Type"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={state.isStyleVisible}
                onChange={state.handleCheck}
                color="primary"
                name="isStyleVisible"
              />
            }
            label="Beer Style"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={state.isABVVisible}
                onChange={state.handleCheck}
                color="primary"
                name="isABVVisible"
              />
            }
            label="ABV"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={state.isIBUVisible}
                onChange={state.handleCheck}
                color="primary"
                name="isIBUVisible"
              />
            }
            label="IBU"
          />
          {[3, 5, 6].includes(state.template) && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={[3, 5, 6].includes(state.template) && state.areKeywordsVisible}
                  onChange={state.handleCheck}
                  color="primary"
                  name="areKeywordsVisible"
                />
              }
              label="Keywords"
            />
          )}
          <FormControlLabel
            control={
              <Checkbox
                checked={state.isKegLevelVisible}
                onChange={state.handleCheck}
                color="primary"
                name="isKegLevelVisible"
              />
            }
            label="Keg Level"
          />
          {state.isKegLevelVisible && (
            <Box pl={2} pt={2} sx={{ width: '100%' }}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="pollingDuration">Update Frequency</InputLabel>
                <Select
                  labelId="pollingDuration"
                  id="pollingDuration"
                  value={state.pollingDuration}
                  onChange={state.handleChange}
                  label="Update Frequency"
                  name="pollingDuration"
                >
                  {DURATIONS.map((val, index) => (
                    <MenuItem key={index} value={val}>
                      {val} seconds
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
        </Box>
        <Divider />
        <Typography variant="h5" className="subheading">
          Serving Sizes
        </Typography>
        <Box display="flex" flexDirection="column" alignItems="start">
          <FormControlLabel
            control={
              <Checkbox
                checked={state.areContainersVisible}
                onChange={state.handleCheck}
                color="primary"
                name="areContainersVisible"
              />
            }
            label="Containers"
          />
          <FormControlLabel
            disabled={!state.areContainersVisible}
            control={
              <Checkbox
                checked={state.isPriceVisible}
                onChange={state.handleCheck}
                color="primary"
                name="isPriceVisible"
              />
            }
            label="Price"
          />
          <Box display="flex" flexDirection="column" ml={2}>
            <FormControlLabel
              disabled={!state.areContainersVisible || !state.isPriceVisible}
              control={
                <Checkbox
                  checked={state.isCurrencyVisible && state.isPriceVisible}
                  onChange={state.handleCheck}
                  color="primary"
                  name="isCurrencyVisible"
                />
              }
              label="Currency"
            />
            <FormControlLabel
              disabled={!state.areContainersVisible || !state.isPriceVisible}
              control={
                <Checkbox
                  checked={state.roundNumbers && state.isPriceVisible}
                  onChange={state.handleCheck}
                  color="primary"
                  name="roundNumbers"
                />
              }
              label="Round Numbers"
            />
          </Box>
        </Box>
      </AccordionDetails>
      <Divider />
    </StyledRootAccordion>
  );
};

export default observer(Content);

const StyledRootAccordion = styled(Accordion)(({ theme }) => ({
  '.heading': {
    fontSize: theme.typography.pxToRem(22),
    fontWeight: theme.typography.fontWeightRegular,
    '& > .MuiIconButton-edgeEnd': {
      marginRight: '0 !important',
    },
  },
  '.subheading': {
    textDecoration: 'underline',
    marginBottom: theme.spacing(1),
  },
}));
