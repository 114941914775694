import { Box, Dialog, styled, Typography } from '@mui/material';
import palette from 'theme/palette';
import { ChevronRight } from '@mui/icons-material';
import React, { useCallback, useMemo, useState } from 'react';
import { MultiSectionDigitalClock } from '@mui/x-date-pickers';
import { SelectDialog } from './InputDialogs/selectDialog/SelectDialog';
import { TextDialog } from './InputDialogs/textdialog/TextDialog';
import { OperatingHours } from './InputDialogs/operatingHours/OperatingHours';
import { isValid } from 'date-fns';

const MobileView = ({
  type,
  disabled,
  title,
  name,
  values,
  currentValue,
  description,
  handleChange,
  valueState,
  stacked,
  handleTimeChangeStart,
  handleTimeChangeEnd,
  nested,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isFieldActive, setIsFieldActive] = useState(false);

  const handleClick = useCallback(() => {
    if (disabled) {
      return;
    }
    if (type === 'time') {
      setIsFieldActive(prevState => !prevState);
      return;
    }
    setIsDialogOpen(prevState => !prevState);
  }, [type, disabled]);

  const handleDialogClose = useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  const renderTitle = useMemo(() => {
    let result;
    switch (type) {
      case 'select':
      case 'time':
      case 'operatingHours':
        result = title;
        break;
      default:
        result = `${title} (%)`;
    }

    return result;
  }, [type, title]);

  const handleMobileSelectChoice = useCallback(
    event => {
      const currentValue = event.target.textContent;
      const element = values.find(e => e.label === currentValue);

      handleChange(element?.value, name);
      setIsDialogOpen(false);
    },
    [values, handleChange, name],
  );

  const dialog = useMemo(() => {
    let dialogType = type ?? 'text';
    const dialogTypes = new Map([
      [
        'select',
        <SelectDialog
          title={title}
          description={description}
          values={values}
          handleChange={handleMobileSelectChoice}
          currentValue={currentValue}
        />,
      ],
      [
        'text',
        <TextDialog
          valueState={valueState}
          handleChange={handleChange}
          name={name}
          title={title}
          description={description}
          handleDialogClose={handleDialogClose}
        />,
      ],
      [
        'operatingHours',
        <OperatingHours
          title={title}
          handleDialogClose={handleDialogClose}
          valueState={valueState}
          handleTimeChangeStart={handleTimeChangeStart}
          handleTimeChangeEnd={handleTimeChangeEnd}
        />,
      ],
    ]);

    return dialogTypes.get(dialogType);
  }, [
    type,
    handleDialogClose,
    title,
    description,
    name,
    valueState,
    handleChange,
    values,
    handleMobileSelectChoice,
    currentValue,
    handleTimeChangeStart,
    handleTimeChangeEnd,
  ]);

  const checkValidTime = useCallback(timeString => {
    if (!isValid(timeString)) {
      const [time, meridiem] = timeString.split(' ');
      const [hours, minutes] = time.split(':');

      let date = new Date();

      if (meridiem === 'PM' && hours !== '12') {
        date.setHours(parseInt(hours) + 12);
      } else if (meridiem === 'AM' && hours === '12') {
        date.setHours(0);
      } else {
        date.setHours(parseInt(hours));
      }

      date.setMinutes(parseInt(minutes));

      return date.getTime();
    }

    return timeString;
  }, []);

  return (
    <>
      <StyledRootBox type={type} nested={nested}>
        <Box className={'title'} onClick={handleClick}>
          <Box
            display={'flex'}
            alignItems={stacked ? 'start' : 'center'}
            flexDirection={stacked ? 'column' : 'row'}
            width={'100%'}
            justifyContent={'space-between'}
          >
            <Typography
              sx={{
                color: palette.whiteEmphasis.medium,
                marginBottom: stacked ? '4px' : '0',
              }}
              variant={'h8'}
            >
              {renderTitle}
            </Typography>
            <Typography
              variant={'h7'}
              sx={{ color: disabled ? palette.whiteEmphasis.medium : palette.whiteEmphasis.high }}
            >
              {currentValue}
            </Typography>
          </Box>
          <Box display={'flex'}>
            <ChevronRight
              sx={{
                color: disabled ? palette.whiteEmphasis.medium : palette.whiteEmphasis.high,
              }}
            />
          </Box>
        </Box>
      </StyledRootBox>
      {type === 'time' && isFieldActive === true && (
        <StyledTimePicker
          className={'timepicker'}
          value={checkValidTime(valueState[name])}
          onChange={handleChange}
        />
      )}
      {type !== 'time' && (
        <StyledDialog
          fullScreen
          open={isDialogOpen}
          onClose={handleDialogClose}
          className={'dialog'}
        >
          {dialog}
        </StyledDialog>
      )}
    </>
  );
};

const StyledRootBox = styled(Box)(({ theme, type, nested }) => ({
  margin: `0 ${!nested ? '16px' : '0px'}`,
  padding: '12px 0',
  borderBottom: type === 'time' ? '' : theme.palette.background.overlay3,
  [theme.breakpoints.up('md')]: {
    padding: '0px',
    borderBottom: 'none',
  },
  '.title': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '.MuiDialog-paper': {
    borderRadius: '16px 16px 0 0 ',
  },
  '.MuiDialogActions-root': {
    paddingBottom: 0,
  },
  '.MuiDialogContent-root': {
    paddingTop: 0,
  },
}));

const StyledTimePicker = styled(MultiSectionDigitalClock)(({ theme }) => ({
  borderRadius: '2px',
  display: 'grid',
  gridAutoFlow: 'column',
  gridTemplateColumns: 'repeat(3, min-content)',
  justifyContent: 'center',
  maxHeight: '150px',
  backgroundColor: theme.palette.secondary.dark,
  border: `4px solid ${theme.palette.secondary.main}`,

  '.MuiList-root': {
    height: '150px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflowY: 'scroll',
    scrollSnapType: 'y proximity',
    border: 0,
    scrollbarColor: 'transparent transparent',
  },
  '.MuiButtonBase-root': {
    scrollSnapAlign: 'center',
    height: 'fit-content',
    border: 0,
  },
}));

export { MobileView };
