import palette from '../../../../../../../theme/palette';
import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';

export const DynamicSelectInput = ({ title, values, valueState, name, handleChange, disabled }) => {
  return (
    <FormControl
      disabled={disabled}
      variant="outlined"
      sx={{
        marginBottom: '24px',
        '.MuiInputBase-root': {
          backgroundColor: palette.secondary.dark,
        },
        '&.MuiTextField-root': {
          marginBottom: 0,
        },
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: palette.outline.main,
        },
      }}
    >
      <InputLabel>{title}</InputLabel>
      <Select label={title} value={valueState[name] ?? 0} onChange={event => handleChange(event)}>
        {values.map(({ label, value }) => {
          return (
            <MenuItem
              key={value}
              sx={({ palette }) => ({
                borderBottom: `1px solid ${palette.background.overlay3}`,
              })}
              onClick={event => handleChange(event)}
              value={value}
            >
              <Typography variant={'h7'} color={palette.whiteEmphasis.high} value={value}>
                {label}
              </Typography>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
