import { types } from 'mobx-state-tree';

import { getRootStore } from 'models/root';
import { UserRole } from 'models/types/userRole';

const UserRoleWithViews = UserRole.views(self => ({
  get orderStatus() {
    const { usersStore } = getRootStore();
    if (Array.isArray(usersStore.allRoles) && usersStore.allRoles.length > 0) {
      const role = usersStore.allRoles.find(role => role.id === self._role_id);
      if (role && role._role_sort_value) {
        return role._role_sort_value || usersStore.allRoles.length + 1;
      }
      return usersStore.allRoles.length + 1;
    }
    return 0;
  },
}));

const AppleAuth = types.model({
  id: types.string,
});

const GoogleAuth = types.model({
  id: types.string,
  givenName: types.string,
  familyName: types.string,
});

const OneLoginAuth = types.model({
  id: types.optional(types.string, '', [null, undefined]),
  givenName: types.optional(types.string, '', [null, undefined]),
  familyName: types.optional(types.string, '', [null, undefined]),
});

const AuthInfo = types.model({
  apple: types.maybeNull(AppleAuth),
  google: types.maybeNull(GoogleAuth),
  onelogin: types.maybeNull(OneLoginAuth),
});

export const UserProfile = types.model({
  id: types.identifierNumber,
  first_name: types.optional(types.string, '', [null, undefined]),
  last_name: types.optional(types.string, '', [null, undefined]),
  phone_number: types.optional(types.string, '', [null, undefined]),
  archived: types.boolean,
  email: types.optional(types.string, '', [null, undefined]),
  verified: types.boolean,
  updated_at: types.maybeNull(types.string),
  updated_by: types.maybeNull(types.number),
  default_roleuser_id: types.maybeNull(types.number),
  auth_info: types.optional(AuthInfo, {}, [null, undefined, {}]),
  uuid: types.string,
  _bar_id: types.maybeNull(types.array(types.integer)),
  _roles: types.maybeNull(types.array(UserRoleWithViews)),
  _valid: types.maybeNull(types.number),
});
