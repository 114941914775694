import { flow, types } from 'mobx-state-tree';

import api from 'services/API';
import { commonUtilities } from 'utils';
import { getRootStore } from 'models/root';
import { Beverage } from 'models/types';
import { normalize } from 'utils/diacriticNormalizer';
import orderBy from 'lodash/orderBy';

const sortedByOptions = {
  beverageName: '_name',
  producerName: '_producers_name',
};

const orderByOptions = {
  ascending: 'asc',
  descending: 'desc',
};

export const beveragesInitialState = {
  all: [],
  state: 'done',
  error: '',
  page: 1,
  limit: 5,
  searchString: '',
  isLoaded: false,
  filters: {
    sortedBy: 'beverageName',
    orderBy: 'ascending',
    filterBy: 'showAll',
    hidden: 'excluded',
  },
};

const BeverageWithView = Beverage.views(self => ({
  get isTapped() {
    const root = getRootStore();
    return Boolean(root.linesStore.lines.find(line => line?.beverage?.id === self.id));
  },
}));

export const beveragesModel = types
  .model({
    all: types.array(BeverageWithView),
    state: types.enumeration('state', ['done', 'pending', 'error']),
    error: types.string,
    page: types.integer,
    limit: types.integer,
    searchString: types.string,
    isLoaded: types.boolean,
    filters: types.maybeNull(
      types.model({
        sortedBy: types.enumeration('sortedBy', ['beverageName', 'producerName']),
        orderBy: types.enumeration('orderBy', ['ascending', 'descending']),
        filterBy: types.enumeration('filterBy', ['showAll', 'onTap', 'inventory']),
        hidden: types.enumeration('hidden', ['included', 'excluded']),
      }),
    ),
  })
  .views(self => ({
    get beverages() {
      return self.all;
    },

    get allExcludingPrototypes() {
      return self.all.filter(beverage => beverage.prototype_beverage_id !== null);
    },

    get sortedBeverages() {
      return self.allExcludingPrototypes.filter(beverage => !beverage.hidden);
    },

    get hiddenBeverages() {
      return self.allExcludingPrototypes.filter(beverage => beverage.hidden);
    },

    get paginatedSortedBeverages() {
      if (self.searchString) {
        return self
          .filteredBySearch(self.sortedBeverages, self.searchString)
          .slice(0, self.page + self.limit);
      }

      return self.sortedBeverages;
    },

    get hasHiddenBeverages() {
      return self.hiddenBeverages.length > 0;
    },

    get hasMoreSortedBeverages() {
      return self.sortedBeverages.length > self.page * self.limit;
    },

    get filteredAndSortedBeverages() {
      const showHidden = self.filters.hidden === 'included';

      let beverages = self.allExcludingPrototypes.filter(
        beverage => beverage.hidden === showHidden,
      );

      if (self.filters.filterBy === 'onTap') {
        beverages = beverages.filter(item => item.isTapped);
      } else if (self.filters.filterBy === 'inventory') {
        beverages = beverages.filter(item => !item.isTapped);
      }

      if (self.searchString) {
        beverages = self.filteredBySearch(beverages, self.searchString);
      }

      const field = sortedByOptions[self.filters.sortedBy];
      const order = orderByOptions[self.filters.orderBy];

      return orderBy(beverages, [field], [order]);
    },

    getBeverageByID: id => {
      return self.all.find(beverage => beverage.id === Number(id));
    },

    get isDefaultFilters() {
      return (
        self.filters.sortedBy === 'beverageName' &&
        self.filters.orderBy === 'ascending' &&
        self.filters.filterBy === 'showAll' &&
        self.filters.hidden === 'excluded'
      );
    },

    isTappedById: id => {
      const root = getRootStore();
      return root.liesStore.lines.find(line => line.beverage.id === id);
    },
  }))
  .actions(self => {
    return {
      handleBeverageCopy(beverage = {}) {
        if (beverage.prototype_beverage_id !== null) {
          self.all.replace([...self.all, beverage]);
        }
      },

      handleCreatedBeverages(result = {}) {
        const { _beverages_added = [] } = result;
        const nonPrototypeBeverages = Array.isArray(_beverages_added)
          ? _beverages_added.filter(beverage => beverage.prototype_beverage_id !== null)
          : [];

        if (nonPrototypeBeverages.length > 0) {
          self.all.replace([...self.all, ...nonPrototypeBeverages]);
        }
      },

      handleBeverageUpdate(updatedBeverage = {}) {
        if (updatedBeverage.archived) {
          const beverage = self.all.find(beverage => beverage.id === updatedBeverage.id);
          self.all.remove(beverage);
        } else {
          self.all.replace(
            self.all.map(beverage =>
              beverage.id === updatedBeverage.id ? updatedBeverage : beverage,
            ),
          );
        }
      },

      handleBeverageDelete(removedElements) {
        if (!removedElements?.id && !Array.isArray(removedElements)) {
          return;
        } else if (!Array.isArray(removedElements)) {
          removedElements = [removedElements];
        }

        const updatedList = self.all.filter(
          existingEl => !removedElements.some(removedEl => removedEl.id === existingEl.id),
        );

        self.all.replace(updatedList);
      },

      fetchBeverages: flow(function* () {
        try {
          self.setState('pending');
          self.setErrorMessage('');
          const {
            userStore: { currentRole },
          } = getRootStore();
          yield self.getMyBeverages({
            establishment_id: currentRole._establishment_id,
          });
          self.setState('done');
          self.isLoaded = true;
        } catch (err) {
          self.setState('error');
          self.isLoaded = false;
          self.setErrorMessage((err && err.message) || ' Fetch data error.');
          console.error(err);
          return Promise.reject(err);
        }
      }),
      //actions never used
      getMyBeverages: flow(function* (params) {
        try {
          const response = yield api.getMyBeverages(params);
          if (commonUtilities.validateResponse(response)) {
            self.all.replace(response.data.result);
          } else {
            return Promise.reject(new Error("There aren't beverages for current items."));
          }
        } catch (err) {
          console.log(err);
          return Promise.reject(new Error('Get beverages error.'));
        }
      }),

      createBeverage: flow(function* (payload, handleConfirm) {
        try {
          self.setState('pending');
          const {
            userStore: { currentRole },
          } = getRootStore();
          const res = yield api.createBeverage([
            {
              ...payload,
              category_code: 1,
              establishment_id: currentRole._establishment_id,
            },
          ]);
          self.setState('done');

          if (res.data?.result?._beverages_added?.length) {
            const copiedBeverage = res.data.result._beverages_added.find(
              e => !!e.prototype_beverage_id,
            );

            self.updateOrInsert(copiedBeverage);

            if (typeof handleConfirm === 'function') {
              handleConfirm(copiedBeverage);
            }
            return res.data.result._beverages_added;
          }
        } catch (err) {
          self.setState('error');
          return Promise.reject(err);
        }
      }),

      copyBeverage: flow(function* (beverage) {
        try {
          const response = yield api.copyBeverage([beverage.id]);
          if (response?.data?.result) {
            response?.data?.result?._beverages_added.map(beverage => self.updateOrInsert(beverage));
          }
          return response;
        } catch (err) {
          return Promise.reject(err);
        }
      }),

      updateMyBeverage: flow(function* (beverageId, payload) {
        try {
          self.setState('pending');
          const response = yield api.updateMyBeverage(beverageId, payload);
          if (response && response.data && response.data.row && response.data.id) {
            self.updateOrInsert(response.data.row);
            self.setState('done');
            return response;
          }
        } catch (err) {
          self.setState('error');
          return Promise.reject(err);
        }
      }),

      removeMyBeverage: flow(function* (id) {
        try {
          self.setState('pending');
          const response = yield api.removeMyBeverage(id);
          if (response.status === 200) {
            const updatedBeverages = self.all.filter(beverage => beverage.id !== id);
            self.all.replace(updatedBeverages);
            self.setState('done');
          }
        } catch (err) {
          self.setState('error');
          return Promise.reject(err);
        }
      }),

      resetLogoUrlIfExists(beverageId) {
        if (beverageId) {
          const beverageIndex = self.all.findIndex(beverage => beverage?.id === beverageId);

          if (beverageIndex >= 0) {
            self.all[beverageIndex]._image_url = null;
          }
        }
      },

      updateOrInsert(beverage) {
        if (beverage && beverage.id) {
          const beverageIndex = self.all.findIndex(
            existingBeverage => existingBeverage && existingBeverage.id === beverage.id,
          );
          const beverageExists = beverageIndex >= 0;

          if (beverageExists) {
            self.all[beverageIndex] = beverage;
          } else if (beverage.prototype_beverage_id !== null) {
            const updatedBeverages = [...self.all, beverage];
            self.all.replace(updatedBeverages);
          }
        }
      },

      setState(state) {
        self.state = state;
      },

      setErrorMessage(message) {
        self.error = message;
      },

      setPage(value) {
        self.page = value;
      },

      setSearchString(value) {
        self.searchString = value;
      },

      filteredBySearch(beverages, searchString) {
        if (!searchString) {
          return beverages;
        }
        const searchArray = searchString.toLowerCase().split(' ');
        return beverages.filter(beverage =>
          searchArray.every(
            element =>
              normalize(beverage?._name || '')
                .toLowerCase()
                .includes(element) ||
              normalize(beverage?._producers_name || '')
                .toLowerCase()
                .includes(element),
          ),
        );
      },

      setFilters(filters) {
        if (filters === null) {
          self.filters = {
            sortedBy: 'beverageName',
            orderBy: 'ascending',
            filterBy: 'showAll',
            hidden: 'excluded',
          };
        } else {
          self.filters = {
            sortedBy: filters.sortedBy || self.filters.sortedBy,
            orderBy: filters.orderBy || self.filters.orderBy,
            filterBy: filters.filterBy || self.filters.filterBy,
            hidden: filters.hidden || self.filters.hidden,
          };
        }
      },

      setBeverages(beverages) {
        self.all.replace(beverages);
        self.setState('done');
        self.isLoaded = true;
      },

      setLimit(value) {
        self.limit = value;
      },
    };
  });
