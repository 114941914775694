import { types, flow } from 'mobx-state-tree';
import api from 'services/API';
import { SKU } from 'models/types/SKU';
import { getRootStore } from 'models/root';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';

export const skuInitialState = {
  all: [],
  isLoaded: false,
};

const SKUModel = SKU.views(self => ({
  get beverage() {
    const { beveragesStore } = getRootStore();
    return beveragesStore.getBeverageByID(self.beverage_id);
  },
  get beverage_name() {
    return (self.beverage && self.beverage._name) || '';
  },
  get hidden() {
    return self.beverage && self.beverage.hidden;
  },
  get producer_name() {
    return (self.beverage && self.beverage._producers_name) || '';
  },
  get container() {
    const { containersStore } = getRootStore();

    return containersStore.getContainerByID(self.container_id);
  },
}));

export const skuStore = types
  .model({
    all: types.array(SKUModel),
    isLoaded: types.boolean,
    sorted: types.array(types.reference(SKUModel)),
  })
  .views(self => ({
    get draftSKUs() {
      return self.all.filter(skus => skus.type_code === 1);
    },

    get servingSizeSKUs() {
      return self.all.filter(sku => sku.type_code === 1 && sku.active === true);
    },

    get kegSKUs() {
      return self.all.filter(skus => skus.type_code === 0);
    },

    getKegSKUsByBeverageId(beverage_id) {
      const { containersStore } = getRootStore();

      const containersIds = containersStore.availableKegContainers.map(({ id }) => id);

      const result = self.kegSKUs.filter(
        skus => skus.beverage_id === beverage_id && containersIds.includes(skus.container_id),
      );

      // TODO: Understand why after add_items flow (after handleInsertSku) that function return duplicate value
      // * uniqBy -> temporary solution
      return uniqBy(result, 'id');
    },

    getSKUById(id) {
      return self.all.find(sku => sku.id === id);
    },

    getSortValue() {
      const max =
        self.draftSKUs.length &&
        Math.max(...self.draftSKUs.map(({ sort_value }) => Number(sort_value) || 0));

      return max ? max + 1 : 100; // 100 - default sort_value on create skus
    },

    getDraftSKUsByBeverageId(beverage_id, activeOnly = false) {
      const { containersStore } = getRootStore();
      const containers = containersStore.availableDraftContainers;
      const ids = containers.map(el => el.id);
      return self.draftSKUs.filter(sku => {
        if (activeOnly) {
          return sku.active && sku.beverage_id === beverage_id && ids.includes(sku.container_id);
        } else {
          return sku.beverage_id === beverage_id && ids.includes(sku.container_id);
        }
      });
    },

    getSortedDraftSKUsByBeverageId(
      beverage_id,
      properties = ['_containers_volume_total_ml', 'id'],
    ) {
      return sortBy(self.getDraftSKUsByBeverageId(beverage_id), properties);
    },

    getKegSKUsByBeverageAndContainer(beverage_id, container_id) {
      return self.kegSKUs.find(
        sku => sku.beverage_id === beverage_id && sku.container_id === container_id,
      );
    },

    get remaining() {
      const { linesStore, unitStore } = getRootStore();

      if (!linesStore.currentLine?.item) return false;

      const remainingArray = self
        .getDraftSKUsByBeverageId(linesStore.currentLine.item.beverage.id)
        .map(sku => {
          const remaining = Math.round(
            linesStore.currentLine.item._volume_remaining_ml /
              (sku._containers_volume_total * sku._units_ml_equivalent),
          );
          const unit = unitStore.units.find(un => un.id === sku._units_id);

          const volume =
            unit.abbreviation.toLowerCase() === 'ml'
              ? Number(sku?.container?.volume_total).toFixed(0)
              : Number(sku?.container?.volume_total).toFixed(2);

          const name = sku?.container?.nickname
            ? `${sku?.container?.nickname} (${volume}${unit.abbreviation.toLowerCase()})`
            : sku?.container?.name;

          return {
            name,
            value: remaining,
            unit,
            _containers_volume_total: sku._containers_volume_total,
          };
        })
        .sort((a, b) => {
          return b.value - a.value;
        })
        .map(el => {
          if (el.name) {
            return `${el.name} - ${el.value}`;
          } else if (el.unit && el._containers_volume_total) {
            return `${el._containers_volume_total}${
              Math.floor(el._containers_volume_total) === el._containers_volume_total ? '.0' : ''
            } ${el.unit.abbreviation} - ${el.value}`;
          }
          return `N/A - ${el.value}`;
        });

      let firstElement = '-';

      const remainingMl =
        isNaN(linesStore.currentLine.item?._volume_remaining_ml) ||
        isNaN(linesStore.currentLine.item?.container?.volume_unit?.ml_equivalent)
          ? ''
          : (
              linesStore.currentLine.item?._volume_remaining_ml /
              linesStore.currentLine.item?.container?.volume_unit?.ml_equivalent
            ).toFixed(1);
      const volumeUnitName = linesStore.currentLine.item?.container?.volume_unit?.name || '';

      const percentage = linesStore.currentLine.item.percentage;
      if (percentage > -3 && percentage < 5) {
        firstElement = 'Low Volume';
      } else {
        firstElement = `${remainingMl} ${volumeUnitName}`;
      }

      if (remainingMl < 0) {
        return [firstElement];
      }

      const combinedArray = [firstElement, ...remainingArray];

      return combinedArray;
    },
  }))
  .actions(self => {
    return {
      handleSkuCreate(sku = {}) {
        if (sku.id) {
          self.all.push(sku);
        }
      },

      handleSkuDelete(removedElements) {
        if (!removedElements?.id && !Array.isArray(removedElements)) {
          return;
        } else if (!Array.isArray(removedElements)) {
          removedElements = [removedElements];
        }

        const updatedList = self.all.filter(
          existingEl => !removedElements.some(removedEl => removedEl.id === existingEl.id),
        );

        self.all.replace(updatedList);
      },

      handleSkuUpdate(sku = {}) {
        const skuIndex = self.all.findIndex(existingSku => existingSku.id === sku.id);

        if (skuIndex >= 0) {
          self.all[skuIndex] = {
            ...self.all[skuIndex],
            ...sku,
          };
        }
      },

      handleInsertSku(sku) {
        const item = self.all.find(existingSku => existingSku.id === sku.id);
        if (!item) {
          self.all.push({
            ...sku,
            id: sku.id || sku.sku_id,
          });
        }
      },

      fetchSKUs: flow(function* (beverage_id = false) {
        const root = getRootStore();
        try {
          const body = beverage_id
            ? { beverage_id, type_code: 1 }
            : { container_id: root.containersStore.all.map(el => el.id) };
          const response = yield api.getSKUs(body);
          if (response && response.data && response.data.result) {
            if (beverage_id) {
              response.data.result.forEach(sku => {
                if (self.all.find(existingSku => existingSku.id === sku.id)) {
                  self.handleSkuUpdate(sku);
                } else {
                  self.handleSkuCreate(sku);
                }
              });
            } else {
              self.all.replace(response.data.result);
            }
          }
          self.isLoaded = true;
        } catch (err) {
          self.isLoaded = false;
          return Promise.reject(err);
        }
      }),

      createSKU: flow(function* (data) {
        try {
          const response = yield api.createSKU({
            ...data,
            sort_value: self.getSortValue(),
          });
          if (response?.data?.id) {
            self.all.push(response.data.row);
            return response;
          }
        } catch (err) {
          return Promise.reject(err);
        }
      }),

      updateSKU: flow(function* (id, data) {
        try {
          const response = yield api.updateSKU(id, data);
          if (response && response.data && response.data.row && response.data.id) {
            self.all.replace(
              self.all.map(skus => {
                if (skus.id === id) {
                  return {
                    ...skus,
                    default_cost: response.data.row.default_cost,
                    default_goal: response.data.row.default_goal,
                    display_price: response.data.row.display_price,
                    name: response.data.row.name,
                    _name: response.data.row.name,
                    active: response.data.row.active,
                    _head_volume_pct: response.data.row._head_volume_pct,
                    par: response.data.row.par,
                  };
                }
                return skus;
              }),
            );
            return response;
          }
        } catch (err) {
          return Promise.reject(err);
        }
      }),

      deleteSKU: flow(function* (id) {
        try {
          const response = yield api.deleteSKU(id);
          if (response && response.data) {
            self.all.replace(self.all.filter(el => el.id !== id));
            return response;
          }
        } catch (err) {
          return Promise.reject(err);
        }
      }),

      createDefaultPricing: flow(function* ({
        beverage_id,
        container_id,
        default_cost,
        default_goal,
        par,
      }) {
        try {
          const response = yield api.createSKU({
            beverage_id,
            container_id,
            default_cost,
            default_goal,
            par: par === '' ? null : par,
            type_code: 0,
          });
          if (response?.data?.row) {
            self.all.push(response.data.row);
          }
          return response;
        } catch (err) {
          return Promise.reject(err);
        }
      }),

      updateDefaultPricing: flow(function* ({ skus_id, default_cost, default_goal, par }) {
        try {
          const response = yield api.updateSKU(skus_id, {
            default_cost,
            default_goal,
            par,
          });
          if (response && response.data && response.data.row) {
            self.all.replace(
              self.all.map(sku => {
                if (sku.id === skus_id) {
                  return response.data.row;
                }
                return sku;
              }),
            );
          }
          return response;
        } catch (err) {
          return Promise.reject(err);
        }
      }),

      reorderSKUs: flow(function* ({ beverage_id, container_ids }) {
        try {
          const response = yield api.reorderContainers({
            beverage_id,
            container_ids,
          });
          if (response?.data?.result) {
            response.data.result.forEach(({ id, sort_value }) => {
              const sku = self.getSKUById(id);
              self.handleSkuUpdate({
                ...sku,
                sort_value,
              });
            });
          }
        } catch (err) {
          return Promise.reject(err);
        }
      }),

      setSKUs(skus) {
        self.all.replace(skus);
        self.isLoaded = true;
      },
      insertSKU(sku) {
        self.all.replace([...self.all, sku]);
        self.isLoaded = true;
      },
    };
  });
