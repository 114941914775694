import { useCallback, useMemo, useState } from 'react';
import { useStore, useToast } from 'hooks';

const useMyBeveragesData = () => {
  const { unitStore, beveragesStore, containersStore, skuStore } = useStore();
  const { errorToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const loadData = useCallback(async () => {
    try {
      setIsLoading(true);
      await containersStore.fetch();
      await Promise.all([unitStore.fetch(), beveragesStore.fetchBeverages(), skuStore.fetchSKUs()]);
    } catch (err) {
      errorToast(err);
    } finally {
      setIsLoading(false);
    }
  }, [beveragesStore, containersStore, errorToast, skuStore, unitStore]);

  const isLoaded = useMemo(() => {
    return beveragesStore.isLoaded && skuStore.isLoaded && containersStore.isLoaded;
  }, [beveragesStore.isLoaded, skuStore.isLoaded, containersStore.isLoaded]);

  return { isLoading, isLoaded, loadData };
};

export default useMyBeveragesData;
