const MuiPickersCalendarHeader = {
  styleOverrides: {
    root: {
      '.MuiPickersArrowSwitcher-root': {
        button: {
          width: '48px',
        },
      },
    },
  },
};

export default MuiPickersCalendarHeader;
