import React, { useState, useCallback } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  TextField,
  styled,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { observer } from 'mobx-react';

import { FONTS } from 'config/fonts';
import { useOuterClick } from 'hooks';
import Picker from './Picker';

const Appearance = ({ state }) => {
  const [expanded, setExpanded] = useState(true);
  const [backgroundColorVisibility, setBackgroundColorVisibility] = useState(false);
  const [titleColorVisibility, setTitleColorVisibility] = useState(false);
  const [footerColorVisibility, setFooterColorVisibility] = useState(false);
  const [bubbleColorVisibility, setBubbleColorVisibility] = useState(false);
  const [bubbleTextColorVisibility, setBubbleTextColorVisibility] = useState(false);
  const [kegColorVisibility, setKegColorVisibility] = useState(false);
  const [beverageNameRowColorVisibility, setBeverageNameRowColorVisibility] = useState(false);
  const [beverageDetailsRowColorVisibility, setBeverageDetailsRowColorVisibility] = useState(false);
  const [producerRowColorVisibility, setProducerRowColorVisibility] = useState(false);
  const [priceListColorVisibility, setPriceListColorVisibility] = useState(false);
  const [logoColorVisibility, setLogoColorVisibility] = useState(false);
  const [categoryColorVisibility, setCategoryColorVisibility] = useState(false);

  const backgroundPickerRef = useOuterClick(() => {
    backgroundColorVisibility && setBackgroundColorVisibility(false);
  });

  const beverageNameRowColorPicker = useOuterClick(() => {
    beverageNameRowColorVisibility && setBeverageNameRowColorVisibility(false);
  });

  const secondRowPickerRef = useOuterClick(() => {
    beverageDetailsRowColorVisibility && setBeverageDetailsRowColorVisibility(false);
  });

  const producerRowPickerRef = useOuterClick(() => {
    producerRowColorVisibility && setProducerRowColorVisibility(false);
  });

  const priceListPickerRef = useOuterClick(() => {
    priceListColorVisibility && setPriceListColorVisibility(false);
  });

  const kegPickerRef = useOuterClick(() => {
    kegColorVisibility && setKegColorVisibility(false);
  });

  const titlePickerRef = useOuterClick(() => {
    titleColorVisibility && setTitleColorVisibility(false);
  });

  const bubblePickerRef = useOuterClick(() => {
    bubbleColorVisibility && setBubbleColorVisibility(false);
  });

  const bubbleTextPickerRef = useOuterClick(() => {
    bubbleTextColorVisibility && setBubbleTextColorVisibility(false);
  });

  const logoPickerRef = useOuterClick(() => {
    logoColorVisibility && setLogoColorVisibility(false);
  });

  const footerPickerRef = useOuterClick(() => {
    footerColorVisibility && setFooterColorVisibility(false);
  });

  const categoryPickerRef = useOuterClick(() => {
    categoryColorVisibility && setCategoryColorVisibility(false);
  });

  const resetPickers = () => {
    setBackgroundColorVisibility(false);
    setTitleColorVisibility(false);
    setFooterColorVisibility(false);
    setBubbleColorVisibility(false);
    setBubbleTextColorVisibility(false);
    setKegColorVisibility(false);
    setBeverageNameRowColorVisibility(false);
    setBeverageDetailsRowColorVisibility(false);
    setPriceListColorVisibility(false);
    setLogoColorVisibility(false);
    setCategoryColorVisibility(false);
  };

  const handleExpansionChange = useCallback(() => {
    setExpanded(expanded => !expanded);
  }, []);
  return (
    <StyledRootAccordion expanded={expanded} onChange={handleExpansionChange}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className="heading">Appearance</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="tile-columns">Font</InputLabel>
          <Select
            fullWidth
            label="Font"
            name="font"
            value={state.font}
            onChange={state.handleChange}
          >
            {FONTS.map(font => (
              <MenuItem key={font.title} value={font.value}>
                <span className="option" style={{ fontFamily: font.value }}>
                  {font.title}
                </span>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="Title"
          variant="outlined"
          fullWidth
          value={state.title}
          onChange={state.handleChange}
          name="title"
        />
        <Typography variant="h5" className="subheading">
          Layout Colors
        </Typography>
        <Box pt={2}>
          <Picker
            open={backgroundColorVisibility}
            label="Background Color"
            pickerRef={backgroundPickerRef}
            name="backgroundColor"
            state={state}
            setter={setBackgroundColorVisibility}
            resetPickers={resetPickers}
            show={[1, 2, 3, 4, 5, 6, 7, 8].includes(state.template)}
          />
          <Picker
            open={titleColorVisibility}
            label="Title Color"
            pickerRef={titlePickerRef}
            name="titleColor"
            state={state}
            setter={setTitleColorVisibility}
            resetPickers={resetPickers}
            show={[1, 2, 3, 4, 5, 6, 7, 8].includes(state.template)}
          />
          <Picker
            open={categoryColorVisibility}
            label="Category Color"
            pickerRef={categoryPickerRef}
            name="categoryColor"
            state={state}
            setter={setCategoryColorVisibility}
            resetPickers={resetPickers}
            show={state.groupBy !== 'n/a'}
          />
          <Picker
            open={footerColorVisibility}
            label="Footer Color"
            pickerRef={footerPickerRef}
            name="footerColor"
            state={state}
            setter={setFooterColorVisibility}
            resetPickers={resetPickers}
            show={state.orientation === 'vertical'}
          />
          <Picker
            open={logoColorVisibility}
            label="Logo Color"
            pickerRef={logoPickerRef}
            name="logoColor"
            state={state}
            setter={setLogoColorVisibility}
            resetPickers={resetPickers}
            show={[1, 2, 3, 4, 5, 6, 7, 8].includes(state.template)}
          />
        </Box>
        <Typography variant="h5" className="subheading">
          Tile Colors
        </Typography>
        <Box pt={2}>
          <Picker
            open={beverageNameRowColorVisibility}
            label="Beverage Name Row Color"
            pickerRef={beverageNameRowColorPicker}
            name="beverageNameRowColor"
            state={state}
            setter={setBeverageNameRowColorVisibility}
            resetPickers={resetPickers}
            show={[1, 2, 3, 4, 5, 6, 7, 8].includes(state.template)}
          />
          <Picker
            open={producerRowColorVisibility}
            label="Producer Row Color"
            pickerRef={producerRowPickerRef}
            name="producerRowColor"
            state={state}
            setter={setProducerRowColorVisibility}
            resetPickers={resetPickers}
            show={[1, 4, 6, 7, 8].includes(state.template)}
          />
          <Picker
            open={beverageDetailsRowColorVisibility}
            label="Beverage Details Row Color"
            pickerRef={secondRowPickerRef}
            name="beverageDetailsRowColor"
            state={state}
            setter={setBeverageDetailsRowColorVisibility}
            resetPickers={resetPickers}
            show={[2, 3, 4, 5, 6, 7, 8].includes(state.template)}
          />
          <Picker
            open={priceListColorVisibility}
            label="Price List Color"
            pickerRef={priceListPickerRef}
            name="priceListColor"
            state={state}
            setter={setPriceListColorVisibility}
            resetPickers={resetPickers}
            show={[1, 2, 3, 4, 5, 6, 7].includes(state.template)}
          />
          <Picker
            open={bubbleColorVisibility}
            label="Bubble Color"
            pickerRef={bubblePickerRef}
            name="bubbleColor"
            state={state}
            setter={setBubbleColorVisibility}
            resetPickers={resetPickers}
            show={[3, 5, 6].includes(state.template)}
          />
          <Picker
            open={bubbleTextColorVisibility}
            label="Bubble Text Color"
            pickerRef={bubbleTextPickerRef}
            name="bubbleTextColor"
            state={state}
            setter={setBubbleTextColorVisibility}
            resetPickers={resetPickers}
            show={[3, 5, 6].includes(state.template)}
          />
          <Picker
            open={kegColorVisibility}
            label="Keg Color"
            pickerRef={kegPickerRef}
            name="kegColor"
            state={state}
            setter={setKegColorVisibility}
            resetPickers={resetPickers}
            show={[1, 2, 3, 4, 5, 6, 7, 8].includes(state.template)}
          />
        </Box>
      </AccordionDetails>
    </StyledRootAccordion>
  );
};

export default observer(Appearance);

const StyledRootAccordion = styled(Accordion)(({ theme }) => ({
  '.heading': {
    fontSize: theme.typography.pxToRem(22),
    fontWeight: theme.typography.fontWeightRegular,
    '& > .MuiIconButton-edgeEnd': {
      marginRight: '0 !important',
    },
  },
  '.subheading': {
    textDecoration: 'underline',
    marginBottom: theme.spacing(1),
  },
}));
