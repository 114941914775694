import { normalize } from 'utils/diacriticNormalizer';
import { Box, Typography } from '@mui/material';
import { getRootStore } from 'models/root';

/* eslint-disable no-useless-escape */
const stringUtilities = {
  capitalizeFirstLetter(string) {
    return string[0]?.toUpperCase() + string.slice(1);
  },

  makeDecimal(value, decimal) {
    const regexp = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimal}})?`);
    return value.toString().match(regexp) && value.toString().match(regexp)[0];
  },

  isExponential(value) {
    const regexp = new RegExp(`[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)`);
    return value.toString().match(regexp) && value.toString().match(regexp)[0];
  },

  fixedSuffix(value, suffix = '') {
    return value ? value.toFixed(1) + suffix : 'N/A';
  },

  encodedSearchParams(value) {
    return (
      value
        .trim()
        .split(' ')
        .filter(e => e)
        .join(`:*&`) + ':*'
    );
  },

  makeTemperature(initialValue, decimal = 1) {
    const root = getRootStore();
    const { unitPreference } = root.establishmentStore;

    if (unitPreference === 'English') {
      const value = this.makeDecimal((initialValue * 9) / 5 + 32, 1);
      return `${Number(value).toFixed(decimal)}°F`;
    }
    const value = this.makeDecimal(initialValue, 1);
    return `${Number(value).toFixed(decimal)}°C`;
  },

  generateImageURL: (name, defaultPath = '/assets/images/empty-tap.svg') =>
    name ? `https://s3.amazonaws.com/logos.bartrack.beer/${name}.png` : defaultPath,

  formatPhone: phone => {
    if (!phone) return phone;

    let p = phone[0] === '+' ? phone : `+${phone}`;

    return `${p.slice(2, 5)}-${p.slice(5, 8)}-${p.slice(8)}`;
  },

  isEmail(str) {
    const regexp = new RegExp(
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
    );

    return regexp.test(str);
  },

  isPhoneNumber(str) {
    const regexp = new RegExp(/^\d\d\d-\d\d\d-\d\d\d\d$/i);

    return regexp.test(str);
  },

  toFloat(value, fractionDigits = 1) {
    if (Number.isFinite(value)) {
      let roundedValue = Math.round(value * 10) / 10;
      if (Object.is(roundedValue, -0)) {
        roundedValue = Math.abs(roundedValue);
      }
      return roundedValue.toFixed(fractionDigits);
    }
    return value;
  },

  matchSome(value = '', options = []) {
    const normalizedValue = typeof value === 'string' ? value.toLowerCase() : '';
    return options.some(option => {
      const normalizedOption = typeof option === 'string' ? normalize(option.toLowerCase()) : '';
      return normalizedOption.includes(normalizedValue);
    });
  },

  isPositive: value => Number(value) > 0,

  prepareText: ({ start, bold, end }) => (
    <Typography component="div">
      {start}
      <Box fontWeight="700" display="inline">
        {bold}
      </Box>
      {end}
    </Typography>
  ),

  sliceNumber: (str, start, end) =>
    str && str.length ? Number(str.slice(str.indexOf(start) + 1, str.indexOf(end)).trim()) : str,

  ruleToValue: str => {
    const sign = (str.indexOf('>') !== -1 && '>') || (str.indexOf('<') !== -1 && '<');

    return stringUtilities.sliceNumber(str, sign, ')');
  },
  getEmailPrefix: email => email.slice(0, email.indexOf('@')),
};

export default stringUtilities;
