import { types } from 'mobx-state-tree';

export const SKU = types.model({
  id: types.identifierNumber,
  beverage_id: types.integer,
  container_id: types.maybe(types.integer),
  type_code: types.integer,
  display_price: types.maybeNull(types.number),
  default_cost: types.maybeNull(types.number),
  default_goal: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  sort_value: types.maybeNull(types.number),
  archived: types.maybeNull(types.boolean),
  active: types.maybeNull(types.boolean),
  par: types.maybeNull(types.number),
  _name: types.maybeNull(types.string),
  _containers_volume_total: types.maybeNull(types.number),
  _containers_volume_total_ml: types.maybeNull(types.number),
  _containers_type_code: types.maybeNull(types.number),
  _head_volume_pct: types.maybeNull(types.number),
  _units_id: types.maybeNull(types.integer),
  _units_ml_equivalent: types.maybeNull(types.number),
  _containers_name: types.maybeNull(types.string),
});
