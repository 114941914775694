export const FONTS = [
  { title: 'Alfa Slab One', value: 'Alfa Slab One, cursive' },
  { title: 'Permanent Marker', value: 'Permanent Marker, cursive' },
  { title: 'Sigmar One', value: 'Sigmar One, cursive' },
  { title: 'Sarpanch', value: 'Sarpanch, cursive' },
  { title: 'Limelight', value: 'Limelight, cursive' },
  { title: 'Chango', value: 'Chango, cursive' },
  { title: 'Rammetto One', value: 'Rammetto One, cursive' },
  { title: 'Fascinate', value: 'Fascinate, cursive' },
  { title: 'Fascinate Inline', value: 'Fascinate Inline, cursive' },
  { title: 'Poller One', value: 'Poller One, cursive' },
  { title: 'Paytone One', value: 'Paytone One, cursive' },
  { title: 'Kavoon', value: 'Kavoon, cursive' },
  { title: 'Emblema One', value: 'Emblema One, cursive' },
  { title: 'Chalkduster', value: 'Chalkduster, cursive' },
  { title: 'Fredoka One', value: 'Fredoka One, cursive' },
  { title: 'Walter Turncoat', value: 'Walter Turncoat, cursive' },
  { title: 'Anton', value: 'Anton, cursive' },
  { title: 'Lemon', value: 'Lemon, cursive' },
  { title: 'Candal', value: 'Candal, cursive' },
  { title: 'Knewave', value: 'Knewave, cursive' },
  { title: 'Comfortaa', value: 'Comfortaa, cursive' },
  { title: 'Montserrat', value: 'Montserrat, sans-serif' },
].sort((a, b) => a.title.localeCompare(b.title));

// excludes Chalkduster
export const FONT_LIST = [
  'Montserrat',
  'Knewave',
  'Comfortaa',
  'Fredoka One',
  'Walter Turncoat',
  'Sarpanch',
  'Limelight',
  'Permanent Marker',
  'Candal',
  'Fascinate',
  'Fascinate Inline',
  'Anton',
  'Lemon',
  'Alfa Slab One',
  'Sigmar One',
  'Chango',
  'Rammetto One',
  'Poller One',
  'Paytone One',
  'Kavoon',
  'Emblema One',
];
