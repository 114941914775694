import React from 'react';
import { Box, styled } from '@mui/material';
import clsx from 'clsx';

import Mobile from 'components/shared/Mobile';

const MobileDialogHeader = ({ breakpoint, children, align = 'left', custom, ...rest }) => {
  if (!children) {
    return null;
  }

  let components = children;
  const componentsCount = components.length || 1;
  if (!custom && Array.isArray(children) && componentsCount > 1) {
    components = children.map((child, index) => <Box key={index}>{child}</Box>);
  }

  return (
    <Mobile breakpoint={breakpoint}>
      <StyledRootBox
        className={
          !custom &&
          clsx(`layout-${componentsCount}`, `align-${align}`, {
            flexContainer: true,
          })
        }
        {...rest}
      >
        {components}
      </StyledRootBox>
    </Mobile>
  );
};

export default MobileDialogHeader;

const StyledRootBox = styled(Box)(() => ({
  display: 'flex',
  height: '100%',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
  '&.layout-1': {
    justifyContent: 'center',
  },
  '&.layout-2': {
    '& > :first-of-type': {
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: 90,
    },
    '&.align-right > :first-of-type': {
      flexGrow: 1,
      paddingLeft: 90,
      textAlign: 'center',
    },
    '& > :last-of-type': {
      flexGrow: 1,
      paddingRight: 90,
      textAlign: 'center',
    },
    '&.align-right > :last-of-type': {
      flexGrow: 0,
      flexShrink: 0,
      paddingRight: 0,
      textAlign: 'right',
    },
  },
  '&.layout-3': {
    justifyContent: 'space-between',
    '& > :first-of-type, & > :last-of-type': {
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: 0,
      textAlign: 'left',
    },
    '& > :last-of-type': {
      textAlign: 'right',
    },
    '& > :nth-of-type(2)': {
      flexGrow: 1,
      textAlign: 'center',
    },
  },
}));
