import { Fragment, useEffect, useState, Suspense } from 'react';
import { useRoutes } from 'react-router-dom';

import { processRoutes } from 'services/router';
import { ability } from 'services/casl';
import config from 'routes/config';

export default function Routes() {
  const [routesUpdatedAt, setRoutesUpdatedAt] = useState(null);
  const routes = useRoutes(processRoutes(config));

  useEffect(() => {
    const unsubscribe = ability.on('update', () => {
      setRoutesUpdatedAt(new Date().getTime());
    });
    return () => unsubscribe();
  }, []);

  return (
    <Fragment key={routesUpdatedAt}>
      <Suspense fallback={null}>{routes}</Suspense>
    </Fragment>
  );
}
