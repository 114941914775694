const MuiExpansionPanelSummary = {
  styleOverrides: {
    root: {
      minHeight: 44,
      '&$expanded': {
        minHeight: 44,
      },
    },
    content: {
      margin: '9px 0',
      '&$expanded': {
        margin: '9px 0',
      },
    },
  },
};

export default MuiExpansionPanelSummary;
