import { DynamicTextInput } from './DesktopInputs/DynamicTextInput';
import { DynamicSelectInput } from './DesktopInputs/DynamicSelectInput';
import { DynamicTimePicker } from './DesktopInputs/DynamicTimePicker';

const DesktopView = ({
  type,
  title,
  name,
  valueState,
  handleChange,
  onBlur,
  setFocusedInput,
  description,
  disabled,
  values,
  textColor,
}) => {
  const props = {
    title,
    name,
    valueState,
    handleChange,
    onBlur,
    setFocusedInput,
    description,
    disabled,
    values,
    textColor,
  };
  //
  // const handleSelectChoice = useCallback(
  //   event => {
  //     const mobileValue = values.filter(({ value, label }) => {
  //       if (label === event.target.innerText) {
  //         return value;
  //       }
  //       return {};
  //     });
  //     const value = event.target.value;
  //     handleChange(value, name);
  //   },
  //   [values, handleChange, name],
  // );

  let inputType = type;
  if (type === undefined) {
    inputType = 'text';
  }

  const inputTypes = new Map([
    ['text', <DynamicTextInput {...props} />],
    ['time', <DynamicTimePicker {...props} />],
    ['select', <DynamicSelectInput {...props} />],
  ]);
  return inputTypes.get(inputType);
};

export { DesktopView };
