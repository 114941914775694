import { types } from 'mobx-state-tree';

export const Bar = types.model({
  id: types.integer,
  establishment_id: types.integer,
  archived: types.boolean,
  created_at: types.maybeNull(types.string),
  created_by: types.maybeNull(types.integer),
  metadata: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
});
