import * as yup from 'yup';
import { isFuture } from 'date-fns';
import { isToday } from 'date-fns';
import { differenceInHours } from 'date-fns';

yup.addMethod(yup.string, 'isPhoneNumber', function (message) {
  return this.test('isPhoneNumber', message, function (value) {
    return !value.includes('_');
  });
});

yup.addMethod(yup.string, 'sameAs', function (ref, message) {
  return this.test('sameAs', message, function (value) {
    const other = this.resolve(ref);
    return !other || !value || value === other;
  });
});

yup.addMethod(yup.date, 'isTodayOrFuture', function (message) {
  return this.test('isTodayOrFuture', message, function (value) {
    return isToday(new Date(value)) || isFuture(new Date(value));
  });
});

yup.addMethod(yup.date, 'isFuture12H', function (message) {
  return this.test('isFuture12H', message, function (value) {
    return !(differenceInHours(new Date(value), new Date()) < 12);
  });
});

export default yup;
