import { Suspense } from 'react';

export const processRoutes = routes => {
  if (Array.isArray(routes)) {
    return routes
      .filter(route => {
        if (typeof route.guard === 'function') {
          return route.guard();
        }
        return true;
      })
      .map(route => ({
        ...route,
        element: <Suspense fallback={null}>{route.element}</Suspense>,
        ...(Array.isArray(route.children) && {
          children: processRoutes(route.children),
        }),
      }));
  }
  return [];
};
