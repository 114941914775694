import React from 'react';
import * as Sentry from '@sentry/react';
import FallbackComponent from 'components/shared/FallbackComponent';

const SentryErrorBoundary = ({ children, fallbackPage }) => (
  <Sentry.ErrorBoundary
    fallback={({ resetError }) => (
      <FallbackComponent resetError={resetError} fallbackPage={fallbackPage} />
    )}
  >
    {children}
  </Sentry.ErrorBoundary>
);

export default SentryErrorBoundary;
