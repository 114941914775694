import { types } from 'mobx-state-tree';
const Metadata = types.model({
  description: types.maybeNull(types.string),
});

export const Cooler = types.model({
  id: types.integer,
  archived: types.maybeNull(types.boolean),
  cooler_type: types.maybeNull(types.number),
  created_at: types.maybeNull(types.string),
  draft_system_type: types.maybeNull(types.number),
  establishment_id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  updated_at: types.maybeNull(types.string),
  gas_system_blend_ratio: types.maybeNull(
    types.model({
      co2_ratio: types.maybeNull(types.number),
      nitrogen_ratio: types.maybeNull(types.number),
    }),
  ),
  _bar_ids: types.maybeNull(types.array(types.integer)),
  metadata: types.maybeNull(Metadata),
});
