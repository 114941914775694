import React from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'hooks';

import DraftPricingCalculator from 'components/shared/dialogs/DraftPricingCalculator';
import MissingUserDetailsDialog from 'components/shared/dialogs/MissingUserDetailsDialog';

export const GlobalModals = () => {
  const { ui } = useStore();

  if (ui.modalsVisibility.calculator) {
    return (
      <DraftPricingCalculator
        title="Draft Pricing Calculator"
        labelledby="calculator"
        fullWidth
        maxWidth="xs"
        open={ui.modalsVisibility.calculator}
      />
    );
  }

  if (ui.modalsVisibility.missingUserDetails) {
    return <MissingUserDetailsDialog open={ui.modalsVisibility.missingUserDetails} />;
  }

  return null;
};

export default observer(GlobalModals);
