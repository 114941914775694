import React from 'react';
import { observer } from 'mobx-react';
import { Box, Divider } from '@mui/material';

import General from './General';
import Templates from './Templates';

const Layout = props => (
  <Box py={3} px={2}>
    <General {...props} />
    <Divider />
    <Templates {...props} />
  </Box>
);

export default observer(Layout);
