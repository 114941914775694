import { useEffect, useState, useCallback } from 'react';

const useNetworkDetector = () => {
  const [isDisconnected, setIsDisconnected] = useState(false);

  const handleConnectionChange = useCallback(() => {
    const condition = navigator && navigator.onLine ? 'online' : 'offline';
    if (condition === 'online') {
      return setIsDisconnected(false);
    }

    return setIsDisconnected(true);
  }, []);

  useEffect(() => {
    window.addEventListener('online', handleConnectionChange);
    window.addEventListener('offline', handleConnectionChange);
    return () => {
      window.removeEventListener('online', handleConnectionChange);
      window.removeEventListener('offline', handleConnectionChange);
    };
  }, [handleConnectionChange]);

  useEffect(() => {
    const condition = navigator && navigator.onLine ? 'online' : 'offline';
    if (condition === 'offline') {
      setIsDisconnected(true);
    }
  }, []);

  return isDisconnected;
};

export default useNetworkDetector;
