import React, { useState, useCallback } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  styled,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { observer } from 'mobx-react';

const Organization = ({ state }) => {
  const [expanded, setExpanded] = useState(true);

  const handleExpansionChange = useCallback(() => {
    setExpanded(expanded => !expanded);
  }, []);

  return (
    <StyledRootAccordion expanded={expanded} onChange={handleExpansionChange}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className="heading">Organization</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="h5" className="subheading">
          Sorting
        </Typography>
        <Box display="flex" flexDirection="column" alignItems="start" pt={1}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="tile-columns">Sort By</InputLabel>
            <Select
              fullWidth
              label="Sort By"
              name="sortBy"
              value={state.sortBy}
              onChange={state.handleChange}
            >
              {[
                {
                  label: 'Beverage Name',
                  value: '_name',
                },
                {
                  label: 'Beverage Type',
                  value: '_type',
                },
                {
                  label: 'Tap #',
                  value: 'sort_value',
                },
              ].map((val, i) => {
                if (i === 2 && state.lists.some(({ method }) => method === 3)) return null;
                return (
                  <MenuItem key={val.value} value={val.value}>
                    <span className="option">{val.label}</span>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="tile-columns">Sort Order</InputLabel>
            <Select
              fullWidth
              label="Sort Order"
              name="sortOrder"
              value={state.sortOrder}
              onChange={state.handleChange}
            >
              {['Ascending', 'Descending'].map(val => (
                <MenuItem key={val} value={val.toLowerCase()}>
                  <span className="option">{val}</span>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Typography variant="h5" className="subheading">
          Menu Groups
        </Typography>
        <Box display="flex" flexDirection="column" alignItems="start" pt={1}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="tile-columns">Group By</InputLabel>
            <Select
              fullWidth
              label="Group By"
              name="groupBy"
              value={state.groupBy}
              onChange={state.handleChange}
            >
              {['N/A', 'Category', 'Type'].map(val => (
                <MenuItem key={val} value={val.toLowerCase()}>
                  <span className="option">{val}</span>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </AccordionDetails>
      <Divider />
    </StyledRootAccordion>
  );
};

export default observer(Organization);

const StyledRootAccordion = styled(Accordion)(({ theme }) => ({
  '.heading': {
    fontSize: theme.typography.pxToRem(22),
    fontWeight: theme.typography.fontWeightRegular,
    '& > .MuiIconButton-edgeEnd': {
      marginRight: '0 !important',
    },
  },
  '.subheading': {
    textDecoration: 'underline',
    marginBottom: theme.spacing(1),
  },
}));
