import palette from 'theme/palette';

const typography = {
  fontSize: 14,
  fontFamily: 'Montserrat',
  h1: {
    color: palette.white,
    fontWeight: 400,
    fontSize: 34,
    letterSpacing: 0,
    lineHeight: '40px',
  },
  h2: {
    color: palette.white,
    fontWeight: 500,
    fontSize: 29,
    letterSpacing: '-0.24px',
    lineHeight: '32px',
  },
  h3: {
    color: palette.primary.main,
    fontWeight: 500,
    fontSize: 26,
    letterSpacing: '-0.06px',
    lineHeight: '28px',
    marginBottom: 10,
    '@media(max-width: 1023px)': {
      fontSize: 22,
    },
  },
  h4: {
    color: palette.white,
    fontWeight: 500,
    fontSize: 26,
    letterSpacing: '-0.06px',
    lineHeight: '24px',
  },
  h5: {
    color: palette.whiteEmphasis.high,
    fontWeight: 500,
    fontSize: 20,
    letterSpacing: '-0.012px',
    lineHeight: 'normal',
  },
  h6: {
    color: palette.primary.main,
    fontWeight: 500,
    fontSize: 16,
    letterSpacing: '-0.008px',
    lineHeight: 'normal',
  },
  h7: {
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '-0.007px',
  },
  h8: {
    fontSize: '11px',
    fontWeight: 500,
    letterSpacing: '0.75px',
  },
  subtitle1: {
    color: palette.white,
    fontWeight: 300,
    fontSize: 16,
    letterSpacing: '0',
    lineHeight: '21px',
  },
  subtitle2: {
    color: palette.white,
    fontWeight: 500,
    fontSize: 22,
    letterSpacing: '0',
    lineHeight: 1.2,
  },
  body1: {
    color: palette.white,
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: '-0.05px',
    lineHeight: 'normal',
  },
  body2: {
    color: palette.text.secondary,
    fontSize: 12,
    letterSpacing: '-0.04px',
    lineHeight: '18px',
  },
  button: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '16px',
    letterSpacing: '0.75px',
  },
  caption: {
    color: palette.text.primary,
    fontSize: 11,
    letterSpacing: '0.33px',
    lineHeight: '13px',
  },
  overline: {
    color: palette.text.secondary,
    fontSize: 11,
    fontWeight: 500,
    letterSpacing: '0.33px',
    lineHeight: '13px',
    textTransform: 'uppercase',
  },
};

export default typography;
