import palette from 'theme/palette';

const MuiCircularProgress = {
  styleOverrides: {
    colorPrimary: {
      color: `${palette.primary.main} !important`,
    },
  },
};

export default MuiCircularProgress;
