import palette from 'theme/palette';

const MuiToolbar = {
  styleOverrides: ({ ownerState }) => ({
    ...(ownerState.color === 'secondary' && {
      color: palette.info.main,
    }),
    gutterBottom: {
      marginBottom: 10,
    },
  }),
};

export default MuiToolbar;
