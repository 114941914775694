import { alpha } from '@mui/material/styles';
import palette from 'theme/palette';

const MuiTableSortLabel = {
  styleOverrides: {
    root: {
      '&:hover': {
        color: palette.white,
      },
      '&.Mui-active': {
        color: alpha(palette.white, 0.6),
      },
      '& $icon': {
        color: `inherit !important`,
      },
    },
  },
};

export default MuiTableSortLabel;
