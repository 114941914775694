import { createContext } from 'react';
import { createContextualCan } from '@casl/react';
import { AbilityBuilder, Ability } from '@casl/ability';

export const ability = new Ability();
export const AbilityContext = createContext(ability);

AbilityContext.displayName = 'CASL Ability Context';

export const updateAbility = inputRules => {
  const { can, rules } = new AbilityBuilder();

  if (Array.isArray(inputRules)) {
    inputRules.forEach(rule => {
      if (rule.subject || rule.action) {
        const subject = rule.subject || 'all';
        const action = rule.action || 'manage';
        can(action, subject, rule.fields, rule.conditions);
      }
    });
  }

  ability.update(rules);
};

// Component that should be used for conditional rendering
// based on the user permissions
export const Can = createContextualCan(AbilityContext.Consumer);
