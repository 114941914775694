import React from 'react';
import { observer } from 'mobx-react';
import Horizontal from './Horizontal';
import Vertical from './Vertical';

const Layout = ({ children, state }) => {
  const LayoutComponent = state.orientation === 'horizontal' ? Horizontal : Vertical;

  return <LayoutComponent state={state}>{children}</LayoutComponent>;
};

export default observer(Layout);
