import palette from '../palette';

const MuiCheckbox = {
  styleOverrides: {
    root: {
      transition: '.3s',
      color: palette.whiteEmphasis.high,
      '&:hover': {
        color: 'rgba(218, 218, 218, .5)',
        backgroundColor: 'rgba(255, 122, 0, 0.3)',
      },
    },
    colorPrimary: {
      '&.Mui-disabled': {
        color: 'rgba(218, 218, 218, 0.10)',
      },
    },
  },
};

export default MuiCheckbox;
