const MuiInputLabel = {
  styleOverrides: {
    root: {
      lineHeight: 1.2,
      color: 'rgba(255,255,255, 0.6)',
      '&.Mui-focused': {
        color: 'rgba(255,255,255, 0.6)',
      },
      '&.Mui-disabled': {
        color: 'rgba(255,255,255, 0.2)',
      },
    },
    shrink: {
      lineHeight: 1.8,
    },
  },
};

export default MuiInputLabel;
