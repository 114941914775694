import { types, flow } from 'mobx-state-tree';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import { getRootStore } from 'models/root';
import api from 'services/API';
import { stringUtilities } from 'utils';

export const POSSKU = types.model({
  id: types.integer,
  sku_id: types.maybeNull(types.number),
  item: types.maybeNull(types.string),
  category: types.maybeNull(types.string),
  subcategory: types.maybeNull(types.string),
  latest_price: types.maybeNull(types.number),
  latest_price_at: types.maybeNull(types.string),
  ignore: types.maybeNull(types.boolean),
  archived: types.boolean,
  earliest_sale_at: types.maybeNull(types.string),
});

export const skuMappingStoreInitialState = {
  all: [],
  posSearchStr: '',
  bartrackSearchStr: '',
  isLoaded: false,
};

export const skuMappingStore = types
  .model({
    allActive: types.array(POSSKU),
    allIgnored: types.array(POSSKU),
    posSearchStr: types.string,
    bartrackSearchStr: types.string,
    isLoaded: types.boolean,
  })
  .views(self => ({
    get allUnmatchedPOS() {
      return orderBy(
        self.allActive.filter(sku => sku.sku_id === null),
        ['item', 'category', 'subcategory'],
        ['asc', 'asc', 'asc'],
      );
    },

    get unmatchedPOS() {
      return self.allUnmatchedPOS.filter(posItem => {
        return stringUtilities.matchSome(self.posSearchStr, [
          posItem.item,
          posItem.category,
          posItem.subcategory,
        ]);
      });
    },

    get allUnmatchedBeverages() {
      const root = getRootStore();

      const beverages = self.showHiddenBeverages
        ? root.beveragesStore.allExcludingPrototypes
        : root.beveragesStore.sortedBeverages;

      return orderBy(beverages, ['_producers_name', '_name'], ['asc', 'asc']);
    },

    get unmatchedBeverages() {
      return self.allUnmatchedBeverages.filter(sku => {
        return stringUtilities.matchSome(self.bartrackSearchStr, [sku._producers_name, sku._name]);
      });
    },

    get allMatched() {
      const { skuStore } = getRootStore();
      const matched = [];

      const groupedBySKU = groupBy(
        self.allActive.filter(sku => sku.sku_id),
        'sku_id',
      );

      Object.keys(groupedBySKU).forEach(sku_id => {
        const sku = skuStore.getSKUById(Number(sku_id));
        if (sku && !self.showHiddenBeverages) {
          matched.push({
            id: new Date().getTime(),
            pos: orderBy(
              groupedBySKU[sku_id],
              ['item', 'category', 'subcategory'],
              ['asc', 'asc', 'asc'],
            ),
            beverage: { ...sku.beverage, _containers_name: sku._containers_name },
          });
        }
      });

      return orderBy(matched, ['beverage._producers_name', 'beverage._name'], ['asc', 'asc']);
    },

    get matched() {
      return self.allMatched.filter(sku => {
        const isMatchingBartrackItems = stringUtilities.matchSome(self.bartrackSearchStr, [
          sku.beverage._producers_name,
          sku.beverage._name,
        ]);

        if (isMatchingBartrackItems) {
          const isMatchingPOSItems = sku.pos.some(posItem => {
            return stringUtilities.matchSome(self.posSearchStr, [
              posItem.item,
              posItem.category,
              posItem.subcategory,
            ]);
          });
          return isMatchingPOSItems;
        }

        return false;
      });
    },

    get ignored() {
      return self.allIgnored.slice().sort((a, b) => {
        if (a.item < b.item) {
          return -1;
        } else if (a.item > b.item) {
          return 1;
        } else {
          if (a.category < b.category) {
            return -1;
          } else if (a.category > b.category) {
            return 1;
          } else {
            if (a.subcategory < b.subcategory) {
              return -1;
            } else if (a.subcategory > b.subcategory) {
              return 1;
            } else {
              return 0;
            }
          }
        }
      });
    },
  }))
  .actions(self => ({
    setPOSSearchStr: str => {
      if (typeof str !== 'string') {
        self.posSearchStr = '';
      } else {
        self.posSearchStr = str.toLowerCase().trim();
      }
    },
    setBartrackSearchStr: str => {
      if (typeof str !== 'string') {
        self.bartrackSearchStr = '';
      } else {
        self.bartrackSearchStr = str.toLowerCase().trim();
      }
    },
    createMapping: flow(function* (mapping) {
      try {
        const { pos, bartrack } = mapping;
        const bartrackSKU = bartrack[0];
        const response = yield self.patchPOSSKUs(
          { id: pos.map(item => item.id), rows: pos.length },
          { sku_id: bartrackSKU.id },
        );
        response.data.result.forEach(item => self.updateActiveById(item));
      } catch (err) {
        return Promise.reject(err);
      }
    }),
    updateMapping: flow(function* (matchToUpdate, newItems) {
      const bartrackID = matchToUpdate.bartrack[0].id;

      const { pos } = newItems;
      if (Array.isArray(pos)) {
        const promises = pos.map(sku => {
          return self.connect(sku, { sku_id: bartrackID });
        });
        try {
          return yield Promise.all(promises);
        } catch (err) {
          return Promise.reject(err);
        }
      }
    }),
    fetchPOSSKUs: flow(function* fetchPOSSKUs() {
      try {
        const response = yield api.getPOSSKUs();
        const skus = response.data.result;
        self.isLoaded = true;

        const { true: allIgnored, false: allActive } = groupBy(skus, 'ignore');

        self.allIgnored.replace(allIgnored);
        self.allActive.replace(allActive);

        self.isLoaded = true;
      } catch (err) {
        self.isLoaded = false;
        return Promise.reject(err);
      }
    }),
    patchPOSSKU: flow(function* patchPOSSKU(id, patch) {
      try {
        return yield api.patchPOSSKU(id, patch);
      } catch (err) {
        return Promise.reject(err);
      }
    }),
    patchPOSSKUs: flow(function* patchPOSSKU(body, params) {
      try {
        return yield api.patchPOSSKUs(body, params);
      } catch (err) {
        return Promise.reject(err);
      }
    }),
    connect: flow(function* connect(item, patch) {
      try {
        const response = yield self.patchPOSSKU(item.id, patch);
        const updatedItem = response.data.row;
        self.updateActiveById(updatedItem);
        return response;
      } catch (err) {
        return Promise.reject(err);
      }
    }),
    ignore: flow(function* ignore(item) {
      try {
        const response = yield self.patchPOSSKU(item.id, { ignore: true });
        const updatedItem = response.data.row;
        self.allActive.remove(item);
        self.allIgnored.push(updatedItem);
        return response;
      } catch (err) {
        return Promise.reject(err);
      }
    }),
    unignore: flow(function* unignore(itemsArray) {
      try {
        const response = yield self.patchPOSSKUs(
          { id: [...itemsArray], rows: itemsArray.length },
          { ignore: false },
        );
        const updatedItems = response.data.result;
        const updatedItemsIds = updatedItems.map(item => item.id);
        const updatedNodes = self.allIgnored.filter(item => updatedItemsIds.includes(item.id));
        updatedNodes.forEach(node => {
          self.allIgnored.remove(node);
        });
        self.allActive.push(...updatedItems);
        return response;
      } catch (err) {
        return Promise.reject(err);
      }
    }),
    unlink: flow(function* (ids) {
      try {
        const response = yield self.patchPOSSKUs(
          { id: [...ids], rows: ids.length },
          { sku_id: null },
        );
        if (response?.data?.result) {
          response.data.result.forEach(posSKU => self.updateActiveById(posSKU));
        }
        return response;
      } catch (err) {
        return Promise.reject(err);
      }
    }),
    updateActiveById(item) {
      const itemIndex = self.allActive.findIndex(sku => sku.id === item.id);
      if (itemIndex >= 0) {
        self.allActive[itemIndex] = item;
      }
    },
    setSearch(value) {
      self.searchString = value;
    },
    setActivePOSSKUs(skus) {
      self.allActive.replace(skus);
      self.isLoaded = true;
    },
    setIgnoredPOSSKUs(skus) {
      self.allIgnored.replace(skus);
    },
  }));
