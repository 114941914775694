import React from 'react';
import { Box, Typography, styled } from '@mui/material';
import { observer } from 'mobx-react';
import clsx from 'clsx';

import MenuPagination from '../../MenuPagination';

const HorizontalLayout = ({ children, state }) => {
  return (
    <StyledRootBox
      className={clsx('horizontal', `${state.establishmentInfoPosition}-bar`)}
      display="flex"
      flexDirection="column"
      sx={{ backgroundColor: state.backgroundColor }}
    >
      <Box className="header" display="flex" justifyContent="space-between" alignItems="center">
        <MenuPagination state={state} />
        <Typography variant="h3" style={{ color: state.titleColor }}>
          {state.title}
        </Typography>
        <Box className="empty" />
      </Box>
      <Box className="content">{children}</Box>
    </StyledRootBox>
  );
};

export default observer(HorizontalLayout);

const StyledRootBox = styled(Box, {
  shouldForwardProp: props => props !== 'state',
})(({ theme }) => ({
  aspectRatio: '16 / 9',
  width: '100%',
  overflow: 'hidden',
  '.header': {
    height: '12vh',
    width: '100%',
    padding: theme.spacing(3),
    marginBottom: '3vh',
    '.footer-bar &': {
      order: 1,
    },
    '& h1': {
      fontSize: '2.2vw',
    },
  },
  '.content': {
    height: '85vh',
    width: '100%',
    boxSizing: 'border-box',
    '.footer-bar &': {
      paddingTop: '3vh',
      paddingBottom: '3vh',
    },
  },
  '.footer': {
    height: '100vh',
    width: '8vw',
    fontSize: '1.2vw',
    fontWeight: 'bold',
  },
  '.socialLink': {
    width: '2vh',
    '&:not(:last-of-type)': {
      marginRight: '1vh',
    },
  },
  '.movement': {
    whiteSpace: 'nowrap',
  },
  '.beers': {
    fontSize: '1.8vw',
    position: 'relative',
    lineHeight: '1vh',
  },
  '.empty': {
    width: '10vh',
    margin: '0 2vh 0 1.6vh',
  },
}));
