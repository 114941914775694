import React from 'react';
import { Navigate, useMatch, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStore } from 'hooks';

const PublicRoute = ({ disableForAuthenticatedUsers, children }) => {
  const { userStore } = useStore();
  const { state } = useLocation();

  const isAcceptGrantPage = useMatch({
    path: '/auth/accept',
  });

  const isResetPasswordPage = useMatch({
    path: `/auth/reset`,
  });

  if (
    userStore.isAuthenticated &&
    disableForAuthenticatedUsers &&
    !Boolean(isAcceptGrantPage) &&
    !Boolean(isResetPasswordPage)
  ) {
    return <Navigate to={state && state.referrer ? state.referrer : '/'} />;
  }

  return children;
};

export default observer(PublicRoute);
