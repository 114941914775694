import React from 'react';
import { Box, Button, TextField } from '@mui/material';
import { HexColorPicker } from 'react-colorful';
import { observer } from 'mobx-react';

const Picker = ({ label, open, pickerRef, name, state, setter, resetPickers, show }) => {
  const handleTogglePicker = () => {
    resetPickers();
    setter(prev => !prev);
  };

  if (!show) return null;

  return (
    <Box ref={pickerRef} sx={{ position: 'relative' }}>
      {open && (
        <Box
          sx={{
            position: 'absolute',
            right: 0,
            top: -150,
            zIndex: 99,
          }}
        >
          <HexColorPicker
            color={state[name]}
            onChange={value => {
              state.handleSelectColor(name, value);
            }}
          />
        </Box>
      )}
      <Box
        sx={{
          display: 'grid',
          gridGap: 2,
          gridAutoFlow: 'column',
          '& button': {
            width: 58,
            height: 48,
            border: '1px solid',
          },
        }}
      >
        <Button style={{ background: state[name] }} onClick={handleTogglePicker} />
        <TextField
          label={label}
          variant="outlined"
          inputProps={{
            type: 'text',
            inputMode: 'numeric',
          }}
          onChange={state.handleChange}
          name={name}
          value={state[name]}
        />
      </Box>
    </Box>
  );
};

export default observer(Picker);
