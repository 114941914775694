import { useEffect } from 'react';
import { isAlive } from 'mobx-state-tree';

import { useStore } from 'hooks';
import api from 'services/API';

const useEstablishmentData = (onSuccess, onError, deps) => {
  const {
    ui,
    barsStore,
    beveragesStore,
    containersStore,
    itemsStore,
    linesStore,
    tapsStore,
    lineTapsStore,
    itemLinesStore,
    skuStore,
    establishmentStore,
    userStore,
    unitStore,
    coolersStore,
    scheduleStore,
    roleUserStore,
    itemEventsStore,
    integrationsStore,
    cleaningsStore,
    cleaningStatisticsStore,
    usersStore,
    skuMappingStore,
    menusStore,
    menuDesignsStore,
    menuListsStore,
    reportStore,
    barsRoleUserStore,
    deviceHistoryStore,
    topologyManagementStore,
    notificationsStore,
    statisticTestsStore,
  } = useStore();

  useEffect(() => {
    let ignoreRequestResults = false;

    (async () => {
      try {
        if (!userStore.auth_token) return;
        await Promise.all([
          api.getBundle({ number: 1 }).then(res => res.data.result),
          api.getBundle({ number: 2 }).then(res => res.data.result),
          api.getBundle({ number: 3 }).then(res => res.data.result),
        ]).then(response => {
          if (!ignoreRequestResults) {
            const {
              alarms,
              brus,
              sensors,
              line_sensors,
              bars,
              beverages,
              containers,
              lines,
              taps,
              line_taps,
              item_lines,
              skus,
              establishments,
              items_statistics,
              units,
              coolers,
              get_cleaning_statistics,
              pos_skus_active,
              pos_skus_ignored,
              roles,
              users,
              bus_hours,
              cleanings,
              events,
              happy_hours,
              integrations,
              role_users,
              type_lists,
              menus,
              menu_designs,
              menu_lists,
              report_configurations,
              bars_role_users,
              gateways,
              notifications,
              conditions,
              statistic_tests,
              temperature_sensors,
              establishments_sensors,
              audits_items,
            } = response.reduce((result, obj) => {
              for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                  result[key] = obj[key];
                }
              }
              return result;
            }, {});

            if (isAlive(establishmentStore) && Array.isArray(establishments?.rows)) {
              const establishment = establishments.rows.find(
                ({ id }) => id === userStore?.currentRole?._establishment_id,
              );
              if (establishment) establishmentStore.setEstablishment(establishment);
            }

            if (isAlive(containersStore)) {
              containers &&
                containers.rows &&
                Array.isArray(containers.rows) &&
                containersStore.setContainers(containers.rows);
            }

            if (isAlive(beveragesStore)) {
              beverages &&
                beverages.rows &&
                Array.isArray(beverages.rows) &&
                beveragesStore.setBeverages(beverages.rows);
            }

            if (isAlive(itemsStore)) {
              items_statistics &&
                items_statistics.rows &&
                Array.isArray(items_statistics?.rows) &&
                itemsStore.setItems(items_statistics.rows);
            }

            if (isAlive(lineTapsStore)) {
              line_taps &&
                line_taps.rows &&
                Array.isArray(line_taps.rows) &&
                lineTapsStore.setLineTaps(line_taps.rows);
            }

            if (isAlive(linesStore)) {
              lines && lines.rows && Array.isArray(lines.rows) && linesStore.setLines(lines.rows);
            }

            if (isAlive(tapsStore)) {
              taps && taps.rows && Array.isArray(taps.rows) && tapsStore.setTaps(taps.rows);
            }

            if (isAlive(itemLinesStore)) {
              item_lines &&
                item_lines.rows &&
                Array.isArray(item_lines.rows) &&
                itemLinesStore.setItemLines(item_lines.rows);
            }

            if (isAlive(skuStore)) {
              skus && skus.rows && Array.isArray(skus.rows) && skuStore.setSKUs(skus.rows);
            }

            if (isAlive(coolersStore)) {
              coolers &&
                coolers &&
                Array.isArray(coolers.rows) &&
                coolersStore.setCoolers(coolers.rows);
            }

            if (isAlive(unitStore)) {
              units && units.rows && Array.isArray(units.rows) && unitStore.setUnits(units.rows);
            }

            if (isAlive(barsStore)) {
              bars && bars.rows && Array.isArray(bars.rows) && barsStore.setBars(bars.rows);
            }

            if (isAlive(barsRoleUserStore)) {
              bars_role_users &&
                bars_role_users.rows &&
                Array.isArray(bars_role_users.rows) &&
                barsRoleUserStore.setBarsRoleUser(bars_role_users.rows);
            }

            if (isAlive(cleaningStatisticsStore)) {
              get_cleaning_statistics &&
                get_cleaning_statistics.rows &&
                Array.isArray(get_cleaning_statistics.rows) &&
                cleaningStatisticsStore.setCleaningStatistics(get_cleaning_statistics.rows);
            }

            if (isAlive(reportStore)) {
              report_configurations &&
                report_configurations.rows &&
                Array.isArray(report_configurations.rows) &&
                reportStore.setReportConfiguration(report_configurations.rows[0]);
            }

            if (isAlive(skuMappingStore)) {
              Array.isArray(pos_skus_active?.rows) &&
                skuMappingStore.setActivePOSSKUs(pos_skus_active.rows);
            }

            if (isAlive(skuMappingStore)) {
              Array.isArray(pos_skus_ignored?.rows) &&
                skuMappingStore.setIgnoredPOSSKUs(pos_skus_ignored.rows);
            }

            if (isAlive(usersStore)) {
              users && users.rows && Array.isArray(users.rows) && usersStore.setUsers(users.rows);
              roles && roles.rows && Array.isArray(roles.rows) && usersStore.setRoles(roles.rows);
            }

            if (isAlive(scheduleStore)) {
              bus_hours &&
                bus_hours.rows &&
                Array.isArray(bus_hours.rows) &&
                scheduleStore.setBusinessHours(bus_hours.rows);

              events &&
                events.rows &&
                Array.isArray(events.rows) &&
                scheduleStore.setEvents(events.rows);

              happy_hours &&
                happy_hours.rows &&
                Array.isArray(happy_hours.rows) &&
                scheduleStore.setHappyHours(happy_hours.rows);
            }

            if (isAlive(cleaningsStore)) {
              cleanings &&
                cleanings.rows &&
                Array.isArray(cleanings.rows) &&
                cleaningsStore.setCleanings(cleanings.rows);
            }

            if (isAlive(integrationsStore)) {
              integrations &&
                integrations.rows &&
                Array.isArray(integrations.rows) &&
                integrationsStore.setIntegrations(integrations.rows);
            }

            if (isAlive(itemEventsStore)) {
              audits_items &&
                audits_items.rows &&
                Array.isArray(audits_items.rows) &&
                itemEventsStore.setItemEvents(audits_items.rows);
            }

            if (isAlive(notificationsStore)) {
              Array.isArray(notifications?.rows) &&
                notificationsStore.setNotifications(notifications.rows);

              Array.isArray(conditions?.rows) && notificationsStore.setConditions(conditions.rows);
            }

            if (isAlive(deviceHistoryStore)) {
              Array.isArray(gateways?.rows) && deviceHistoryStore.setDeviceHistory(gateways.rows);
              Array.isArray(alarms?.rows) && deviceHistoryStore.setAlarms(alarms.rows);
            }

            if (isAlive(topologyManagementStore)) {
              Array.isArray(alarms?.rows) && topologyManagementStore.setAlarms(alarms.rows);

              Array.isArray(establishments_sensors?.rows) &&
                topologyManagementStore.setEstablishmentSensors(establishments_sensors.rows[0]);

              Array.isArray(brus?.rows) && topologyManagementStore.setBRUs(brus.rows);

              Array.isArray(gateways?.rows) && topologyManagementStore.setGateways(gateways.rows);

              Array.isArray(line_sensors?.rows) &&
                topologyManagementStore.setLineSensors(line_sensors.rows);

              Array.isArray(sensors?.rows) && topologyManagementStore.setSensors(sensors.rows);
              Array.isArray(temperature_sensors?.rows) &&
                topologyManagementStore.setTemperatureSensors(temperature_sensors.rows);
            }

            if (isAlive(roleUserStore)) {
              role_users &&
                role_users.rows &&
                Array.isArray(role_users.rows) &&
                roleUserStore.setRoleUsers(role_users.rows);
            }

            if (isAlive(scheduleStore)) {
              type_lists &&
                type_lists.rows &&
                Array.isArray(type_lists.rows) &&
                scheduleStore.setScheduleTypes(type_lists.rows);
            }

            if (isAlive(menusStore)) {
              menus &&
                menus.rows &&
                Array.isArray(menus.rows) &&
                menusStore.setDigitalMenus(menus.rows);
            }

            if (isAlive(menuDesignsStore)) {
              menu_designs &&
                menu_designs.rows &&
                Array.isArray(menu_designs.rows) &&
                menuDesignsStore.setMenuDesigns(menu_designs.rows);
            }

            if (isAlive(menuListsStore)) {
              menu_lists &&
                menu_lists.rows &&
                Array.isArray(menu_lists.rows) &&
                menuListsStore.setMenuLists(menu_lists.rows);
            }

            if (isAlive(statisticTestsStore)) {
              statistic_tests &&
                statistic_tests?.rows &&
                Array.isArray(statistic_tests.rows) &&
                statisticTestsStore.setStatisticTests(statistic_tests.rows);
            }

            if (isAlive(ui)) {
              ui.setIsDataLoaded(true);
              ui.setIsDataLoading(false);
            }

            if (typeof onSuccess === 'function') {
              onSuccess();
            }
          }
        });
      } catch (err) {
        console.error(err);
        if (typeof onError === 'function') {
          onError(err);
        }
      }
    })();

    return () => {
      ignoreRequestResults = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export default useEstablishmentData;
