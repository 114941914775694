import React from 'react';
import { List, styled } from '@mui/material';

import routes from './settings';
import MenuItem from './MenuItem';
import { useStore } from 'hooks';

const { REACT_APP_VERSION } = process.env;

const MenuList = props => {
  const { ui } = useStore();
  const validItems = routes.filter(route => {
    if (typeof route.guard === 'function') {
      return route.guard(props);
    }
    return true;
  });

  return (
    <>
      <StyledRootList>
        {validItems.map(props => (
          <MenuItem key={props.id} {...props} />
        ))}
      </StyledRootList>
      {ui.isOpenedMenu && <StyledAppVersion>App Version - {REACT_APP_VERSION}</StyledAppVersion>}
    </>
  );
};

export default MenuList;

const StyledRootList = styled(List)(({ theme }) => ({
  flexDirection: 'column',
  display: 'flex',
  flex: 1,
  paddingBottom: 0,
  '&.MuiList-root.MuiList-padding': {
    '&::-webkit-scrollbar': {
      width: '3px',
    },
  },
}));

const StyledAppVersion = styled('div')(({ theme }) => ({
  color: '#888',
  padding: '0 19px 20px',
  fontSize: '12px',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}));
