import { types } from 'mobx-state-tree';
import { Identifier } from './line-identifier';

export const Cleaning = types.model({
  id: types.identifierNumber,
  line_id: types.integer,
  identifier: types.maybeNull(types.string),
  cleaned_from: types.string,
  cleaned_to: types.maybeNull(types.string),
  cleaned: types.string,
  created_at: types.string,
  created_by: types.integer,
  updated_at: types.maybeNull(types.string),
  updated_by: types.maybeNull(types.integer),
  archived: types.boolean,
  _lines_identifiers: Identifier,
});
