import { types } from 'mobx-state-tree';

export const Carrier = types.model({
  archived: types.optional(types.boolean, false),
  carrier: types.maybeNull(types.string),
  digits: types.maybeNull(types.number),
  id: types.identifierNumber,
  msg_limit: types.maybeNull(types.number),
  server: types.maybeNull(types.string),
  users: types.maybeNull(types.number),
});
