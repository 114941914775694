import React from 'react';
import { Box, Typography, styled } from '@mui/material';
import { observer } from 'mobx-react';

import MenuPagination from '../../MenuPagination';

const VerticalLayout = ({ state, children }) => {
  return (
    <StyledRootBox
      state={state}
      className="vertical"
      display="flex"
      flexDirection="column"
      sx={{ backgroundColor: state.backgroundColor }}
    >
      <Box className="header" display="flex" justifyContent="center" alignItems="center">
        <Box display="flex" justifyContent="center" alignItems="center" whiteSpace="nowrap">
          <Typography variant="h3" style={{ fontWeight: 'bold', color: state.titleColor }}>
            {state.title}
          </Typography>
        </Box>
      </Box>
      <Box className="content">
        <Box style={{ height: '80vh' }}>{children}</Box>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ color: state.footerColor, fontFamily: state.font }}
      >
        <Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
              <Box mb=".5vh">@BARTRACKBEER</Box>
              <Box>
                <img alt="instagram" src="/assets/images/instagram.png" className="socialLink" />
                <img alt="linkedin" src="/assets/images/linkedin.png" className="socialLink" />
                <img alt="facebook" src="/assets/images/facebook.png" className="socialLink" />
              </Box>
            </Box>
            <MenuPagination state={state} />
            <Box
              className="movement"
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <Box mb=".5vh">
                Join the MOVEMENT{' '}
                <span role="img" className="beers">
                  🍻
                </span>
              </Box>
              <Box className="text-center">#DrinkSmarter</Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </StyledRootBox>
  );
};

export default observer(VerticalLayout);

const StyledRootBox = styled(Box, {
  shouldForwardProp: props => props !== 'state',
})(({ state }) => ({
  height: '100%',
  aspectRatio: '3 / 4',
  margin: 'auto',
  overflow: 'hidden',
  '.header': {
    height: '10vh',
  },
  '.content': {
    height: '80vh',
  },
  '.footer': {
    height: '10vh',
    fontSize: '1.2vh',
    fontWeight: 'bold',
  },
  '.socialLink': {
    width: '2vh',
    '&:not(:last-of-type)': {
      marginRight: '1vh',
    },
  },
  '.rotate90': {
    transform: 'rotate(90deg)',
  },
  '.rotate270': {
    transform: 'rotate(-90deg)',
  },
  '.movement': {
    whiteSpace: 'nowrap',
  },
  '.beers': {
    fontSize: '1.8vh',
    position: 'relative',
    lineHeight: '1vh',
  },
}));
