import palette from 'theme/palette';

const MuiDivider = {
  styleOverrides: {
    root: {
      margin: '15px 0',
      '&.gradient': {
        backgroundColor: palette.primary.main,
      },
    },
    flexItem: {
      height: '100%',
      alignSelf: 'center',
    },
    vertical: {
      height: '100%',
      margin: 0,
    },
  },
};

export default MuiDivider;
