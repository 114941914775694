import { useMediaQuery, useTheme } from '@mui/material';

const useMediaQueries = () => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  const isMobileOrGreater = useMediaQuery(theme.breakpoints.up('xs'));
  const isMobileOrSmaller = useMediaQuery(theme.breakpoints.down('sm'));

  const isTablet = useMediaQuery(theme.breakpoints.only('sm'));
  const isTabletOrGreater = useMediaQuery(theme.breakpoints.up('sm'));
  const isTabletOrSmaller = useMediaQuery(theme.breakpoints.down('md'));

  const isDesktop = useMediaQuery(theme.breakpoints.only('md'));
  const isDesktopOrGreater = useMediaQuery(theme.breakpoints.up('md'));
  const isDesktopOrSmaller = useMediaQuery(theme.breakpoints.down('lg'));

  const isLargeDesktop = useMediaQuery(theme.breakpoints.only('lg'));
  const isLGDesktopOrGreater = useMediaQuery(theme.breakpoints.up('lg'));
  const isLGDesktopOrSmaller = useMediaQuery(theme.breakpoints.down('xl'));

  const isExtraLargeDesktop = useMediaQuery(theme.breakpoints.only('xl'));

  return {
    isMobileOrGreater, // 0 >=
    isMobile, // 0 - 599
    isMobileOrSmaller, // <= 599
    isTabletOrGreater, // 600 >=
    isTablet, // 600 - 1023
    isTabletOrSmaller, // <= 1023
    isDesktopOrGreater, // 1024 >=
    isDesktop, // 1024 - 1279
    isDesktopOrSmaller, // <= 1279
    isLGDesktopOrGreater, // 1280 >=
    isLGDesktopOrSmaller, // <= 1279,
    isLargeDesktop, // 1280 - 1919,
    isExtraLargeDesktop, // 1920
  };
};

export default useMediaQueries;
