import { useCallback, useMemo, useState } from 'react';
import { useStore, useToast } from 'hooks';

const useReportConfigurationData = () => {
  const { reportStore, usersStore } = useStore();
  const { errorToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const loadData = useCallback(async () => {
    try {
      setIsLoading(true);
      await Promise.all([reportStore.fetch(), usersStore.fetchUsers()]);
    } catch (error) {
      errorToast(error);
    } finally {
      setIsLoading(false);
    }
  }, [errorToast, reportStore, usersStore]);

  const isLoaded = useMemo(() => {
    return reportStore.isLoaded && usersStore.isUsersLoaded;
  }, [reportStore.isLoaded, usersStore.isUsersLoaded]);

  return { isLoading, isLoaded, loadData };
};

export default useReportConfigurationData;
